import { capitalize } from '@mui/material';

export interface Evidence {
  file_name: string;
  scan_id: string;
}

export interface Vulnerability {
  id: string;
  name: string;
  category: string;
  endpoint: string;
  curl_cmd: string | null;
  evidence: Evidence[];
  description: string;
  remediation: string;
  owasp_link?: string;
  application_id?: string;
  false_positive?: boolean;
  status: string;
  parameter?: boolean;
  tickets: VulnerabilityTicket[];
  tags?: string[];
  subcategory: string;
  vulnerability_severity: number;
}

export enum VulnerabilityInstanceStatus {
  DETECTED = 'detected',
  FALSE_POSITIVE_CLAIMED = 'false_positive_claimed',
  WAIVED = 'waived',
  RESOLVED = 'resolved',
}

enum VulnerabilityScope {
  ENDPOINT = 'endpoint',
  HOST = 'host',
}

interface VulnerabilityDefinition {
  category: string;
  sub_category: string;
}

interface Finding {
  original_value: string | null;
  parameter_name: string | null;
  parameter_type: string | null;
  replaced_value: string | null;

  finding_parameter_uid: string | null;
  finding_parameter_rid: string | null;
  finding_replaced_value_uid: string | null;
  finding_replaced_value_rid: string | null;

  payload: string | null;
  verified_by: string | null;

  header: string | null;
  mode: string | null;
  header_key: string | null;
}

export interface EndpointAttackInfo {
  scan_id: string;
  endpoint: string;
  host: string;
  curl_cmd: string | null;
  evidence_paths: string[];
  finding: Finding;
}

interface VulnerabilityScopeInfo {
  scope: VulnerabilityScope;
  endpoint_attacks_info: EndpointAttackInfo[];
}

export interface VulnerabilityInstance {
  id: string;
  application_id: string;
  created_at: string;
  updated_at: string;
  status: VulnerabilityInstanceStatus;
  tickets: VulnerabilityTicket[];
  tags: string[];
  vulnerability_definition: VulnerabilityDefinition;
  scope_info: VulnerabilityScopeInfo;
  severity: number;
}

export const statusToTitle = (status?: string) => {
  switch (status) {
    case 'false_positive_claimed':
      return 'False Positive Claimed';

    default:
      return capitalize(status?.replaceAll('_', ' ') ?? '');
  }
};

export interface VulnerabilityTicket {
  id: string;
  integration_id: string;
  integration_type: string;
  external_id: string;
  name: string;
  status?: string;
  link?: string;
  jira?: {
    project_id: string;
    key?: string;
  };
}

export enum VulnerabilitySeverity {
  INFO = 0,
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
  CRITICAL = 4,
}

export interface VulnerableEndpoint {
  scanId: string;
  endpoint: string;
  evidencePaths: string[];
  curlCmd: string | null;
}

export interface DisplayedVulnerability {
  id: string;
  severity: VulnerabilitySeverity;
  name: string;
  status: VulnerabilityInstanceStatus;
  host: string;
  vulnerable_endpoints: VulnerableEndpoint[];
  owasp_category: string;
  owasp_link: string;
  applicationId: string;
  ticket: VulnerabilityTicket | null;
  tags: string[];
  description: string;
  remediation: string;
  isLLM: boolean;
}
