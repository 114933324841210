import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  SxProps,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { JiraIcon } from '../../assets/svgs/icons/JiraIcon';
import {
  IntegrationsContext,
  IntegrationsContextProps,
} from '../../contexts/Integrations';
import { SnackbarContext } from '../../contexts/Snackbar';
import {
  commitSetupAction,
  replaceAuthCode,
} from '../../services/IntegrationsService';
import CustomDrawer from '../Common/CustomDrawer';
import TextFieldTitle from '../Common/TextFieldTitle';
import { Application } from '../../models/application';

const BOTTOM_SPACER: SxProps = { minHeight: 14, flex: 1 };

interface Props {
  mode: 'create' | 'edit';
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  application?: Application;
  onSave?: (app: Application) => void;
  code?: string;
}

interface SelectionOption {
  name: string;
  value: any;
}

export const EditJiraIntegrationDrawer = (props: Props) => {
  const { mode, isOpen, setIsOpen } = props;

  const { show } = useContext(SnackbarContext);
  const { fetchIntegrations } = useContext(
    IntegrationsContext,
  ) as IntegrationsContextProps;

  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const [integrationId, setIntegrationId] = useState<string>();
  const [jiraSites, setJiraSites] = useState<SelectionOption[]>();
  const [selectedJiraSite, setSelectedJiraSite] = useState<SelectionOption>();
  const [jiraProjects, setJiraProjects] = useState<SelectionOption[]>();
  const [selectedJiraProject, setSelectedJiraProject] =
    useState<SelectionOption>();

  useEffect(() => {
    if (props.code) {
      setLoading(true);
      replaceAuthCode(props.code)
        .then(
          ({
            integration_id: integrationId,
            selection_list: sites,
          }) => {
            setIntegrationId(integrationId);
            setJiraSites([...sites]);
          },
        )
        .catch((error) => {
          show(error.message, 'error');
          Sentry.captureException(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [props.code]);

  useEffect(() => {
    if (selectedJiraSite && integrationId) {
      setJiraProjects(undefined);
      setSelectedJiraProject(undefined);

      setLoading(true);

      commitSetupAction(integrationId, 'select', 'site', selectedJiraSite)
        .then(({ selection_list: projects }) => {
          setJiraProjects([...projects]);
        })
        .catch((error) => {
          show(error.message, 'error');
          Sentry.captureException(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedJiraSite, integrationId]);

  const onSaveClick = useCallback(() => {
    if (selectedJiraProject && integrationId) {
      setIsLoadingSave(true);
      commitSetupAction(integrationId, 'select', 'project', selectedJiraProject)
        .then(async () => {
          await fetchIntegrations().catch((e) => {
            console.error(e);
            Sentry.captureException(e);
          });
          show('Jira integration created successfully', 'success');
          setIsOpen(false);
        })
        .catch((error) => {
          show(error.message, 'error');
          Sentry.captureException(error);
        })
        .finally(() => {
          setIsLoadingSave(false);
        });
    }
  }, [selectedJiraProject, integrationId]);

  const handleChangeSite = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setSelectedJiraSite(JSON.parse(value));
  };

  const handleChangeProject = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setSelectedJiraProject(JSON.parse(value));
  };

  return (
    <CustomDrawer
      header={{
        title:
          mode === 'edit' ? 'Edit Jira Integration' : 'Create Jira Integration',
        icon: <JiraIcon />,
      }}
      open={isOpen}
      setOpen={() => setIsOpen?.(false)}
    >
      <Stack
        direction={'column'}
        alignItems={'stretch'}
        flex={1}
        height={'100%'}
      >
        {jiraSites && (
          <Box>
            <TextFieldTitle title="Jira site" />
            <Box sx={{ minWidth: 220 }}>
              <FormControl fullWidth>
                <Select
                  value={
                    selectedJiraSite
                      ? JSON.stringify(selectedJiraSite)
                      : undefined
                  }
                  variant="filled"
                  onChange={handleChangeSite}
                  size="small"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        marginTop: 4,
                        borderRadius: 6,
                        textAlign: 'start',
                        backgroundColor: 'white',
                        border: 'solid 1px #D3D3D3',
                        boxShadow: 'none',
                      },
                    },
                  }}
                >
                  {jiraSites?.map((site: any) => (
                    <MenuItem
                      key={JSON.stringify(site.value)}
                      value={JSON.stringify(site.value)}
                    >
                      {site.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}
        {jiraProjects && (
          <Box sx={{ mt: 2 }}>
            <TextFieldTitle title="Jira project" />
            <Box sx={{ minWidth: 220 }}>
              <FormControl fullWidth>
                <Select
                  value={
                    selectedJiraProject
                      ? JSON.stringify(selectedJiraProject)
                      : undefined
                  }
                  variant="filled"
                  onChange={handleChangeProject}
                  size="small"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        marginTop: 4,
                        borderRadius: 6,
                        textAlign: 'start',
                        backgroundColor: 'white',
                        border: 'solid 1px #D3D3D3',
                        boxShadow: 'none',
                      },
                    },
                  }}
                >
                  {jiraProjects?.map((project: any) => (
                    <MenuItem
                      key={JSON.stringify(project.value)}
                      value={JSON.stringify(project.value)}
                    >
                      {project.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}
        {loading && <Box sx={{ mt: 2 }}>Loading...</Box>}
        <Box sx={BOTTOM_SPACER} />
        <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
          <Button variant="text" onClick={() => setIsOpen?.(false)}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            onClick={onSaveClick}
            loading={isLoadingSave}
            disabled={!selectedJiraSite || !selectedJiraProject}
          >
            Save
          </LoadingButton>
        </Stack>
      </Stack>
    </CustomDrawer>
  );
};

export default EditJiraIntegrationDrawer;
