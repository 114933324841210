import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ApplicationsGrid from '../../components/Applications/ApplicationsGrid';
import { OnboardContext } from '../../contexts/Onboard';
import { track } from '../../utils/analytics';

import { usePDF } from 'react-to-pdf';
import { LoadingButton } from '@mui/lab';
import { ExportIcon2 } from '../../assets/svgs/icons/ExportIcon2';
import PieChartWithCenterLabel, {
  PieChartDataItem,
} from './PieChartWithCenterLabel';
import { DarkLogo } from '../../assets/svgs/DarkLogo';
import dayjs from 'dayjs';
import APICatalogGrid from '../../components/APICatalog/APICatalogGrid';
import ScanHistoryGrid from '../../components/ScansHistory/ScanHistoryGrid';
import { OverviewContext, OverviewContextProps } from '../../contexts/Overview';
import { UserContext, UserContextProps } from '../../contexts/User';

const CARD: SxProps = {
  p: 4,
  background: 'white',
  borderRadius: 2,
  border: '1px solid #000',
  position: 'relative',
};

const ApplicationsSummaryReportButton = () => {
  const { toPDF, targetRef } = usePDF();
  const [searchParams, setSearchParams] = useSearchParams();
  const { state: onboardState, setState: setOnboardState } =
    useContext(OnboardContext);
  const { totalApisCount, totalScansCount } = useContext(
    OverviewContext,
  ) as OverviewContextProps;
  const { applications } = useContext(UserContext) as UserContextProps;

  const [renderingPDF, setRenderingPDF] = useState(false);

  const totalVulnerabilitiesCount = useMemo(() => {
    return applications?.reduce((acc, app) => {
      return acc + (app.vulnerability_instances ?? 0);
    }, 0);
  }, [applications]);

  const appsByRiskData: PieChartDataItem[] = useMemo(() => {
    return [
      {
        label: 'Critical',
        value: applications?.filter((app) => app.risk === 4).length ?? 0,
        color: '#D20404',
      },
      {
        label: 'High',
        value: applications?.filter((app) => app.risk === 3).length ?? 0,
        color: '#F82F02',
      },
      {
        label: 'Medium',
        value: applications?.filter((app) => app.risk === 2).length ?? 0,
        color: '#FF8C00',
      },
      {
        label: 'Low',
        value: applications?.filter((app) => app.risk === 1).length ?? 0,
        color: '#FFD300',
      },
      {
        label: 'No Risk',
        value: applications?.filter((app) => !app.risk).length ?? 0,
        color: 'green',
      },
    ];
  }, [applications]);

  const endpointsByRiskData: PieChartDataItem[] = useMemo(() => {
    const riskToColorMap: Record<string, string> = {
      low: '#FFD300',
      medium: '#FF8C00',
      high: '#F82F02',
      critical: '#D20404',
    };
    const risks = applications?.reduce(
      (acc, app) => {
        const risks = app.endpoints_risks;
        if (!risks) return acc;

        Object.entries(risks).forEach(([k, v]: any) => {
          acc[k] = (acc[k] ?? 0) + v;
        });

        return acc;
      },
      {} as { [key: string]: number },
    );

    return Object.entries(risks ?? {}).map(([k, v]) => ({
      label: k,
      value: v,
      color: riskToColorMap[k] || 'green',
    }));
  }, [applications]);

  const filter = useMemo(() => {
    const val = searchParams.get('filter');
    if (!val) return null;

    try {
      return JSON.parse(val);
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [searchParams]);

  const sorting = useMemo(() => {
    const val = searchParams.get('sorting');
    if (!val) return null;

    try {
      return JSON.parse(val);
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [searchParams]);

  useEffect(() => {
    const showTour = searchParams.get('tour');
    if (showTour !== 'true') return;
    if (onboardState.run) return;

    let show = true;
    setTimeout(() => {
      if (!show) return;

      track('web_app_show_create_inventory_onboard');
      setOnboardState((s) => ({
        ...s,
        steps: [
          {
            target: '#add-application-button',
            content: (
              <Stack alignItems={'stretch'}>
                <Box paddingX={2} paddingY={1}>
                  <Typography variant="subtitle1" textAlign="start">
                    Add new application
                  </Typography>
                </Box>
                <Divider variant="fullWidth" />
                <Stack paddingX={2} paddingY={1} spacing={2}>
                  <Typography textAlign={'start'}>
                    Integrate your first app with Pynt for automated API
                    cataloging.
                  </Typography>
                  <Stack
                    direction={'row'}
                    justifyContent={'flex-start'}
                    spacing={1}
                  >
                    <Button
                      variant="text"
                      onClick={() => {
                        track(
                          'web_app_create_inventory_onboard_skip_button_click',
                          { step: 0 },
                        );
                        setSearchParams((s) => {
                          const newSearchParams = new URLSearchParams(s);
                          newSearchParams.delete('tour');
                          return newSearchParams;
                        });
                        setOnboardState((s) => ({
                          ...s,
                          run: false,
                          stepIndex: 0,
                        }));
                      }}
                    >
                      Skip
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            ),
            handleTargetClick: () => {
              setOnboardState((s) => ({
                ...s,
                stepIndex: 1,
                run: false,
              }));
            },
            disableCloseOnEsc: true,
            disableBeacon: true,
            hideCloseButton: true,
            disableOverlayClose: true,
            spotlightClicks: true,
            hideFooter: true,
            styles: {
              tooltip: { padding: 0 },
              tooltipContent: { padding: 0 },
            },
          },
        ],
        run: true,
      }));
    }, 200);

    return () => {
      show = false;
    };
  }, []);

  useEffect(() => {
    if (renderingPDF) {
      setTimeout(() => {
        toPDF({
          filename: 'applications-summary-report.pdf',
          canvas: {
            // default is 'image/jpeg' for better size performance
            mimeType: 'image/jpeg',
            qualityRatio: 1,
          },
          // Customize any value passed to the jsPDF instance and html2canvas
          // function. You probably will not need this and things can break,
          // so use with caution.
          overrides: {
            // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
            pdf: {
              compress: true,
            },
            // see https://html2canvas.hertzen.com/configuration for more options
            canvas: {
              useCORS: true,
            },
          },
        });
        setRenderingPDF(false);
      }, 8000);
    }
  }, [renderingPDF]);

  return (
    <div>
      <LoadingButton
        variant="contained"
        sx={{ bgcolor: 'black', color: 'white' }}
        startIcon={<ExportIcon2 color="white" />}
        disabled={renderingPDF}
        loading={renderingPDF}
        onClick={() => {
          setRenderingPDF(true);
        }}
      >
        Create Summary Report
      </LoadingButton>
      {renderingPDF && (
        <Stack
          direction={'column'}
          gap={3}
          ref={targetRef}
          style={{
            position: 'fixed',
            top: 5000,
            left: 5000,
            width: 1400,
            padding: 24,
            backgroundColor: 'white',
          }}
        >
          <Grid
            container
            spacing={3}
            style={{ position: 'relative', pageBreakInside: 'avoid' }}
          >
            <Grid item xs={12} md={12}>
              <Stack direction={'column'} spacing={1}>
                {/* <img
                    src={require('../../assets/images/')}
                    style={{ height: 80 }}
                    alt="logo"
                  /> */}
                <DarkLogo />
                <Typography
                  sx={{
                    color: '#050D21',
                    fontSize: 28,
                    textAlign: 'start',
                    fontWeight: 500,
                    whiteSpace: 'nowrap',
                  }}
                >
                  Applications Summary Report
                </Typography>
                <Box flex={1}>
                  <Typography
                    sx={{
                      color: '#7B7B88',
                      fontSize: 14,
                      textAlign: 'start',
                      maxWidth: 600,
                      display: '-webkit-box',
                      overflow: 'hidden',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 4,
                    }}
                  >
                    {`This is your applications summary report, where you can view your
              API catalog and analytics about the APIs at risk. Review the risk
              scores to identify actions you need to take and follow your team's
              scans in the scans tab.`}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ ...CARD, bgcolor: '#0171B8' }}>
                <Stack direction={'column'} spacing={1}>
                  <Typography
                    sx={{
                      color: 'white',
                      fontSize: 20,
                      textAlign: 'start',
                      fontWeight: 500,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Applications
                  </Typography>
                  <Typography
                    sx={{ fontSize: 48, color: 'white', fontWeight: 600 }}
                  >
                    {applications && applications.length}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ ...CARD, bgcolor: '#3EAD48' }}>
                <Stack direction={'column'} spacing={1}>
                  <Typography
                    sx={{
                      fontSize: 20,
                      textAlign: 'start',
                      fontWeight: 500,
                      whiteSpace: 'nowrap',
                      color: 'white',
                    }}
                  >
                    APIs
                  </Typography>
                  <Typography
                    sx={{ fontSize: 48, color: 'white', fontWeight: 600 }}
                  >
                    {totalApisCount}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ ...CARD, bgcolor: '#D43F39' }}>
                <Stack direction={'column'} spacing={1}>
                  <Typography
                    sx={{
                      color: 'white',
                      fontSize: 20,
                      textAlign: 'start',
                      fontWeight: 500,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Vulnerabilities
                  </Typography>
                  <Typography
                    sx={{ fontSize: 48, color: 'white', fontWeight: 600 }}
                  >
                    {totalVulnerabilitiesCount}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ ...CARD, bgcolor: 'primary.main' }}>
                <Stack direction={'column'} spacing={1}>
                  <Typography
                    sx={{
                      color: 'white',
                      fontSize: 20,
                      textAlign: 'start',
                      fontWeight: 500,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Scans
                  </Typography>
                  <Typography
                    sx={{ fontSize: 48, color: 'white', fontWeight: 600 }}
                  >
                    {totalScansCount}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box sx={CARD}>
                <PieChartWithCenterLabel
                  title="Apps by risk"
                  data={appsByRiskData}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={CARD}>
                <PieChartWithCenterLabel
                  title="APIs by risk"
                  data={endpointsByRiskData}
                />
              </Box>
            </Grid>
          </Grid>
          <Stack direction={'row'} spacing={3}>
            {[
              { label: 'Critical', color: '#D20404' },
              { label: 'High', color: '#F82F02' },
              { label: 'Medium', color: '#FF8C00' },
              { label: 'Low', color: '#FFD300' },
              { label: 'No Risk', color: 'green' },
            ].map((item: { label: string; color: string }) => (
              <Stack
                direction={'row'}
                key={item.color}
                justifyContent={'center'}
                alignItems={'center'}
                spacing={1}
              >
                <Box
                  sx={{
                    width: 18,
                    height: 18,
                    borderRadius: 12,
                    bgcolor: item.color,
                  }}
                ></Box>
                <Typography sx={{ color: 'black' }}>{item.label}</Typography>
              </Stack>
            ))}
          </Stack>
          <div style={{ height: 1090 }}>
            <Box sx={CARD}>
              <Stack direction={'column'} spacing={1}>
                <Typography
                  sx={{
                    fontSize: 20,
                    textAlign: 'start',
                    fontWeight: 500,
                    whiteSpace: 'nowrap',
                    bgcolor: '#F6F6F6',
                    p: 1,
                    pl: 2,
                    pr: 2,
                    borderRadius: 1,
                    color: 'black',
                  }}
                >
                  Applications
                </Typography>
                <ApplicationsGrid
                  id="ApplicationsGrid"
                  autoHeight={false}
                  filter={filter}
                  sorting={sorting}
                  usePagination={false}
                  hideActions={true}
                />
              </Stack>
            </Box>
          </div>
          <Box
            sx={CARD}
            style={{
              pageBreakInside: 'avoid',
              position: 'relative',
              marginTop: 24,
            }}
          >
            <Stack direction={'column'} spacing={1}>
              <Typography
                sx={{
                  fontSize: 20,
                  textAlign: 'start',
                  fontWeight: 500,
                  whiteSpace: 'nowrap',
                  bgcolor: '#F6F6F6',
                  p: 1,
                  pl: 2,
                  pr: 2,
                  borderRadius: 1,
                  color: 'black',
                }}
              >
                Last Week APIs
              </Typography>
              <APICatalogGrid
                id="LastWeekAPIsGrid"
                autoHeight={false}
                autoPageSize={true}
                hideFooter
                sorting={[{ field: 'properties.last_scan_time', sort: 'desc' }]}
                filter={{
                  items: [
                    {
                      id: 1,
                      field: 'scan_time',
                      operator: 'onOrAfter',
                      value: dayjs()
                        .subtract(8, 'days')
                        .format('YYYY-MM-DDTHH:mm'),
                    },
                  ],
                }}
                limit={4}
              />
            </Stack>
          </Box>
          <Box
            sx={CARD}
            style={{
              pageBreakInside: 'avoid',
              position: 'relative',
            }}
          >
            <Stack direction={'column'} spacing={1}>
              <Typography
                sx={{
                  fontSize: 20,
                  textAlign: 'start',
                  fontWeight: 500,
                  whiteSpace: 'nowrap',
                  bgcolor: '#F6F6F6',
                  p: 1,
                  pl: 2,
                  pr: 2,
                  borderRadius: 1,
                  color: 'black',
                }}
              >
                Last Week Scans
              </Typography>
              <ScanHistoryGrid
                id="LastWeekScansGrid"
                autoHeight={false}
                autoPageSize={true}
                applicationFilter={false}
                hideFooter
                openDetailsOnSelect
                showOpenReportButton
                filter={{
                  items: [
                    {
                      id: 1,
                      field: 'scan_time',
                      operator: 'onOrAfter',
                      value: dayjs()
                        .subtract(8, 'days')
                        .format('YYYY-MM-DDTHH:mm'),
                    },
                  ],
                }}
                limit={4}
                hideActions={true}
              />
            </Stack>
          </Box>
        </Stack>
      )}
    </div>
  );
};

export default ApplicationsSummaryReportButton;
