/* eslint-disable quotes */
import { Stack, SxProps, Tooltip, Typography } from '@mui/material';
import { useMemo } from 'react';
import { BRAND_DARK_BLUE } from '../../constants/colors';

const CHIP: (bgColor: string, textColor: string) => SxProps = (
  bgColor,
  textColor,
) => ({
  position: 'relative',
  bgcolor: bgColor,
  color: textColor,
  borderRadius: '6px',
  height: 28,
  paddingInline: 0.8,
  maxWidth: '100%',
  boxSizing: 'border-box',
});

const PROGRESS_BOX: (bgColor: string, textColor: string) => SxProps = (
  bgColor,
  textColor,
) => ({
  position: 'absolute',
  bgcolor: bgColor,
  borderRadius: '6px',
  height: 28,
  left: 0,
  paddingInlineStart: 0.8,
  paddingInlineEnd: 0,
  overflow: 'hidden',
  color: textColor,
});

const TEXT: SxProps = {
  fontSize: 11,
  letterSpacing: '-0.3 px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: '100%',
  fontWeight: 500,
};

interface Props {
  type: 'Completed' | 'Did not run' | 'running' | 'generating-traffic';
  progress?: number;
}

export default function HistoryScanStatusChip({ type, progress }: Props) {
  const textColor = useMemo(() => {
    switch (type) {
      case 'Did not run':
        return '#F82F02';

      case 'running':
        return BRAND_DARK_BLUE;

      case 'generating-traffic':
        return BRAND_DARK_BLUE;

      default:
        return '#0F8F44';
    }
  }, [type]);

  const bgColor = useMemo(() => {
    switch (type) {
      case 'Did not run':
        return '#E2432930';

      case 'running':
        return '#F5F8FD';

      case 'generating-traffic':
        return '#F5F8FD';

      default:
        return '#C7F0D0';
    }
  }, [type]);

  const statusTitle = useMemo(() => {
    switch (type) {
      case 'Did not run':
        return "Didn't run";

      case 'running':
        return 'Running';

      case 'generating-traffic':
        return 'Traffic..';

      case 'Completed':
        return 'Complete';

      default:
        return type;
    }
  }, [type]);

  const [progressBgColor, progressTextColor] = useMemo(() => {
    switch (type) {
      case 'running':
        return ['#C7F0D0', '#0F8F44'];

      case 'generating-traffic':
        return ['#FFD8B3', '#F82F02'];

      default:
        return ['#C7F0D0', '#0F8F44'];
    }
  }, [type]);

  const chip = (
    <Stack
      sx={CHIP(bgColor, textColor)}
      alignItems={'start'}
      justifyContent={'center'}
    >
      {(type === 'running' || type === 'generating-traffic') && progress && (
        <Stack
          sx={PROGRESS_BOX(progressBgColor, progressTextColor)}
          width={`${progress}%`}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Typography variant="body1" sx={TEXT} textOverflow={'clip'}>
            {statusTitle}
          </Typography>
        </Stack>
      )}
      <Typography variant="body1" sx={TEXT} textOverflow={'ellipsis'}>
        {statusTitle}
      </Typography>
    </Stack>
  );

  if (type === 'running' || type === 'generating-traffic') {
    return (
      <Tooltip
        title={
          type === 'running'
            ? `Running: ${progress || 0}%`
            : `Generating Traffic: ${progress || 0}%`
        }
        placement="top"
      >
        {chip}
      </Tooltip>
    );
  }

  return chip;
}
