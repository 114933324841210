import { Box, Button, Stack, Typography } from '@mui/material';
import { ChangeEventHandler } from 'react';
import TextFieldTitle from '../../Common/TextFieldTitle';
import { SourceDataBase } from './EditSourceForm';

export interface PostmanSourceData extends SourceDataBase {
  integration?: 'postman';
  file?: File | string;
}

interface Props {
  data: PostmanSourceData;
  onChanged: (data: PostmanSourceData) => void;
  disabled?: boolean;
}

export default function EditSourceFormPostmanFile({
  data,
  onChanged,
  disabled,
}: Props) {
  const handleFile: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    onChanged({ ...data, file: file });
  };

  return (
    <Stack spacing={2}>
      <Box>
        <TextFieldTitle title="Upload File" />
        <Stack direction={'row'} gap={2} alignItems={'center'}>
          <Button variant="contained" component="label" disabled={disabled}>
            Upload File
            <input type="file" hidden onChange={handleFile} />
          </Button>
          {data.file ? (
            <Typography>
              {typeof data.file === 'object' ? data.file.name : data.file}
            </Typography>
          ) : (
            <></>
          )}
        </Stack>
      </Box>
    </Stack>
  );
}
