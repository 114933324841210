import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEventHandler } from 'react';
import { InsertDriveFileIcon } from '../../../assets/svgs/icons/InsertDriveFileIcon';
import { LinkIcon } from '../../../assets/svgs/icons/LinkIcon';
import { isValidUrl } from '../../../utils/validator';
import TextFieldTitle from '../../Common/TextFieldTitle';
import { SourceDataBase } from './EditSourceForm';

const SELECT_ICON_WRAPPER: SxProps = {
  bgcolor: '#EFF5FF',
  width: 24,
  height: 24,
  borderRadius: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export interface SwaggerSourceData extends SourceDataBase {
  integration?: 'swagger';
  swaggerType?: 'link' | 'file';
  url?: string;
  swaggerFile?: File | string;
}

interface Props {
  data: SwaggerSourceData;
  onChanged: (data: SwaggerSourceData) => void;
  disabled?: boolean;
}

export default function EditSourceFormSwagger({
  data,
  onChanged,
  disabled,
}: Props) {
  const handleFile: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    onChanged({ ...data, swaggerFile: file });
  };

  return (
    <Stack spacing={2}>
      <Box>
        <TextFieldTitle title="Type" />
        <FormControl
          variant="filled"
          fullWidth
          size="small"
          disabled={disabled}
        >
          <Select
            disableUnderline
            value={data.swaggerType ?? ''}
            onChange={(v) =>
              onChanged({ ...data, swaggerType: v.target.value as any })
            }
          >
            <MenuItem value={'link'}>
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Box sx={SELECT_ICON_WRAPPER}>
                  {<LinkIcon size={20} color="#BFCEE5" />}
                </Box>
                <span>Link</span>
              </Stack>
            </MenuItem>
            <MenuItem value={'file'}>
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Box sx={SELECT_ICON_WRAPPER}>
                  {<InsertDriveFileIcon size={20} color="#BFCEE5" />}
                </Box>
                <span>File</span>
              </Stack>
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      {data.swaggerType === 'link' ? (
        <Box>
          <TextFieldTitle title="Insert Link" />
          <TextField
            placeholder="Link..."
            fullWidth
            variant="filled"
            size="small"
            value={data.url || ''}
            onChange={(v) =>
              onChanged({ ...data, url: v.target.value?.trim() })
            }
            error={data.url ? !isValidUrl(data.url) : false}
            helperText={
              data.url
                ? isValidUrl(data.url)
                  ? undefined
                  : 'Invalid link'
                : undefined
            }
            disabled={disabled}
          />
        </Box>
      ) : (
        <></>
      )}
      {data.swaggerType === 'file' ? (
        <Box>
          <TextFieldTitle title="Upload File" />
          <Stack direction={'row'} gap={2} alignItems={'center'}>
            <Button variant="contained" component="label" disabled={disabled}>
              Upload File
              <input type="file" hidden onChange={handleFile} />
            </Button>
            {data.swaggerFile ? (
              <Typography>
                {typeof data.swaggerFile === 'object'
                  ? data.swaggerFile.name
                  : data.swaggerFile}
              </Typography>
            ) : (
              <></>
            )}
          </Stack>
        </Box>
      ) : (
        <></>
      )}
    </Stack>
  );
}
