import { Evidence, Vulnerability, VulnerabilityTicket } from '../models/vulnerability';
import { get, post, put, PyntFilter } from './BaseService';
import vulnerabilities from '../assets/data/vulnerabilities.json';

type APIVulnerability = {
  id: string;
  application_id: string;
  category: string;
  subcategory: string;
  endpoint: string;
  curl_cmd: string | null;
  evidence: Evidence[];
  status: string;
  vulnerability_severity: number;
  variables: Record<string, string>;
  tickets: VulnerabilityTicket[];
};

const fillInfo = (line: string, params: Record<string, string>) => {
  let newLine = line.replace(/\{\{\./g, '{{'); // maybe God knows why, !@#!@
  Object.entries(params).forEach(([key, value]) => {
    newLine = newLine.replace(new RegExp(`\{\{${key}\}\}`, 'g'), value);
  });
  return newLine;
};

const apiVulnerabilityToVulnerability = (
  apiVulnerability: APIVulnerability,
): Vulnerability => {
  const key = `${apiVulnerability.category}::${apiVulnerability.subcategory}`;
  if (!(key in vulnerabilities)) {
    throw new Error(`key ${key} of category and subcategory wasn't found`);
  }
  const vulnerabilityData =
    vulnerabilities[key as keyof typeof vulnerabilities];
  return {
    ...apiVulnerability,
    name: fillInfo(vulnerabilityData.nameTemplate, apiVulnerability.variables),
    category: fillInfo(vulnerabilityData.categoryTemplate, apiVulnerability.variables),
    description: fillInfo(
      vulnerabilityData.description,
      apiVulnerability.variables,
    ),
    remediation: fillInfo(
      vulnerabilityData.remediation,
      apiVulnerability.variables,
    ),
  };
};

export async function getVulnerabilities(filter: PyntFilter = {}) {
  const res = await get<APIVulnerability[]>('/vulnerability', {
    params: { filter: JSON.stringify(filter) },
  });

  return res.data.map(apiVulnerabilityToVulnerability);
}

export async function getVulnerabilitiesCount(where: PyntFilter['where'] = {}) {
  const res = await get('/vulnerability/count', {
    params: { where: JSON.stringify(where) },
  });

  return res.data;
}

export async function getVulnerabilityById(vulnerabilityId: string) {
  const res = await get<APIVulnerability>(
    `/vulnerability/${vulnerabilityId}`,
  );

  return apiVulnerabilityToVulnerability(res.data);
}

export async function updateVulnerabilityFalsePositiveStatus(
  vulnerabilityId: string,
  falsePositive: boolean,
) {
  await post(`/vulnerability/${vulnerabilityId}/mark-false-positive`, {
    false_positive: falsePositive,
  });
}

export async function updateVulneravilityTags(
  vulnerabilityId: string,
  tags: string[],
) {
  const res = await put('/vulnerability/' + vulnerabilityId + '/tags', tags);

  return res.data;
}

export async function getEvidence(scanId: string, fileName: string) {
  const res = await get<Blob>('/evidence', {
    params: {
      scan_id: scanId,
      file_name: fileName,
    },
    responseType: 'blob',
  });

  return res.data;
}

export async function getTicketMetadata(
  integrationId: string,
) {
  const res = await get('/ticketmetadata', {
    params: {
      ticket_type: 'jira',
      integration_id: integrationId,
    },
    timeout: 300000,
  });

  return res.data;
}

export async function createTicket(
  vulnerabilityId: string,
  integrationId: string,
  data: any,
) {
  const res = await post('/ticket', {
    ticket_type: 'jira',
    vulnerability_id: vulnerabilityId,
    integration_id: integrationId,
    project_id: data.projectId,
    issue_type_id: data.issueType,
    summary: data.name,
    description: data.description,
  });

  return res.data;
}
