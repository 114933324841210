import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import {
  GridColDef,
  GridColumnsInitialState,
  GridRowId,
} from '@mui/x-data-grid';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  ApplicationContext,
  ApplicationContextProps,
} from '../../contexts/Application';
import { AuthContext } from '../../contexts/Auth';
import { usePersistedJsonState } from '../../hooks/usePersistedState';
import { TestCase } from '../../models/test-case';
import { getScanProfile, updateScanProfile } from '../../services/TestsService';
import PyntDataGrid from '../Common/PyntDataGrid';
import TestCategoryChip from './TestCategoryChip';
import vulnerabilities from '../../assets/data/vulnerabilities.json';

interface Props {
  hideFooter?: boolean;
  autoHeight?: boolean;
  autoPageSize?: boolean;
}

export default function TestCasesGrid({
  hideFooter,
  autoHeight,
  autoPageSize,
}: Props) {
  const { user } = useContext(AuthContext);
  const { application } = useContext(
    ApplicationContext,
  ) as ApplicationContextProps;

  const [scanProfileId, setScanProfileId] = useState();
  const [testCases, setTestCases] = useState<TestCase[]>([]);
  const [loadingTestCases, setIsLoadingTestCases] = useState(true);

  const isAdmin = useMemo(() => user?.role === 'Admin', [user?.role]);

  const [selectedTestsIds, setSelectedTestsIds] = useState<GridRowId[]>([]);
  const [loadingIncludeInScans, setLoadingIncludeInScans] =
    useState<boolean>(false);
  const [loadingExcludeInScans, setLoadingExcludeInScans] =
    useState<boolean>(false);
  const [allowCustomizeTests, setAllowCustomizeTests] =
    useState<boolean>(false);

  const fetchTestCases = useCallback(async () => {
    if (!application) {
      return;
    }
    setIsLoadingTestCases(true);
    const scanProfile = await getScanProfile(application.app_id);
    setScanProfileId(scanProfile?.[0]?.scan_profile_id);
    const includedTests = scanProfile?.[0]?.tests;

    const testCases: TestCase[] = Object.entries(vulnerabilities).map(
      ([_, testCase]) => ({
        ...testCase,
        includedInScans:
          includedTests?.[testCase.category]?.[testCase.subcategory]
            ?.should_attack ?? testCase.enabled,
      }),
    );
    setTestCases(testCases);
    setIsLoadingTestCases(false);
  }, [application, setIsLoadingTestCases, setScanProfileId, setTestCases]);

  useEffect(() => {
    if (!application) {
      return;
    }
    fetchTestCases();
  }, [application]);

  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: 'Test Name',
          flex: 1.5,
        },
        {
          field: 'category',
          headerName: 'Category',
          flex: 1,
          renderCell: ({ value }) => <TestCategoryChip category={value} />,
        },
        {
          field: 'includedInScans',
          headerName: 'Included in Scans',
          valueGetter: ({ row }) => (row.includedInScans ? 'Yes' : 'No'),
          flex: 1.5,
        },
      ] as GridColDef[],
    [],
  );

  const [gridColumnsState, setGridColumnsState] =
    usePersistedJsonState<GridColumnsInitialState>(
      'TestCasesGridColumnsState',
      {
        columnVisibilityModel: {},
      },
    );

  const gridInitialState = useMemo<GridInitialStateCommunity>(() => {
    return {
      sorting: { sortModel: [{ field: 'status', sort: 'asc' }] },
      columns: gridColumnsState,
    };
  }, [gridColumnsState]);

  const allowIncludeInScans = useMemo(() => {
    return selectedTestsIds.every((id) => {
      const testCase = testCases.find(
        (testCase: TestCase) =>
          `${testCase.category}-${testCase.subcategory}` === id,
      );
      return testCase?.includedInScans === false;
    });
  }, [selectedTestsIds]);

  const allowExcludeInScans = useMemo(() => {
    return selectedTestsIds.every((id) => {
      const testCase = testCases.find(
        (testCase: TestCase) =>
          `${testCase.category}-${testCase.subcategory}` === id,
      );
      return testCase?.includedInScans === true;
    });
  }, [selectedTestsIds]);

  const includeExcludeInScans = async (action: 'include' | 'exclude') => {
    const tests = testCases.reduce((acc: any, testCase) => {
      // Initialize the category if it doesn't exist
      if (!acc[testCase.category]) {
        acc[testCase.category] = {};
      }

      // Initialize the subcategory if it doesn't exist
      if (!acc[testCase.category][testCase.subcategory]) {
        acc[testCase.category][testCase.subcategory] = {};
      }

      if (
        selectedTestsIds.includes(
          `${testCase.category}-${testCase.subcategory}`,
        )
      ) {
        // Assign the should_attack property
        acc[testCase.category][testCase.subcategory] = {
          should_attack: action === 'include',
        };
      } else {
        // Assign the should_attack property
        acc[testCase.category][testCase.subcategory] = {
          should_attack: testCase.includedInScans,
        };
      }

      return acc;
    }, {});

    if (action === 'include') {
      setLoadingIncludeInScans(true);
    } else {
      setLoadingExcludeInScans(true);
    }

    const data: any = {
      name: 'default',
      tests,
      application_id: application?.app_id,
    };
    if (scanProfileId) {
      data['scan_profile_id'] = scanProfileId;
    }
    await updateScanProfile(data)
      .then(fetchTestCases)
      .finally(() => {
        if (action === 'include') {
          setLoadingIncludeInScans(false);
        } else {
          setLoadingExcludeInScans(false);
        }
        setSelectedTestsIds([]);
      });
  };

  return (
    <>
      {isAdmin && (
        <Stack
          sx={{ width: '100%', bgcolor: 'white', borderRadius: 1, p: 2 }}
          justifyContent={'start'}
          alignItems={'center'}
          direction={'row'}
          spacing={2}
        >
          <Typography
            sx={{
              textAlign: 'start',
              fontWeight: 500,
              fontSize: 18,
            }}
          >
            All Tests
          </Typography>
          {isAdmin && (
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'start' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setAllowCustomizeTests(!allowCustomizeTests);
                  setSelectedTestsIds([]);
                }}
                sx={{ width: 'fit-content' }}
                size="small"
              >
                {allowCustomizeTests ? 'Cancel' : 'Customize Tests'}
              </Button>
            </Box>
          )}
          {allowCustomizeTests && (
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={() => includeExcludeInScans('include')}
              sx={{ width: 'fit-content' }}
              size="small"
              loading={loadingIncludeInScans}
              disabled={
                selectedTestsIds.length === 0 ||
                loadingExcludeInScans ||
                (!allowIncludeInScans && allowExcludeInScans)
              }
            >
              Include in Scans
            </LoadingButton>
          )}
          {allowCustomizeTests && (
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={() => includeExcludeInScans('exclude')}
              sx={{ width: 'fit-content' }}
              size="small"
              loading={loadingExcludeInScans}
              disabled={
                selectedTestsIds.length === 0 ||
                loadingIncludeInScans ||
                (allowIncludeInScans && !allowExcludeInScans)
              }
            >
              Exclude from Scans
            </LoadingButton>
          )}
        </Stack>
      )}
      <PyntDataGrid
        checkboxSelection={allowCustomizeTests}
        rowSelectionModel={selectedTestsIds}
        autoHeight={autoHeight}
        autoPageSize={autoPageSize}
        hideFooter={hideFooter}
        sx={{ borderRadius: 1 }}
        onRowSelectionModelChange={(selection) =>
          setSelectedTestsIds(selection)
        }
        rows={testCases}
        getRowId={(row) => `${row.category}-${row.subcategory}`}
        loading={loadingTestCases}
        columns={columns}
        initialState={gridInitialState}
        onStateChange={(state) => {
          setGridColumnsState(state.columns);
        }}
      />
    </>
  );
}
