import { Components } from '@mui/material';
import type {} from '@mui/x-data-grid/themeAugmentation';
import themedMuiButton from './MuiButton';
import themedMuiDataGrid from './MuiDataGrid';
import themedMuiFilledInput from './MuiFilledInput';
import themedMuiFormLabel from './MuiFormLabel';
import themedMuiPaper from './MuiPaper';
import themedMuiSelect from './MuiSelect';
import themedMuiTab from './MuiTab';
import themedMuiTooltip from './MuiTooltip';

export default function ComponentsOverrides(): Components {
  return Object.assign(
    themedMuiButton(),
    themedMuiDataGrid(),
    themedMuiFilledInput(),
    themedMuiSelect(),
    themedMuiFormLabel(),
    themedMuiPaper(),
    themedMuiTooltip(),
    themedMuiTab(),
    themedMuiFilledInput(),
  ) as Components;
}
