import * as Sentry from '@sentry/react';
import { post } from './BaseService';

export async function uploadFile(file: File, folder: string, prefix?: string) {
  const {
    upload_url: uploadUrl,
    path,
    bucket_name: bucketName,
  } = await getUploadUrl(folder, prefix ? prefix + file.name : file.name);

  const arrayBuffer = await file.arrayBuffer();

  await fetch(uploadUrl, {
    method: 'PUT',
    body: arrayBuffer,
  }).catch((error) => {
    Sentry.captureException(error);
    throw error;
  });

  return {
    path,
    bucketName,
  };
}

export async function getUploadUrl(
  folder: string,
  fileName: string,
): Promise<{
  upload_url: string;
  path: string;
  bucket_name: string;
}> {
  const uploadUrlResponse = await post('/artifacts/upload-url', {
    folder: folder,
    file_name: fileName,
  });

  return uploadUrlResponse.data;
}

export async function getDownloadUrl(
  folder: string,
  fileName: string,
): Promise<string> {
  const downloadUrlResponse = await post('/artifacts/download-url', {
    folder: folder,
    file_name: fileName,
  });

  return downloadUrlResponse.data;
}
