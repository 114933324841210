// eslint-disable-next-line spaced-comment
/// <reference types="@types/segment-analytics" />

declare global {
  interface Window {
    // eslint-disable-next-line no-undef
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

import Hotjar from '@hotjar/browser';
import { License } from '../models/license';
import { Organization } from '../models/organization';
import { User } from '../models/user';

export const trackOptions: {
  intercomTrack?: (event: string, metaData?: object) => void;
  globalParamters: Record<string, any>;
} = { globalParamters: {} };

export function track(eventName: string, parameters?: Record<string, any>) {
  if (process.env.NODE_ENV === 'development') {
    console.debug('[EVENT_TRACK]', eventName, 'parameters:', {
      ...trackOptions.globalParamters,
      ...parameters,
    });
  }

  try {
    window.analytics.track(eventName, {
      ...trackOptions.globalParamters,
      ...(parameters || {}),
      url: window.location.href,
    });
  } catch (e) {
    console.error('failed to send event to segment');
  }

  // try {
  //   if (process.env.REACT_APP_NODE_ENV !== 'development') {
  //     mixpanel.track(eventName, {
  //       ...trackOptions.globalParamters,
  //       ...(parameters || {}),
  //       url: window.location.href,
  //     });
  //   }
  // } catch (e) {
  //   console.error('failed to send event to mixpanel');
  //   // TODO: report to sentry
  // }

  try {
    if (Hotjar.isReady()) {
      Hotjar.event(eventName);
    }
  } catch (e) {
    console.error('failed to send event to hotjar');
    // TODO: report to sentry
  }

  try {
    if (trackOptions.intercomTrack) {
      trackOptions.intercomTrack(eventName, {
        ...trackOptions.globalParamters,
        ...(parameters || {}),
        url: window.location.href,
      });
    }
  } catch (e) {
    console.error('failed to send event to intercom');
    // TODO: report to sentry
  }
}

export function trackPage() {
  if (process.env.NODE_ENV === 'development') {
    try {
      console.debug('[PAGE_EVENT_TRACK]', window.location);
    } catch (e) {
      console.error('failed to log page event');
    }
  }

  try {
    window.analytics.page();
  } catch (e) {
    console.error('failed to send page event to segment');
  }
}

export function analyticsIdentify(
  userId?: string,
  email?: string,
  user?: User,
  organization?: Organization,
  license?: License,
) {
  if (process.env.NODE_ENV === 'development') {
    try {
      console.debug('[IDENTIFY_USER]', 'userId:', userId, 'user:', user);
    } catch (e) {
      console.error('failed to log identify user event');
    }
  }

  try {
    if (userId) {
      window.analytics.identify(userId, {
        email: user?.email || email,
        ...(user
          ? {
              name: [user.first_name, user.last_name]
                .filter((x) => !!x)
                .join(' '),
              firstName: user.first_name,
              lastName: user.last_name,
              created: user.created_at,
              organizationId: user.organization_id,
              organizationName: organization?.name,
              ...(license
                ? {
                    licenseTier: 'business',
                    licenseStartDate: new Date(license.start_date + 'Z'),
                    licenseExpirationDate: new Date(
                      license.expiration_date + 'Z',
                    ),
                  }
                : {}),
            }
          : {}),
      });
    }
    if (organization) {
      window.analytics.group(organization.organization_id, {
        organizationId: organization.organization_id,
        name: organization.name,
        ...(license
          ? {
              licenseTier: 'business',
              licenseStartDate: new Date(license.start_date + 'Z'),
              licenseExpirationDate: new Date(license.expiration_date + 'Z'),
            }
          : {}),
      });
    }
  } catch (e) {
    console.error('failed to identify user with segment');
  }

  try {
    if (user) {
      trackOptions.globalParamters.email = user.email;
      trackOptions.globalParamters.name = user.first_name;
      trackOptions.globalParamters.lastname = user.last_name;
    }
  } catch (e) {
    console.error('failed to add user props to global params');
  }
}

export function connectAnonymousUser(anonymousId: string) {
  window.analytics.ready(() => {
    const userId = window.analytics.user().id();
    const currAnonymousId = window.analytics.user().anonymousId();
    window.analytics.alias(userId || currAnonymousId, anonymousId);
    track('linked_users_from_website');
  });
}
