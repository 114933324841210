import { Box, IconButton, Stack, SxProps, Typography } from '@mui/material';
import { useContext } from 'react';
import { CopyIcon2 } from '../../assets/svgs/icons/CopyIcon2';
import { SnackbarContext } from '../../contexts/Snackbar';
import {
  getStoredAccessToken,
  getStoredRefreshToken,
} from '../../services/BaseService';
import { track } from '../../utils/analytics';
import TextFieldTitle from '../Common/TextFieldTitle';
import { RunScanData } from './RunScanDrawer';

const COPY_BOX: SxProps = {
  bgcolor: '#eeeeee',
  position: 'relative',
  border: '1px solid #D3D3D3',
  p: 2,
  borderRadius: 1,
};

const TEXT: SxProps = {
  fontSize: 14,
};

interface Props {
  data: RunScanData;
}

const RunScanInstructionsWizard = ({ data }: Props) => {
  if (data.integration === 'postman') {
    return <PostmanRunScanInstructionsWizard data={data} />;
  } else if (data.integration === 'newman') {
    return <NewmanRunScanInstructionsWizard data={data} />;
  } else if (data.integration === 'python') {
    return <PythonRunScanInstructionsWizard data={data} />;
  } else if (data.integration === 'restAssured') {
    return <RestAssuredRunScanInstructionsWizard data={data} />;
  } else if (data.type === 'cicd') {
    return <CICDRunScanInstructionsWizard />;
  }

  return <></>;
};

export default RunScanInstructionsWizard;

const PostmanRunScanInstructionsWizard = ({ data }: Props) => {
  return (
    <>
      <Typography sx={TEXT}>
        1. Download and run Pynt CLI by executing the following Python commands
        from the cmd (make sure you have{' '}
        <a
          href="https://docs.docker.com/get-docker/"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            track('web_app_run_scan_drawer_download_docker_button_click', {
              ...data,
            });
          }}
        >
          docker desktop
        </a>{' '}
        installed):
      </Typography>

      <Stack direction={'column'} spacing={1} pb={1}>
        <CopyField
          text="pip install pyntcli"
          onClick={() => {
            track('web_app_run_scan_drawer_copy_button_click', {
              ...data,
              step: 1,
            });
          }}
        />
        <CopyField
          text="pynt postman"
          onClick={() => {
            track('web_app_run_scan_drawer_copy_button_click', {
              ...data,
              step: 2,
            });
          }}
        />
      </Stack>
      <Stack direction={'row'} gap={3}>
        <Typography sx={TEXT}>
          2. Open Postman App{' '}
          <a
            href="https://www.postman.com/downloads/"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              track('web_app_run_scan_drawer_no_postman_app_button_click', {
                ...data,
              });
            }}
          >
            I don&apos;t have the postman app
          </a>
        </Typography>
      </Stack>
      <Stack direction={'row'} gap={3}>
        <Typography sx={TEXT}>
          3. Run Pynt collection from your workspace{' '}
          <a
            href="https://global-uploads.webflow.com/6357c772bd0a59790d6cfdf5/63a3fe31c8c1bd279fe5db41_how_run_it.png"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              track('web_app_run_scan_drawer_how_to_run_button_click', {
                ...data,
              });
            }}
          >
            How do I run it?
          </a>
        </Typography>
      </Stack>
    </>
  );
};

const NewmanRunScanInstructionsWizard = ({ data }: Props) => {
  return (
    <>
      <Typography sx={TEXT}>1. First run our test app called GOAT:</Typography>

      <Stack direction={'column'} spacing={1} pb={1}>
        <CopyField
          text={
            'wget https://raw.githubusercontent.com/pynt-io/pynt/main/goat_functional_tests/goat.postman_collection.json'
          }
          onClick={() => {
            track('web_app_run_scan_drawer_copy_button_click', {
              ...data,
              step: 1,
            });
          }}
        />
        <CopyField
          text={'pynt newman --collection goat.postman_collection.json'}
          onClick={() => {
            track('web_app_run_scan_drawer_copy_button_click', {
              ...data,
              step: 2,
            });
          }}
        />
      </Stack>
      <Stack direction={'column'} spacing={1} pb={1}>
        <Typography sx={TEXT}>2. Run your own app</Typography>
        <CopyField
          text={'pynt newman --collection <postman-collection-file-name>'}
          onClick={() => {
            track('web_app_run_scan_drawer_copy_button_click', {
              ...data,
              step: 2,
            });
          }}
        />
      </Stack>

      <Typography sx={TEXT} pb={1}>
        3. For further instructions or issue troubleshooting{' '}
        <a
          href="https://docs.pynt.io/documentation/security-testing/getting-started/pynt-newman"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            track('web_app_run_scan_drawer_docs_button_click', {
              ...data,
            });
          }}
        >
          see our docs
        </a>
        .
      </Typography>
    </>
  );
};

const PythonRunScanInstructionsWizard = ({ data }: Props) => {
  return (
    <>
      <Typography sx={TEXT}>1. First run our test app called GOAT:</Typography>

      <Stack direction={'column'} spacing={1} pb={1}>
        <CopyField
          text={
            'wget https://raw.githubusercontent.com/pynt-io/pynt/main/goat_functional_tests/goat_functional_test.py'
          }
          onClick={() => {
            track('web_app_run_scan_drawer_copy_button_click', {
              ...data,
              step: 1,
            });
          }}
        />
        <CopyField
          text={'pynt command --cmd "pytest goat_functional_test.py"'}
          onClick={() => {
            track('web_app_run_scan_drawer_copy_button_click', {
              ...data,
              step: 2,
            });
          }}
        />
      </Stack>
      <Stack direction={'column'} spacing={1} pb={1}>
        <Typography sx={TEXT}>2. Run your own app</Typography>
        <CopyField
          text={'pynt command --cmd "pytest <tests-file-name>"'}
          onClick={() => {
            track('web_app_run_scan_drawer_copy_button_click', {
              ...data,
              step: 3,
            });
          }}
        />
      </Stack>

      <Typography sx={TEXT} pb={1}>
        3. For further instructions or issue troubleshooting{' '}
        <a
          href="https://docs.pynt.io/documentation/pynt-examples/pynt-with-testing-frameworks/pytest"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            track('web_app_run_scan_drawer_docs_button_click', {
              ...data,
            });
          }}
        >
          see our docs
        </a>
        .
      </Typography>
    </>
  );
};

const RestAssuredRunScanInstructionsWizard = ({ data }: Props) => {
  return (
    <>
      <Typography sx={TEXT}>1. First run our test app called GOAT:</Typography>

      <Stack direction={'column'} spacing={1} pb={1}>
        <CopyField
          text={'git clone https://github.com/pynt-io/pynt.git'}
          onClick={() => {
            track('web_app_run_scan_drawer_copy_button_click', {
              ...data,
              step: 1,
            });
          }}
        />
        <CopyField
          text={'cd ./pynt/goat_functional_tests/goat-rest-assured'}
          onClick={() => {
            track('web_app_run_scan_drawer_copy_button_click', {
              ...data,
              step: 2,
            });
          }}
        />
        <CopyField
          text={
            'pynt command --cmd "mvn -Dhttp.proxyHost=127.0.0.1 -Dhttp.proxyPort=6666 test"'
          }
          onClick={() => {
            track('web_app_run_scan_drawer_copy_button_click', {
              ...data,
              step: 3,
            });
          }}
        />
      </Stack>
      <Stack direction={'column'} spacing={1} pb={1}>
        <Typography sx={TEXT}>2. Run your own app</Typography>
        <CopyField
          text={
            'pynt command --cmd "mvn -Dhttp.proxyHost=127.0.0.1 -Dhttp.proxyPort=6666 test"'
          }
          onClick={() => {
            track('web_app_run_scan_drawer_copy_button_click', {
              ...data,
              step: 4,
            });
          }}
        />
      </Stack>

      <Typography sx={TEXT} pb={1}>
        3. For further instructions or issue troubleshooting{' '}
        <a
          href="https://docs.pynt.io/documentation/pynt-examples/pynt-with-testing-frameworks/pytest"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            track('web_app_run_scan_drawer_docs_button_click', {
              ...data,
            });
          }}
        >
          see our docs
        </a>
        .
      </Typography>
    </>
  );
};

const CICDRunScanInstructionsWizard = () => {
  return (
    <Box>
      <TextFieldTitle title="Your Pynt ID" />
      <CopyField
        title="*****************************"
        text={JSON.stringify({
          token: {
            access_token: getStoredAccessToken(),
            refresh_token: getStoredRefreshToken(),
            token_type: 'Bearer',
          },
        })}
      />
    </Box>
  );
};

interface CopyFieldProps {
  text: string;
  title?: string;
  onClick?: () => void;
}
const CopyField = ({ text, title, onClick }: CopyFieldProps) => {
  const { show } = useContext(SnackbarContext);

  return (
    <Box
      sx={COPY_BOX}
      onClick={() => {
        navigator.clipboard.writeText(text);
        show('Copied to clipboard');
        onClick?.();
      }}
    >
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography
          sx={{ wordBreak: 'break-all', fontSize: 14 }}
          paddingInlineEnd={4}
        >
          {title || text}
        </Typography>
        <IconButton
          sx={{ position: 'absolute', right: 8 }}
          onClick={() => {
            navigator.clipboard.writeText(text);
            show('Copied to clipboard');
            onClick?.();
          }}
        >
          <CopyIcon2 color="#FF8C00" />
        </IconButton>
      </Stack>
    </Box>
  );
};
