import { BrowserUpdatedOutlined, Download } from '@mui/icons-material';
import { Box, Skeleton, Stack, Typography, Tooltip } from '@mui/material';
import {
  GridActionsCellItem,
  GridColDef,
  GridColumnsInitialState,
  GridFilterModel,
  GridRowParams,
} from '@mui/x-data-grid';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid-pro';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ChevronRightIcon } from '../../assets/svgs/icons/ChevronRightIcon';
import { ClockIcon } from '../../assets/svgs/icons/ClockIcon';
import { DangerIcon } from '../../assets/svgs/icons/DangerIcon';
import { DocumentText } from '../../assets/svgs/icons/DocumentText';
import { BRAND_DARK_BLUE } from '../../constants/colors';
import { AuthContext } from '../../contexts/Auth';
import { SnackbarContext } from '../../contexts/Snackbar';
import { UserContext, UserContextProps } from '../../contexts/User';
import { usePersistedJsonState } from '../../hooks/usePersistedState';
import { ScanHistoryItem } from '../../models/scanHistory';
import { PyntFilter } from '../../services/BaseService';
import {
  downloadPostmanCollection,
  downloadRequestsLog,
  getScansWithInProgress,
  getScansWithInProgressCount,
} from '../../services/ScansService';
import { formatDuration } from '../../utils';
import { track } from '../../utils/analytics';
import { reformatFilterModel, reformatSortModel } from '../../utils/datagrid';
import { NumberChipIndicator, PyntDataGrid, ScanReportDialog } from '../Common';
import HistoryScanDrawer from './HistoryScanDrawer';
import HistoryScanIntegrationIcon from './HistoryScanIntegrationIcon';
import HistoryScanStatusChip from './HistoryScanStatusChip';

const NumberChipSkeleton = () => (
  <Skeleton
    variant="rectangular"
    width={28}
    height={28}
    sx={{ borderRadius: '6px' }}
  />
);

interface Props {
  id?: string;
  filter?: GridFilterModel;
  sorting?: GridSortModel;
  hideFooter?: boolean;
  showOpenReportButton?: boolean;
  showDownloadRequestsLogButton?: boolean;
  openDetailsOnSelect?: boolean;
  pagination?: Partial<GridPaginationModel>;
  autoHeight?: boolean;
  autoPageSize?: boolean;
  onRowClick?: (row: any) => void;
  applicationFilter?: boolean;
  limit?: number | undefined;
  pyntFilter?: PyntFilter['where'] | any;
  hideActions?: boolean;
}

export default function ScanHistoryGrid({
  id = '',
  filter,
  hideFooter,
  pagination,
  showOpenReportButton,
  showDownloadRequestsLogButton,
  openDetailsOnSelect,
  autoHeight = true,
  autoPageSize = false,
  onRowClick,
  limit,
  pyntFilter,
  hideActions = false,
}: Props) {
  const { show } = useContext(SnackbarContext);
  const { organization } = useContext(AuthContext);
  const { applications, getAppByIdSync } = useContext(
    UserContext,
  ) as UserContextProps;

  const [scans, setScans] = useState<ScanHistoryItem[]>();
  const [, setIsLoadingScansHistory] = useState(false);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'scan_time', sort: 'desc' },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [count, setCount] = useState<number>();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedHistoryScan, setSelectedHistoryScan] = useState<
    ScanHistoryItem | undefined
  >();
  const [scanReportDialogOpen, setScanReportDialogOpen] = useState(false);

  useEffect(() => {
    const storedScans = localStorage.getItem(`scans-history-${id}`);
    if (storedScans) {
      try {
        const parsedScans = JSON.parse(storedScans);
        if (!parsedScans.length) return;
        setScans(parsedScans);
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  const fetchScans = useCallback(async () => {
    setIsLoadingScansHistory(true);

    const filter: PyntFilter = {
      where: { ...reformatFilterModel(filterModel, columns), ...pyntFilter },
      sort: reformatSortModel(sortModel, columns),
      offset: page * pageSize,
      limit: limit ? limit : pageSize,
    };

    const scans = await getScansWithInProgress(filter).catch((e) => {
      console.error(e);
      Sentry.captureException(e);

      return [];
    });

    if (page === 0) {
      if (scans) {
        localStorage.setItem(`scans-history-${id}`, JSON.stringify(scans));
      } else {
        localStorage.removeItem(`scans-history-${id}`);
      }
    }

    setScans(scans);
    setIsLoadingScansHistory(false);

    const scansCount = await getScansWithInProgressCount(filter.where).catch(
      (e) => {
        console.error(e);
        Sentry.captureException(e);
      },
    );

    setCount(scansCount?.count);

    return scans;
  }, [filterModel, sortModel, page, pageSize, pyntFilter]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchScans();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [filterModel, sortModel, page, pageSize, pyntFilter]);

  useEffect(() => {
    const interval = setInterval(
      () => {
        fetchScans();
      },
      scans?.some((s) => s.scan_status === 'running') ? 3000 : 10000,
    );

    return () => {
      clearInterval(interval);
    };
  }, [scans]);

  const allowDownloadRequestsLogForScan = useCallback(
    (scan: any) => {
      if (
        !organization?.config?.allow_collect_data ||
        !organization.config.allow_collect_data_since
      ) {
        return false;
      }

      if (scan.scan_status?.toLowerCase() !== 'completed') {
        return false;
      }

      const since = new Date(
        organization.config.allow_collect_data_since + 'Z',
      );
      const scanTime = new Date(scan.scan_time + 'Z');

      return scanTime >= since;
    },
    [organization],
  );

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'scan_id', headerName: 'Scan ID', width: 90, sortable: false },
      {
        field: 'scan_time',
        headerName: 'Time',
        width: 160,
        type: 'dateTime',
        filterable: false,
        filterField: 'data.summary.scan_time',
        valueGetter: ({ row }) =>
          row.scan_time ? new Date(row.scan_time + 'Z') : null,
        renderCell: ({ value }: { value?: Date }) =>
          value ? <>{dayjs(value).format('DD MMM YYYY (HH:mm)')}</> : <></>,
      },
      {
        field: 'scan_status',
        headerName: 'Status',
        width: 110,
        type: 'singleSelect',
        sortable: false,
        filterable: false,
        renderCell: ({ value, row }) => (
          <HistoryScanStatusChip
            type={
              row.progress_type === 'traffic' ? 'generating-traffic' : value
            }
            progress={
              row.traffic_progress > 0
                ? row.traffic_progress
                : row.scan_progress
            }
          />
        ),
        filterField: 'data.summary.scan_status',
      },
      {
        field: 'errors',
        headerName: '#Errors',
        width: 100,
        type: 'number',
        align: 'center',
        headerAlign: 'center',
        filterField: 'data.summary.errors',
        renderCell: ({ value, row }) =>
          row.scan_status === 'running' ? (
            <NumberChipSkeleton />
          ) : (
            <NumberChipIndicator
              value={value}
              color={'#E2432930'}
              labelColor={'#F82F02'}
            />
          ),
      },
      {
        field: 'warnings',
        headerName: '#Warnings',
        width: 100,
        type: 'number',
        align: 'center',
        headerAlign: 'center',
        filterField: 'data.summary.warnings',
        renderCell: ({ value, row }) =>
          row.scan_status === 'running' ? (
            <NumberChipSkeleton />
          ) : (
            <NumberChipIndicator
              value={value}
              color={'#ffddb2'}
              labelColor={'#B95711'}
            />
          ),
      },
      {
        field: 'source',
        type: 'singleSelect',
        headerName: 'Integration',
        valueGetter: ({ row }) => row.source?.integraion,
        width: 100,
        align: 'center',
        sortable: false,
        filterable: false,
        valueOptions: [
          { value: 'postman', label: 'Postman' },
          { value: 'newman', label: 'Newman' },
          { value: 'proxy', label: 'Command' },
        ],
        renderCell: ({ value, row }) =>
          row.scan_status === 'running' ? (
            <NumberChipSkeleton />
          ) : !value ? (
            <></>
          ) : (
            <HistoryScanIntegrationIcon type={value} />
          ),
      },
      {
        field: 'application_id',
        headerName: 'Applications',
        width: 150,
        type: 'singleSelect',
        sortable: false,
        filterable: false,
        valueOptions:
          applications?.map((a) => ({ label: a.name, value: a.app_id })) ?? [],
        renderCell: ({ value }) =>
          !applications ? (
            <></>
          ) : (
            <>
              {/* <ConnectApplicationSelector
                selected={value}
                onSelected={(app) => {
                  if (!app) {
                    navigate('/dashboard/setup');
                  } else {
                    show('Connecting application to scan...');
                    connectAppToScan(row.scan_id, app.app_id);
                  }
                }}
              /> */}
              {value ? (
                <Typography>{getAppByIdSync(value)?.name ?? ''}</Typography>
              ) : (
                <Stack gap={1} direction="row" alignItems={'center'}>
                  <DangerIcon size={16} color={'#afafaf'} />
                  <Typography variant="body2" color={'gray'}>
                    None
                  </Typography>
                </Stack>
              )}
            </>
          ),
      },
      {
        field: 'unique_endpoints',
        headerName: 'Endpoints',
        width: 100,
        type: 'number',
        align: 'center',
        headerAlign: 'center',
        filterField: 'data.summary.unique_endpoints',
        renderCell: ({ value, row }) =>
          row.scan_status === 'running' ? (
            <NumberChipSkeleton />
          ) : (
            <NumberChipIndicator
              value={value}
              color={'#F5F8FD'}
              labelColor={BRAND_DARK_BLUE}
              borderColor={'#F5F8FD'}
            />
          ),
      },
      {
        field: 'source.user',
        headerName: 'User',
        width: 150,
        sortable: false,
        filterable: false,
        valueGetter: ({ row }) =>
          [row.first_name, row.last_name].filter((a) => !!a).join(' '),
        renderCell: ({ value }) => (
          <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
            <Box>{value}</Box>
          </Stack>
        ),
      },
      {
        field: 'scan_duration',
        headerName: 'Scan Duration',
        valueGetter: ({ row }) =>
          typeof row.scan_duration === 'number'
            ? Math.round(row.scan_duration / 1e9)
            : undefined,
        width: 80,
        sortable: false,
        filterable: false,
        renderCell: ({ value, row }) =>
          row.scan_status === 'running' ? (
            <Skeleton variant="text" width={48} />
          ) : (
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <ClockIcon size={20} color="#8A9FC2" />

              <Box>{value ? formatDuration(value) : undefined}</Box>
            </Stack>
          ),
      },
      {
        field: 'functional_test_name',
        headerName: 'Test Name',
        minWidth: 150,
        flex: 1,
        sortable: false,
        filterable: false,
        renderCell: ({ value, row }) =>
          row.scan_status === 'running' ? (
            <Skeleton variant="text" width={72} />
          ) : (
            value
          ),
      },
      {
        field: 'tags',
        headerName: 'Tags',
        minWidth: 150,
        flex: 1,
        sortable: false,
        filterable: false,
        filterField: 'data.summary.tags',
        valueGetter: ({ row }) =>
          row.tags ? row.tags.join(', ') : '',
        renderCell: ({ value }: { value?: string }) =>
          value ? <>{value}</> : <></>,
      },
      {
        field: 'passed_tests',
        headerName: '#Passed tests',
        width: 110,
        type: 'number',
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ value }) => (
          <NumberChipIndicator
            value={value}
            color={'rgba(0, 166, 118, 0.1)'}
            labelColor={BRAND_DARK_BLUE}
            borderColor={'rgba(0, 166, 118, 0.1)'}
          />
        ),
      },
      {
        field: 'number_of_requests',
        headerName: '#Requests',
        width: 100,
        type: 'number',
        align: 'center',
        headerAlign: 'center',
        filterField: 'data.summary.number_of_requests',
        renderCell: ({ value }) => (
          <NumberChipIndicator
            value={value}
            color={'#FBFCFF'}
            labelColor={BRAND_DARK_BLUE}
            borderColor={'#F5F8FD'}
          />
        ),
      },
      ...(showOpenReportButton || showDownloadRequestsLogButton
        ? [
          {
            field: 'actions',
            headerName: 'Report',
            type: 'actions',
            sortable: false,
            filterable: false,
            getActions: ({ row }: GridRowParams) =>
              row.scan_status === 'running'
                ? []
                : [
                  ...(row.scan_status === 'Completed' &&
                    row.pynt_collection_uploaded === true
                    ? [
                      <Tooltip title="Download Generated Postman Collection" arrow key={'postman'}>
                      <GridActionsCellItem
                        key={'download-postman-gen-report'}
                        icon={<Download sx={{ height: 24, width: 24 }} />}
                        onClick={() => {
                          track(
                            'web_app_scan_download_postman_collection_button_click',
                            {
                              scan_id: row?.scan_id,
                            },
                          );
                          downloadPostmanCollection(
                            row.scan_id,
                            row.application_id,
                          ).catch((e) => {
                              show('Error downloading collection', 'error');
                            Sentry.captureException(e);
                          });
                        }}
                        label="Download postman collection"
                      /></Tooltip>,
                    ]
                    : []),
                  ...(showOpenReportButton
                    ? [
                      <Tooltip title="Download Pynt Report" arrow key={'report'}>
                      <GridActionsCellItem
                        key={'show-report'}
                        icon={<DocumentText color="#ACACBB" size={24} />}
                        onClick={() => {
                          track('web_app_open_scan_report_button_click', {
                            scan_id: row?.scan_id,
                          });
                          setSelectedHistoryScan(row);
                          setScanReportDialogOpen(true);
                        }}
                        label="Show Report"
                      /></Tooltip>,
                    ]
                    : []),
                  ...(showDownloadRequestsLogButton
                    ? [
                      <Tooltip title="Download Full Requests log" arrow key={'requests'}>
                      <GridActionsCellItem
                        sx={{
                          visibility: allowDownloadRequestsLogForScan(row)
                            ? 'visible'
                            : 'hidden',
                        }}
                        key={'download-requests-log'}
                        icon={<BrowserUpdatedOutlined />}
                        onClick={() => {
                          track(
                            'web_app_download_requests_log_button_click',
                            {
                              scan_id: row?.scan_id,
                            },
                          );
                          show(
                            'Downloading requests log, might take a few seconds...',
                          );
                          downloadRequestsLog(row.scan_id).catch((e) => {
                            show(
                              'Failed to download requests log',
                              'error',
                            );
                            Sentry.captureException(e);
                          });
                        }}
                        label="Download Requests Log"
                      /></Tooltip>,
                    ]
                    : []),
                ],
          },
        ]
        : []),
      {
        field: 'continue',
        headerName: '',
        renderCell: ({ row }) =>
          row.scan_status === 'running' ? <></> : <ChevronRightIcon />,
      },
    ],
    [
      applications,
      showOpenReportButton,
      showDownloadRequestsLogButton,
      allowDownloadRequestsLogForScan,
    ],
  );

  const [gridColumnsState, setGridColumnsState] =
    usePersistedJsonState<GridColumnsInitialState>(
      'ScanHistoryGridColumnsState',
      {
        columnVisibilityModel: {
          scan_id: false,
          passed_tests: false,
          number_of_requests: false,
        },
      },
    );

  const gridInitialState = useMemo<GridInitialStateCommunity>(() => {
    return {
      columns: gridColumnsState,
      pagination: { paginationModel: pagination ?? { pageSize: 10 } },
    };
  }, [gridColumnsState, filter, pagination]);

  return (
    <>
      <HistoryScanDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        historyScan={selectedHistoryScan}
        onOpenReportClick={() => {
          track('web_app_open_scan_report_button_click', {
            scan_id: selectedHistoryScan?.scan_id,
          });
          setScanReportDialogOpen(true);
        }}
      />
      <ScanReportDialog
        scanId={selectedHistoryScan?.scan_id}
        open={scanReportDialogOpen}
        setOpen={setScanReportDialogOpen}
      />
      <PyntDataGrid
        paginationMode="server"
        filterMode="server"
        sortingMode={'server'}
        sortModel={sortModel}
        filterModel={filterModel}
        onSortModelChange={(sortModel) => setSortModel([...sortModel])}
        onFilterModelChange={(filterModel) =>
          setFilterModel({ ...filterModel })
        }
        paginationModel={{ page: page || 0, pageSize: pageSize || 50 }}
        onPaginationModelChange={(paginationModel) => {
          setPage(paginationModel.page);
          setPageSize(paginationModel.pageSize);
        }}
        autoHeight={autoHeight}
        autoPageSize={autoPageSize}
        rows={scans ?? []}
        rowCount={count}
        getRowId={(row) => `${row.scan_id}-${row.scan_duration}`}
        loading={!scans}
        columns={
          hideActions
            ? columns.filter(
              (c) => c.field !== 'actions' && c.field !== 'continue',
            )
            : columns
        }
        initialState={gridInitialState}
        sx={{ borderRadius: 1 }}
        onStateChange={(state) => {
          setGridColumnsState(state.columns);
        }}
        pagination
        pageSizeOptions={[25, 50, 100]}
        onRowClick={({ row }) => {
          // allow to select text without interaption
          if (window.getSelection()?.type === 'Range') return;
          if (onRowClick) {
            onRowClick(row);
            return;
          }
          if (!openDetailsOnSelect) return;

          track('web_app_scan_history_grid_row_click', {
            scan_id: row?.scan_id,
          });
          setSelectedHistoryScan(row);
          setIsOpen(!!row);
        }}
        hideFooter={hideFooter}
      />
    </>
  );
}
