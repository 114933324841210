import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

export const WhiteTooltip = styled(
  ({ className, ...props }: TooltipProps & { maxWidth?: number | string }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
)(({ maxWidth }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    'color': 'white',
    '&::before': {
      boxShadow: '0px 0px 2px 0px #0000001A',
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    padding: 16,
    color: 'black',
    maxWidth: maxWidth ?? 320,
    boxShadow: '0px 0px 4px 0px #0000001A',
  },
}));
