import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SxProps,
} from '@mui/material';
import { ReactNode, useMemo } from 'react';
import apiGatewayIcon from '../../../assets/images/apiGatewayIcon.png';
import harFileIcon from '../../../assets/images/harFileIcon.png';
import github from '../../../assets/images/integrations/github.png';
import gitlab from '../../../assets/images/integrations/gitlab.png';
import jenkins from '../../../assets/images/integrations/jenkins.png';
import newman from '../../../assets/images/integrations/newman.png';
import python from '../../../assets/images/integrations/python.png';
import restAssured from '../../../assets/images/integrations/restAssured.png';
import logFileIcon from '../../../assets/images/logFileIcon.png';
import postman from '../../../assets/images/postman.png';
import swaggerIcon from '../../../assets/images/swaggerIcon.png';
import { InsertDriveFileIcon } from '../../../assets/svgs/icons/InsertDriveFileIcon';
import { IntegrationInstructionsIcon } from '../../../assets/svgs/icons/IntegrationInstructionsIcon';
import { SafetyCheckIcon } from '../../../assets/svgs/icons/SafetyCheckIcon';
import { StorageIcon } from '../../../assets/svgs/icons/StorageIcon';
import TextFieldTitle from '../../Common/TextFieldTitle';
import EditSourceFormAPIGateway, {
  APIGatewaySourceData,
} from './EditSourceFormAPIGateway';
import EditSourceFormPostmanFile, {
  PostmanSourceData,
} from './EditSourceFormPostmanFile';
import EditSourceFormSwagger, {
  SwaggerSourceData,
} from './EditSourceFormSwagger';
import IntegrationOptionChip from './IntegrationOptionChip';

const SELECT_ICON_WRAPPER: SxProps = {
  bgcolor: '#EFF5FF',
  width: 24,
  height: 24,
  borderRadius: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const sourceOriginTypes = [
  'documentation',
  'production',
  'codeRepository',
  'testing',
] as const;

type SourceOriginType = (typeof sourceOriginTypes)[number];

export type IntegrationType =
  | 'swagger'
  | 'postman'
  | 'apiGateway'
  | 'har'
  | 'log'
  | 'custom';

interface SourceOrigin {
  type: SourceOriginType;
  title: string;
  icon: ReactNode;
  disabled?: boolean;
}

interface Integration {
  type: IntegrationType;
  title: string;
  icon: ReactNode;
  disabled?: boolean;
  comingSoon?: boolean;
  link?: string;
}

const TYPE_OPTIONS: SourceOrigin[] = [
  {
    type: 'documentation',
    title: 'Documentation',
    icon: <InsertDriveFileIcon size={20} color="#BFCEE5" />,
  },
  {
    type: 'production',
    title: 'Production',
    icon: <IntegrationInstructionsIcon size={20} color="#BFCEE5" />,
  },
  {
    type: 'testing',
    title: 'Testing',
    icon: <SafetyCheckIcon size={20} color="#BFCEE5" />,
  },
  {
    type: 'codeRepository',
    title: 'Code Repository',
    icon: <StorageIcon size={20} color="#BFCEE5" />,
    disabled: true,
  },
];

const INTEGRATION_OPTIONS: {
  [key in SourceOriginType]: { options: Integration[] };
} = {
  documentation: {
    options: [
      {
        type: 'swagger',
        title: 'Swagger',
        icon: <img src={swaggerIcon} height={20} width={20} />,
      },
      {
        type: 'postman',
        title: 'Postman Collection',
        icon: <img src={postman} height={20} width={20} />,
      },
    ],
  },
  production: {
    options: [
      {
        type: 'apiGateway',
        title: 'API Gateway',
        icon: <img src={apiGatewayIcon} height={20} width={20} />,
      },
      {
        type: 'har',
        title: 'HAR File',
        icon: <img src={harFileIcon} height={20} width={20} />,
        disabled: true,
        comingSoon: true,
      },
      {
        type: 'log',
        title: 'Log File',
        icon: <img src={logFileIcon} height={20} width={20} />,
        disabled: true,
        comingSoon: true,
      },
    ],
  },
  testing: {
    options: [
      {
        type: 'custom',
        title: 'Postman',
        icon: <img src={postman} height={20} width={20} />,
        link: 'https://docs.pynt.io/documentation/onboarding/getting-started/pynt-postman',
      },
      {
        type: 'custom',
        title: 'Newman',
        icon: <img src={newman} height={20} width={20} />,
        link: 'https://docs.pynt.io/documentation/onboarding/getting-started/pynt-newman',
      },
      {
        type: 'custom',
        title: 'Python',
        icon: <img src={python} height={20} width={20} />,
        link: 'https://docs.pynt.io/documentation/pynt-examples/pynt-with-testing-frameworks/pytest',
      },
      {
        type: 'custom',
        title: 'Rest Assured',
        icon: <img src={restAssured} height={20} width={20} />,
        link: 'https://docs.pynt.io/documentation/pynt-examples/pynt-with-testing-frameworks/rest-assured',
      },
      {
        type: 'custom',
        icon: <img src={github} height={20} width={20} />,
        title: 'GitHub Actions',
        link: 'https://docs.pynt.io/documentation/onboarding/getting-started/pynt-on-ci-cd',
      },
      {
        type: 'custom',
        icon: <img src={gitlab} height={20} width={20} />,
        title: 'GitLab',
        link: 'https://docs.pynt.io/documentation/onboarding/getting-started/pynt-on-ci-cd',
      },
      {
        type: 'custom',
        icon: <img src={jenkins} height={20} width={20} />,
        title: 'Jenkins',
        link: 'https://docs.pynt.io/documentation/onboarding/getting-started/pynt-on-ci-cd',
      },
    ],
  },
  codeRepository: {
    options: [],
  },
};

export interface SourceDataBase {
  sourceOrigin?: SourceOriginType;
  integration?: IntegrationType;
}

export type SourceData =
  | SwaggerSourceData
  | PostmanSourceData
  | APIGatewaySourceData;

interface Props {
  data: SourceData;
  onChanged: (data: SourceData | ((curr: SourceData) => SourceData)) => void;
  disabled?: boolean;
}

export default function EditSourceForm({ data, onChanged, disabled }: Props) {
  const integrations = useMemo(
    () =>
      data.sourceOrigin &&
      INTEGRATION_OPTIONS[data.sourceOrigin ?? '']?.options,
    [data.sourceOrigin],
  );

  const innerFields = useMemo(() => {
    switch (data.integration) {
      case 'swagger':
        return (
          <EditSourceFormSwagger
            data={data}
            onChanged={(data) => onChanged(data)}
            disabled={disabled}
          />
        );

      case 'postman':
        return (
          <EditSourceFormPostmanFile
            data={data}
            onChanged={(data) => onChanged(data)}
            disabled={disabled}
          />
        );

      case 'apiGateway':
        return (
          <EditSourceFormAPIGateway
            data={data as any}
            onChanged={(data) => onChanged(data)}
            disabled={disabled}
          />
        );

      default:
        return <></>;
    }
  }, [data.integration, data]);

  return (
    <Stack spacing={2}>
      <Box>
        <TextFieldTitle title="Category" />
        <FormControl
          variant="filled"
          fullWidth
          size="small"
          disabled={disabled}
        >
          <InputLabel shrink={false} disabled disableAnimation>
            {data.sourceOrigin ? '' : 'Select...'}
          </InputLabel>
          <Select
            disableUnderline
            value={data.sourceOrigin || ''}
            onChange={(v) =>
              onChanged({
                ...data,
                sourceOrigin: v.target.value as SourceOriginType,
                integration: undefined,
              })
            }
          >
            {TYPE_OPTIONS.map((option) => (
              <MenuItem
                key={option.type}
                value={option.type}
                disabled={option.disabled}
              >
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  <Box sx={SELECT_ICON_WRAPPER}>{option.icon}</Box>
                  <span>{option.title}</span>
                </Stack>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {integrations ? (
        <Box>
          <TextFieldTitle title="Integration" />
          <Stack direction={'row'} gap={1} flexWrap={'wrap'}>
            {integrations.map((i) => (
              <Box key={i.type + '-' + i.title}>
                <IntegrationOptionChip
                  icon={i.icon}
                  title={i.title}
                  selected={i.type === data.integration}
                  onClick={() => {
                    if (i.link) {
                      window.open(i.link, '_blank');
                      return;
                    }
                    onChanged({ ...data, integration: i.type as any });
                  }}
                  disabled={i.disabled || disabled}
                  comingSoon={i.comingSoon}
                />
              </Box>
            ))}
          </Stack>
        </Box>
      ) : null}
      {innerFields}
    </Stack>
  );
}
