import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  SxProps,
} from '@mui/material';
import { ReactNode, useContext, useMemo } from 'react';
import awsIcon from '../../../assets/images/awsIcon.png';
import azureIcon from '../../../assets/images/azureIcon.png';
import gcpIcon from '../../../assets/images/gcpIcon.png';
import kongIcon from '../../../assets/images/kongIcon.png';
import { AuthContext } from '../../../contexts/Auth';
import TextFieldTitle from '../../Common/TextFieldTitle';
import { SourceDataBase } from './EditSourceForm';
import EditSourceFormAPIGatewayAws from './EditSourceFormAPIGatewayAws';
import EditSourceFormAPIGatewayAzure from './EditSourceFormAPIGatewayAzure';
import EditSourceFormAPIGatewayKong from './EditSourceFormAPIGatewayKong';

const SELECT_ICON_WRAPPER: SxProps = {
  bgcolor: '#EFF5FF',
  width: 24,
  height: 24,
  borderRadius: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

type APIGatewayVendor = 'aws' | 'azure' | 'gcp' | 'kong';

interface APIGatewayOption {
  type: APIGatewayVendor;
  title: string;
  icon: ReactNode;
  disabled?: boolean;
}

export interface APIGatewaySourceDataBase extends SourceDataBase {
  integration?: 'apiGateway';
  vendor?: APIGatewayVendor;
}

export interface AwsAPIGatewaySourceData extends APIGatewaySourceDataBase {
  vendor?: 'aws';
  region?: string;
  access_key_id?: string;
  secret_access_key_id?: string;
  gw_id?: string;
  stage?: string;
}

export interface AzureAPIGatewaySourceData extends APIGatewaySourceDataBase {
  vendor?: 'azure';
  tenant_id?: string;
  client_id?: string;
  client_secret?: string;
  subscription_id?: string;
  resource_group_name?: string;
  service_name?: string;
}

export interface KongAPIGatewaySourceData extends APIGatewaySourceDataBase {
  vendor?: 'kong';
  admin_url?: string;
  service_name?: string;
  auth_type?: null | 'basic' | 'api_key';
  auth_token?: string;
}

export interface GCPAPIGatewaySourceData extends APIGatewaySourceDataBase {
  vendor?: 'gcp';
}

export type APIGatewaySourceData =
  | AwsAPIGatewaySourceData
  | AzureAPIGatewaySourceData
  | GCPAPIGatewaySourceData
  | KongAPIGatewaySourceData;

interface Props {
  data?: APIGatewaySourceData;
  onChanged: (data: APIGatewaySourceData) => void;
  disabled?: boolean;
}

export default function EditSourceFormAPIGateway({
  data,
  onChanged,
  disabled,
}: Props) {
  const { isProd } = useContext(AuthContext);

  const apiGatewayOptions: APIGatewayOption[] = useMemo(
    () => [
      {
        type: 'aws',
        title: 'AWS',
        icon: <img src={awsIcon} height={20} width={20} />,
      },
      {
        type: 'azure',
        title: 'Azure',
        icon: <img src={azureIcon} height={20} width={20} />,
      },
      {
        type: 'kong',
        title: 'Kong',
        icon: <img src={kongIcon} height={20} width={20} />,
      },
      {
        type: 'gcp',
        title: 'Google Cloud',
        icon: <img src={gcpIcon} height={20} width={20} />,
        disabled: true,
      },
    ],
    [isProd],
  );

  return (
    <Stack spacing={2}>
      <Box>
        <TextFieldTitle title="Gateway Vendor" />
        <FormControl
          variant="filled"
          fullWidth
          size="small"
          disabled={disabled}
        >
          <Select
            disableUnderline
            value={data?.['vendor']}
            onChange={(v) =>
              onChanged({ ...data, vendor: v.target.value as APIGatewayVendor })
            }
          >
            {apiGatewayOptions.map((option) => (
              <MenuItem
                key={option.type}
                value={option.type}
                disabled={option.disabled}
              >
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  <Box sx={SELECT_ICON_WRAPPER}>{option.icon}</Box>
                  <span>{option.title}</span>
                </Stack>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {data?.['vendor'] === 'aws' ? (
        <EditSourceFormAPIGatewayAws
          data={data}
          onChanged={onChanged}
          disabled={disabled}
        />
      ) : (
        <></>
      )}
      {data?.['vendor'] === 'azure' ? (
        <EditSourceFormAPIGatewayAzure
          data={data}
          onChanged={onChanged}
          disabled={disabled}
        />
      ) : (
        <></>
      )}
      {data?.['vendor'] === 'kong' ? (
        <EditSourceFormAPIGatewayKong
          data={data}
          onChanged={onChanged}
          disabled={disabled}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
}
