import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useContext, useMemo, useState } from 'react';
import { InsertDriveFileIcon } from '../../assets/svgs/icons/InsertDriveFileIcon';
import { LinkIcon } from '../../assets/svgs/icons/LinkIcon';
import { SnackbarContext } from '../../contexts/Snackbar';
import { uploadFile } from '../../services/FilesService';
import { runSwaggerScanOnCloud } from '../../services/ScansService';
import { track } from '../../utils/analytics';
import { isValidUrl } from '../../utils/validator';
import TextFieldTitle from '../Common/TextFieldTitle';
import { CloudSwaggerRunScanData, RunScanData } from './RunScanDrawer';

const SELECT_ICON_WRAPPER: SxProps = {
  bgcolor: '#EFF5FF',
  width: 24,
  height: 24,
  borderRadius: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

interface Props {
  data: CloudSwaggerRunScanData;
  setData: (updateCb: (current: RunScanData) => RunScanData) => void;
  onDone?: () => void;
  setDisabled?: (disabled: boolean) => void;
}

const SwaggerUploadScanWizard = ({ data, onDone, setData }: Props) => {
  const { show } = useContext(SnackbarContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleNextClick = useCallback(async () => {
    if (!data.appId) {
      show('App ID is required', 'error');
      return;
    }

    try {
      setIsLoading(true);

      let swaggerFileUrl: string | undefined = undefined;
      const swaggerSourceType = data.swaggerSourceType ?? 'file';
      if (swaggerSourceType === 'file') {
        if (!data.swaggerFile) {
          throw new Error('File is required');
        }
        const { bucketName, path } = await uploadFile(
          data.swaggerFile,
          'api-docs',
          String(new Date().getTime()),
        );

        swaggerFileUrl = `s3://${bucketName}/${path}`;
      } else {
        if (!data.swaggerUrl) {
          throw new Error('Link is required');
        }

        swaggerFileUrl = data.swaggerUrl;
      }

      if (!swaggerFileUrl) {
        throw new Error('Swagger file is required');
      }

      let authFileUrl: string | undefined = undefined;

      if (data.authFile) {
        const { bucketName, path } = await uploadFile(
          data.authFile,
          'api-docs',
          String(new Date().getTime()),
        );

        authFileUrl = `s3://${bucketName}/${path}`;
      }

      await runSwaggerScanOnCloud(
        data.appId || '',
        swaggerFileUrl,
        authFileUrl,
      );

      setIsLoading(false);

      show('Scan started successfully', 'success');

      onDone?.();
    } catch (error: any) {
      console.error(error);
      show(error?.message || 'Failed to run swagger scan', 'error');
    } finally {
      setIsLoading(false);
    }
  }, [data]);

  const canSubmit = useMemo(() => {
    const { swaggerSourceType } = data;
    if (swaggerSourceType === 'link') {
      return isValidUrl(data.swaggerUrl ?? '');
    }

    if (!swaggerSourceType || swaggerSourceType === 'file') {
      return !!data.swaggerFile;
    }

    return false;
  }, [data]);

  return (
    <>
      <Stack spacing={2}>
        <Box>
          <TextFieldTitle title="Swagger File Type" />
          <FormControl variant="filled" fullWidth size="small">
            <Select
              disableUnderline
              value={data.swaggerSourceType ?? 'file'}
              onChange={(v) =>
                setData((d) => ({
                  ...d,
                  swaggerSourceType: v.target.value as any,
                }))
              }
            >
              <MenuItem value={'link'}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  <Box sx={SELECT_ICON_WRAPPER}>
                    {<LinkIcon size={20} color="#BFCEE5" />}
                  </Box>
                  <span>Link</span>
                </Stack>
              </MenuItem>
              <MenuItem value={'file'}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  <Box sx={SELECT_ICON_WRAPPER}>
                    {<InsertDriveFileIcon size={20} color="#BFCEE5" />}
                  </Box>
                  <span>File</span>
                </Stack>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        {data.swaggerSourceType === 'link' ? (
          <Box>
            <TextFieldTitle title="Insert Swagger Public Link" />
            <TextField
              placeholder="Link..."
              fullWidth
              variant="filled"
              size="small"
              value={data.swaggerUrl || ''}
              onChange={(v) =>
                setData(() => ({ ...data, swaggerUrl: v.target.value?.trim() }))
              }
              error={data.swaggerUrl ? !isValidUrl(data.swaggerUrl) : false}
              helperText={
                data.swaggerUrl
                  ? isValidUrl(data.swaggerUrl)
                    ? undefined
                    : 'Invalid link'
                  : undefined
              }
            />
          </Box>
        ) : (
          <></>
        )}
        {!data.swaggerSourceType || data.swaggerSourceType === 'file' ? (
          <Box>
            <TextFieldTitle title="Upload File" />
            <Stack direction={'row'} gap={2} alignItems={'center'}>
              <Button
                variant="contained"
                component="label"
                sx={{ flexShrink: 0 }}
              >
                Upload Swagger File
                <input
                  type="file"
                  hidden
                  onChange={(event) => {
                    const selectedFile = event.target.files?.[0] || null;
                    setData((d) => ({
                      ...d,
                      swaggerFile: selectedFile || undefined,
                    }));
                  }}
                />
              </Button>
              {data.swaggerFile ? (
                <Typography>
                  {typeof data.swaggerFile === 'object'
                    ? data.swaggerFile.name
                    : data.swaggerFile}
                </Typography>
              ) : (
                <></>
              )}
            </Stack>
          </Box>
        ) : (
          <></>
        )}
        <Box>
          <TextFieldTitle title="Upload Auth File (Optional)" />
          <Stack direction={'row'} gap={2} alignItems={'center'}>
            <Button
              variant="contained"
              component="label"
              sx={{ flexShrink: 0 }}
            >
              Upload Auth File
              <input
                type="file"
                hidden
                onChange={(event) => {
                  const selectedFile = event.target.files?.[0] || null;
                  setData((d) => ({
                    ...d,
                    authFile: selectedFile || undefined,
                  }));
                }}
              />
            </Button>
            {data.authFile ? (
              <Typography>
                {typeof data.authFile === 'object'
                  ? data.authFile.name
                  : data.authFile}
              </Typography>
            ) : (
              <></>
            )}
          </Stack>
        </Box>
      </Stack>
      <Stack direction={'row'} justifyContent={'end'}>
        <Box flex={1} />
        <LoadingButton
          variant="contained"
          size="large"
          loading={isLoading}
          disabled={!canSubmit}
          onClick={() => {
            track(
              'web_app_run_scan_drawer_swagger_submit_swagger_file_button_click',
            );
            handleNextClick();
          }}
        >
          Next
        </LoadingButton>
      </Stack>
    </>
  );
};

export default SwaggerUploadScanWizard;
