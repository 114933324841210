import {
  GridColDef,
  GridFilterModel,
  GridSortItem,
} from '@mui/x-data-grid-pro';

export const reformatFilterModel = (
  filterModel: GridFilterModel,
  columns: (GridColDef & { filterField?: string })[],
) => {
  if (!filterModel || !filterModel.items || filterModel.items.length === 0) {
    return {}; // If no filtering specified, return an empty object
  }

  const columnsMap = Object.fromEntries(
    columns.map((column) => [column.field, column]),
  );

  const pyntFilterModel: any = {};
  // const logicOperator = filterModel.logicOperator || 'or'; // Default logic operator is 'or'

  // Loop through each filter item
  filterModel.items.forEach((item) => {
    const { field, operator, value } = item;
    let mongoOperator;

    const column = columnsMap[field] || {};

    // Map GridFilterOperator to MongoDB query operator
    switch (operator) {
      case '=':
        mongoOperator = 'eq';
        break;
      case 'equals':
        mongoOperator = 'eq';
        break;
      case '!=':
        mongoOperator = 'ne';
        break;
      case '>':
        mongoOperator = 'gt';
        break;
      case '>=':
        mongoOperator = 'gte';
        break;
      case '<':
        mongoOperator = 'lt';
        break;
      case '<=':
        mongoOperator = 'lte';
        break;
      case 'contains':
        // For text search, you may use regex for case-insensitive matching
        pyntFilterModel[field] = { regex: `.*${value}.*`, options: 'i' };
        return; // Skip further processing for 'contains' operator
      case 'is':
        mongoOperator = 'eq';
        break;
      case 'not':
        mongoOperator = 'ne';
        break;
      case 'isAnyOf':
        mongoOperator = 'in';
        break;
      case 'startsWith':
        pyntFilterModel[field] = { regex: `^${value}`, options: 'i' };
        return;
      case 'endsWith':
        pyntFilterModel[field] = { regex: `${value}$`, options: 'i' };
        return;
      case 'exists':
        pyntFilterModel[field] = { exists: true };
        return;
      case 'notExists':
        pyntFilterModel[field] = { exists: false };
        return;
      default:
        // Handle unsupported operators or custom operators as needed
        console.warn(`Unsupported operator: ${operator}`);
        return; // Skip further processing
    }

    // Construct MongoDB filter
    const filter: any = {};
    if (mongoOperator === 'eq') {
      pyntFilterModel[field] = convertValue(column, value);
    } else {
      if (mongoOperator === 'in') {
        const parsedValues = convertValue(column, value);
        filter[mongoOperator] = parsedValues; // Assign parsed values directly without parsing to integers
      } else {
        filter[mongoOperator] = convertValue(column, value);
      }

      pyntFilterModel[field] = { ...pyntFilterModel[field], ...filter };
    }

    // Combine filters based on logic operator
    // if (logicOperator === 'and') {
    //   mongoFilters[field] = { ...mongoFilters[field], ...filter };
    // } else {
    //   // Default to 'or' logic operator
    //   mongoFilters[field] = {
    //     ...mongoFilters[field],
    //     or: [mongoFilters[field], filter],
    //   };
    // }
  });

  const modifiedFilterModel: any = {};
  Object.keys(pyntFilterModel).forEach((field) => {
    const column = columnsMap[field] || {};
    const filterField = column.filterField || field;
    modifiedFilterModel[filterField] = pyntFilterModel[field];
  });
  return modifiedFilterModel;
};

const convertValue = (column: GridColDef, value: any) => {
  if (column.type === 'number') {
    return parseFloat(value);
  } else {
    return value;
  }
};

export const reformatSortModel = (
  sortModel: GridSortItem[],
  columns: (GridColDef & { filterField?: string })[],
) => {
  if (!sortModel || sortModel.length === 0) {
    return {}; // If no sorting specified, return an empty object
  }
  const pyntSortModel: any = {};
  sortModel.forEach((item: GridSortItem) => {
    const { field, sort } = item;
    if (sort === 'asc') {
      pyntSortModel[field] = 1; // 1 for ascending order
    } else if (sort === 'desc') {
      pyntSortModel[field] = -1; // -1 for descending order
    }
  });

  const columnsMap = Object.fromEntries(
    columns.map((column) => [column.field, column]),
  );

  const modifiedSortModel: any = {};
  Object.keys(pyntSortModel).forEach((field) => {
    const column = columnsMap[field] || {};
    const filterField = column.filterField || field;
    modifiedSortModel[filterField] = pyntSortModel[field];
  });
  return modifiedSortModel;
};
