
import { get, PyntFilter } from './BaseService';

export async function getEndpoints(filter: PyntFilter = {}) {
  const res = await get('/endpoint/metadata', {
    params: { filter: JSON.stringify(filter) },
  });

  return res.data;
}

export async function getEndpointsCount(where: PyntFilter['where'] = {}) {
  const res = await get('/endpoint/count', {
    params: { where: JSON.stringify(where) },
  });

  return res.data;
}
