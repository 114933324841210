import { SvgIconProps } from './SvgIconProps';

export const QuesionMarkCircleIcon = ({
  color = '#ACACBB',
  size = 22,
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1311_469)">
        <path
          d="M17.0711 3.42891C15.1823 1.5402 12.6711 0.5 10 0.5C7.32891 0.5 4.8177 1.5402 2.92891 3.42891C1.0402 5.3177 0 7.82891 0 10.5C0 13.1711 1.0402 15.6823 2.92891 17.5711C4.8177 19.4598 7.32891 20.5 10 20.5C12.6711 20.5 15.1823 19.4598 17.0711 17.5711C18.9598 15.6823 20 13.1711 20 10.5C20 7.82891 18.9598 5.3177 17.0711 3.42891ZM10 1.67188C12.2235 1.67188 14.2573 2.49848 15.8112 3.86012L13.0841 6.58719C12.2351 5.91652 11.1635 5.5157 10 5.5157C8.83652 5.5157 7.76492 5.91652 6.9159 6.58719L4.18883 3.86012C5.74266 2.49848 7.77652 1.67188 10 1.67188ZM13.8124 10.5C13.8124 12.6022 12.1022 14.3124 10 14.3124C7.89781 14.3124 6.18758 12.6022 6.18758 10.5C6.18758 8.39781 7.89781 6.68758 10 6.68758C12.1022 6.68758 13.8124 8.39781 13.8124 10.5ZM1.17188 10.5C1.17188 8.27648 1.99852 6.24262 3.3602 4.68875L6.08727 7.41582C5.41656 8.26488 5.0157 9.33648 5.0157 10.5C5.0157 11.6635 5.41652 12.7351 6.08719 13.5842L3.36012 16.3113C1.99848 14.7573 1.17188 12.7235 1.17188 10.5ZM10 19.3281C7.77648 19.3281 5.74262 18.5015 4.18871 17.1398L6.91578 14.4127C7.76484 15.0834 8.83644 15.4843 9.99996 15.4843C11.1635 15.4843 12.2351 15.0834 13.0842 14.4127L15.8113 17.1398C14.2574 18.5015 12.2235 19.3281 10 19.3281ZM16.6399 16.3112L13.9128 13.5841C14.5835 12.735 14.9843 11.6635 14.9843 10.5C14.9843 9.33648 14.5835 8.26488 13.9128 7.41582L16.6398 4.68875C18.0015 6.24262 18.8281 8.27648 18.8281 10.5C18.8281 12.7235 18.0015 14.7573 16.6399 16.3112Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1311_469">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
