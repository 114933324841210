import dayjs from 'dayjs';
import mixpanel from 'mixpanel-browser';
import { createContext, useCallback, useEffect, useMemo } from 'react';
// import { useIntercom } from 'react-use-intercom';
import * as Sentry from '@sentry/react';
import { usePersistedJsonState } from '../hooks/usePersistedState';
import { License } from '../models/license';
import { Organization } from '../models/organization';
import { User } from '../models/user';
import {
  exchangeOAuthToken,
  getUser,
  createUser,
  discoverSSO,
  storeDeviceCode,
} from '../services/AuthService';
import {
  getStoredHeaders,
  onRefreshError,
  storeAccessToken,
  storeHeaders,
  storeRefreshToken,
} from '../services/BaseService';
import { getOrganizationById } from '../services/OrgService';
import {
  acceptAWSMarketplaceInvite,
  acceptInvite,
} from '../services/OrgUsersService';
import { analyticsIdentify, track, trackOptions } from '../utils/analytics';
import { updateHotjar } from '../utils/hotjar';

type ConfirmOAuthCodeResponse = {
  newOrgCreated: boolean;
  goto?: string;
  deviceCode?: string;
};

interface AuthContextProps {
  user?: User;
  organization?: Organization;
  isAuthenticated: boolean;
  isProd: boolean;
  license?: License['license_type'];
  planExpirationInDays: number;
  refreshUser: () => void;
  setIsProd: (_: boolean) => void;
  logout: () => void;
  loginWithProvider: (provider: string, deviceCode?: string) => void;
  discoverSSO: (email: string) => Promise<string>;
  confirmOAuthCode: (
    code: string,
    state?: string,
  ) => Promise<ConfirmOAuthCodeResponse>;
}

type AuthState = {
  provider: string;
  inviteId?: string;
  awsMarketplaceId?: string;
  goto?: string;
  deviceCode?: string;
};

export const AuthContext = createContext<AuthContextProps>({
  isProd: true,
  planExpirationInDays: 21,
  refreshUser: () => {},
  setIsProd: (_) => {},
  logout: () => {},
  loginWithProvider: (_provider, _deviceCode) => {},
  discoverSSO: (_) => Promise.reject(new Error('not found')),
  confirmOAuthCode: async () => ({ newOrgCreated: false }),
  isAuthenticated: false,
});

function AuthContextProvider(props: any) {
  // const { trackEvent, boot: bootIntercom } = useIntercom();
  const [user, setUser] = usePersistedJsonState<User | undefined>('user');
  const [organization, setOrganization] = usePersistedJsonState<
    Organization | undefined
  >('organization');
  const [isAuthenticated, setIsAuthenticated] =
    usePersistedJsonState<boolean>('isAuthenticated');
  const [isProd, setIsProd] = usePersistedJsonState(
    '#$%#$#$',
    process.env.REACT_APP_NODE_ENV !== 'development',
    sessionStorage,
  );

  const activeLicense: License | undefined | null = useMemo(() => {
    if (!organization) return undefined;
    if (!organization.licenses) return null;

    const licenses = organization.licenses.filter((l) => l.status === 'active');

    const activeLicense = licenses.reduce((acc, l) => {
      if (!acc) return l;
      return acc.expiration_date > l.expiration_date ? acc : l;
    }, licenses[0]);

    return activeLicense || null;
  }, [organization]);

  const license = useMemo(() => {
    return activeLicense?.license_type;
  }, [activeLicense]);

  const planExpirationInDays = useMemo(
    () =>
      activeLicense?.start_date && activeLicense?.expiration_date
        ? dayjs(activeLicense?.expiration_date).diff(new Date(), 'day')
        : 14,
    [activeLicense],
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const xOrg = searchParams.get('x-org');
    if (xOrg) {
      storeHeaders({ ...getStoredHeaders(), 'X-Org': xOrg });
    }
  }, []);

  useEffect(() => {
    if (!isAuthenticated) return;
    refreshUser();
  }, [isAuthenticated]);

  useEffect(() => {
    onRefreshError.cb = () => {
      logout();
    };
    return () => {
      onRefreshError.cb = undefined;
    };
  }, [isAuthenticated]);

  // useEffect(() => {
  //   trackOptions.intercomTrack = trackEvent;
  // }, [trackEvent]);

  useEffect(() => {
    trackOptions.globalParamters.organizationId = user?.organization_id;
    if (!isAuthenticated || !user) {
      // bootIntercom();
      return;
    }
    analyticsIdentify(user.uid, user.email, user, organization);
    updateHotjar(user, organization);
    // bootIntercom({
    //   email: user.email,
    //   name: user.name,
    //   userId: user.user_id,
    //   company: user.organization
    //     ? {
    //         companyId: user.organization,
    //         name: user.organization_name,
    //       }
    //     : undefined,
    // });
  }, [
    isAuthenticated,
    user,
    organization,
    // bootIntercom,
  ]);

  const getOAuthRedirectUri = () => {
    const redirectUri = window.location.origin + '/auth';
    return redirectUri;
  };

  const buildOAuthUrl = (provider: string, deviceCode?: string) => {
    const redirectUri = encodeURIComponent(getOAuthRedirectUri());
    const params = new URLSearchParams(window.location.search);
    const state: AuthState = {
      provider,
      deviceCode,
    };
    if (params.has('invite')) {
      state.inviteId = params.get('invite')!;
    }
    if (params.has('awsMarketplaceId')) {
      state.awsMarketplaceId = params.get('awsMarketplaceId')!;
    }
    if (params.has('goto')) {
      state.goto = params.get('goto')!;
    }
    return `${
      process.env.REACT_APP_OAUTH_BASE_URL
    }/oauth2/authorize?identity_provider=${provider}&redirect_uri=${redirectUri}&response_type=CODE&client_id=${
      process.env.REACT_APP_OAUTH_CLIENT_ID
    }&scope=pynt/default&state=${encodeURIComponent(JSON.stringify(state))}`;
  };

  const confirmOAuthCode = useMemo(
    () =>
      async (
        code: string,
        state?: string,
      ): Promise<ConfirmOAuthCodeResponse> => {
        const redirectUri = getOAuthRedirectUri();
        let inviteId: string | undefined;
        let awsMarketplaceId: string | undefined;
        let goto: string | undefined;
        let deviceCode: string | undefined;
        try {
          const jState: AuthState = state ? JSON.parse(state) : {};
          inviteId = jState.inviteId;
          awsMarketplaceId = jState.awsMarketplaceId;
          goto = jState.goto;
          deviceCode = jState.deviceCode;
        } catch (e) {
          console.error(e);
        }
        if (deviceCode) {
          // CLI Login
          await storeDeviceCode(deviceCode, code, redirectUri);
          return { newOrgCreated: false, deviceCode };
        }
        const json = await exchangeOAuthToken(code, redirectUri);
        storeAccessToken(json.access_token);
        storeRefreshToken(json.refresh_token);

        let newOrgCreated = false;
        const [newUser, org] = await (
          awsMarketplaceId
            ? acceptAWSMarketplaceInvite(awsMarketplaceId).then(() => {
                return getUser();
              })
            : inviteId
              ? acceptInvite(inviteId).then(() => {
                  return getUser();
                })
              : getUser().catch(async (e) => {
                  if (e?.response?.status === 404) {
                    const createUserResponse = await createUser();
                    newOrgCreated = createUserResponse.new_organization_created;
                    const newUser = await getUser();
                    if (newUser) return newUser;
                  }
                  throw e;
                })
        )
          .then(async (user) => {
            const org = await getOrganizationById(user.organization_id);
            return [user, org];
          })
          .catch((e) => {
            storeAccessToken(undefined);
            storeRefreshToken(undefined);

            Sentry.captureException(e);

            throw e;
          });

        setUser(newUser);
        setOrganization(org);
        setIsAuthenticated(true);
        track('web_app_login_successfully');
        return { newOrgCreated, goto };
      },
    [],
  );

  const refreshUser = useCallback(async () => {
    await Promise.all([
      getUser().then((updatedUser) => {
        Sentry.setUser({
          id: updatedUser.uid,
          email: updatedUser.email,
        });
        return setUser(updatedUser);
      }),
      user && user.organization_id
        ? getOrganizationById(user.organization_id).then((org) =>
            setOrganization(org),
          )
        : undefined,
    ]);
  }, [user]);

  const logout = () => {
    storeAccessToken(undefined);
    storeRefreshToken(undefined);
    setUser(undefined);
    setIsAuthenticated(false);
    Sentry.setUser(null);
    if (process.env.REACT_APP_NODE_ENV !== 'development') {
      mixpanel.reset();
    }
  };

  const loginWithProvider = useCallback(
    (provider: string, deviceCode?: string) => {
      window.location.replace(buildOAuthUrl(provider, deviceCode));
    },
    [buildOAuthUrl],
  );

  return (
    <AuthContext.Provider
      value={{
        user,
        organization,
        isAuthenticated,
        isProd,
        license,
        planExpirationInDays,
        refreshUser,
        setIsProd,
        loginWithProvider,
        logout,
        discoverSSO,
        confirmOAuthCode,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
