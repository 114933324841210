import { get, post, put, PyntFilter } from './BaseService';
import {
  DisplayedVulnerability,
  EndpointAttackInfo,
  VulnerabilityInstance,
  VulnerabilityInstanceStatus,
} from '../models/vulnerability';
import vulnerabilities from '../assets/data/vulnerabilities.json';

type VulnerabilityInstancesResponse = {
  instances: VulnerabilityInstance[];
  total: number;
};

const newTemplateResolver = (attackInfo: EndpointAttackInfo) => {
  const params = {
    FindingParameter: attackInfo.finding.parameter_name,
    FindingEndpoint: attackInfo.endpoint,
    FindingReplacedValue: attackInfo.finding.replaced_value,
    FindingOriginalValue: attackInfo.finding.original_value,
    FindingReplacedValueUid: attackInfo.finding.finding_replaced_value_uid,
    FindingReplacedValueRid: attackInfo.finding.finding_replaced_value_rid,
    FindingParameterUid: attackInfo.finding.finding_parameter_uid,
    FindingParameterRid: attackInfo.finding.finding_parameter_rid,
    FindingPayload: attackInfo.finding.payload,
    VerifiedBy: attackInfo.finding.verified_by,
    WarningModeTitle: attackInfo.finding.mode,
    WarningMode: attackInfo.finding.mode,
    WarningParameter: attackInfo.finding.header,
    HeaderKey: attackInfo.finding.header_key,
  };

  return (template: string) => {
    let newLine = template.replace(/\{\{\./g, '{{'); // maybe God knows why, !@#!@
    Object.entries(params).forEach(([key, value]) => {
      if (value === null) {
        return;
      }

      newLine = newLine.replace(new RegExp(`\{\{${key}\}\}`, 'g'), value);
    });
    return newLine;
  };
};

export const fetchVulnerabilities = async (filter: PyntFilter) => {
  const res = await get<VulnerabilityInstancesResponse>(
    '/vulnerability-instance',
    {
      params: {
        filter: JSON.stringify(filter),
      },
    },
  );

  return {
    vulnerabilities: res.data.instances.map(convert),
    total: res.data.total,
  };
};

const convert = (vi: VulnerabilityInstance): DisplayedVulnerability => {
  const key = `${vi.vulnerability_definition.category}::${vi.vulnerability_definition.sub_category}`;
  if (!(key in vulnerabilities)) {
    throw new Error(`key ${key} of category and subcategory wasn't found`);
  }
  const vulnerabilityData =
    vulnerabilities[key as keyof typeof vulnerabilities];

  const attackInfo = vi.scope_info.endpoint_attacks_info[0];
  const resolveTemplate = newTemplateResolver(attackInfo);
  const isLLM = vi.vulnerability_definition.category === 'LLM';
  return {
    id: vi.id,
    severity: vi.severity,
    status: vi.status,
    name: resolveTemplate(vulnerabilityData.nameTemplate),
    applicationId: vi.application_id,
    host: attackInfo.host,
    vulnerable_endpoints: vi.scope_info.endpoint_attacks_info.map(
      (endpointAttackInfo) => ({
        scanId: endpointAttackInfo.scan_id,
        endpoint: endpointAttackInfo.endpoint,
        evidencePaths: endpointAttackInfo.evidence_paths,
        curlCmd: endpointAttackInfo.curl_cmd,
      }),
    ),
    ticket: vi.tickets.length > 0 ? vi.tickets[0] : null,
    owasp_category: resolveTemplate(vulnerabilityData.categoryTemplate),
    owasp_link: vulnerabilityData.owaspLink,
    tags: vi.tags,
    description: resolveTemplate(vulnerabilityData.description),
    remediation: resolveTemplate(vulnerabilityData.remediation),
    isLLM,
  };
};

export const createTicket = async (
  vulnerabilityInstanceId: string,
  integrationId: string,
  projectId: string,
  issueType: string,
  summary: string,
  description: string,
) => {
  const res = await post('/ticket', {
    ticket_type: 'jira',
    vulnerability_instance_id: vulnerabilityInstanceId,
    integration_id: integrationId,
    project_id: projectId,
    issue_type_id: issueType,
    summary,
    description,
  });

  return res.data;
};

export const updateVulnerabilityStatus = async (
  vulnerabilityInstanceId: string,
  status: VulnerabilityInstanceStatus,
) => {
  await put(`/vulnerability-instance/${vulnerabilityInstanceId}/status`, {
    status,
  });
};

export const setVulnerabilityTags = async (
  vulnerabilityInstanceId: string,
  tags: string[],
) => {
  await put(`/vulnerability-instance/${vulnerabilityInstanceId}/tags`, {
    tags,
  });
};
