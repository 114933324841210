import { Box, Stack, SxProps } from '@mui/material';
import { LockIcon } from '../../../assets/svgs/icons/LockIcon';
import IconTextField from '../../Common/IconTextField';
import TextFieldTitle from '../../Common/TextFieldTitle';
import { AzureAPIGatewaySourceData } from './EditSourceFormAPIGateway';

const SELECT_ICON_WRAPPER: SxProps = {
  bgcolor: '#EFF5FF',
  width: 24,
  height: 24,
  borderRadius: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

interface Props {
  data?: AzureAPIGatewaySourceData;
  onChanged: (data: AzureAPIGatewaySourceData) => void;
  disabled?: boolean;
}

export default function EditSourceFormAPIGatewayAzure({
  data,
  onChanged,
  disabled,
}: Props) {
  return (
    <Stack spacing={2}>
      <Box>
        <TextFieldTitle title="Tenant ID" />
        <IconTextField
          fullWidth
          variant="filled"
          size="small"
          placeholder="Azure tenant id"
          iconStart={
            <Box sx={SELECT_ICON_WRAPPER}>
              <LockIcon size={20} color="#BFCEE5" />
            </Box>
          }
          value={data?.['tenant_id']}
          onChange={(v) =>
            onChanged({ ...data, tenant_id: v.target.value?.trim() })
          }
          required
          disabled={disabled}
        />
      </Box>
      <Box>
        <TextFieldTitle title="Client ID" />
        <IconTextField
          fullWidth
          variant="filled"
          size="small"
          placeholder="Azure client id"
          iconStart={
            <Box sx={SELECT_ICON_WRAPPER}>
              <LockIcon size={20} color="#BFCEE5" />
            </Box>
          }
          value={data?.['client_id']}
          onChange={(v) =>
            onChanged({ ...data, client_id: v.target.value?.trim() })
          }
          required
          disabled={disabled}
        />
      </Box>
      <Box>
        <TextFieldTitle title="Client Secret" />
        <IconTextField
          fullWidth
          variant="filled"
          size="small"
          placeholder="Azure secret value"
          iconStart={
            <Box sx={SELECT_ICON_WRAPPER}>
              <LockIcon size={20} color="#BFCEE5" />
            </Box>
          }
          value={data?.['client_secret']}
          onChange={(v) =>
            onChanged({ ...data, client_secret: v.target.value?.trim() })
          }
          required
          disabled={disabled}
        />
      </Box>
      <Box>
        <TextFieldTitle title="Subscription ID" />
        <IconTextField
          fullWidth
          variant="filled"
          size="small"
          placeholder="Azure subscription id"
          iconStart={
            <Box sx={SELECT_ICON_WRAPPER}>
              <LockIcon size={20} color="#BFCEE5" />
            </Box>
          }
          value={data?.['subscription_id']}
          onChange={(v) =>
            onChanged({ ...data, subscription_id: v.target.value?.trim() })
          }
          required
          disabled={disabled}
        />
      </Box>
      <Box>
        <TextFieldTitle title="Resource Group Name" />
        <IconTextField
          fullWidth
          variant="filled"
          size="small"
          placeholder="Azure resource group name"
          iconStart={
            <Box sx={SELECT_ICON_WRAPPER}>
              <LockIcon size={20} color="#BFCEE5" />
            </Box>
          }
          value={data?.['resource_group_name']}
          onChange={(v) =>
            onChanged({ ...data, resource_group_name: v.target.value?.trim() })
          }
          required
          disabled={disabled}
        />
      </Box>
      <Box>
        <TextFieldTitle title="Service Name" />
        <IconTextField
          fullWidth
          variant="filled"
          size="small"
          placeholder="Azure service name"
          iconStart={
            <Box sx={SELECT_ICON_WRAPPER}>
              <LockIcon size={20} color="#BFCEE5" />
            </Box>
          }
          value={data?.['service_name']}
          onChange={(v) =>
            onChanged({ ...data, service_name: v.target.value?.trim() })
          }
          required
          disabled={disabled}
        />
      </Box>
    </Stack>
  );
}
