import * as Sentry from '@sentry/react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { RunningScanHistoryItem, ScanHistoryItem } from '../models/scanHistory';
import { getApplicationById } from '../services/ApplicationsService';
import { getAppScans, getRunningScans } from '../services/ScansService';
import { AuthContext } from './Auth';

import { Application } from '../models/application';

export interface ApplicationContextProps {
  application?: Application;
  isLoadingApplication: boolean;
  error?: string;
  refetchApplication: () => Promise<void>;

  latestScans?: ScanHistoryItem[];
  isLoadingLatestScans: boolean;

  runningScans?: RunningScanHistoryItem[];
  isLoadingRunningScans: boolean;

  endpointsRisks: Record<string, number>;
  setApplicationId: (id: string | undefined) => any;
}

export const ApplicationContext = createContext<ApplicationContextProps | null>(
  null,
);

function ApplicationContextProvider(props: any) {
  const { isAuthenticated } = useContext(AuthContext);
  const [application, setApplication] = useState<Application>();
  const [error, setError] = useState<string>();
  const [applicationId, setApplicationId] = useState<string>();
  const [isLoadingApplication, setIsLoadingApplication] = useState(true);
  const [latestScans, setLatestScans] = useState<ScanHistoryItem[]>();
  const [isLoadingLatestScans, setIsLoadingLatestScans] = useState(false);
  const [runningScans, setRunningScans] = useState<RunningScanHistoryItem[]>();
  const [isLoadingRunningScans, setIsLoadingRunningScans] = useState(false);

  const endpointsRisks = useMemo(() => {
    const risks = (application as any)?.endpoints_risks;
    if (!risks) return {};

    return Object.fromEntries(
      Object.entries(risks).map(([k, v]: any) => [k?.toUpperCase(), v]),
    ) as any;
  }, [application]);

  useEffect(() => {
    if (!applicationId || !isAuthenticated) {
      setApplication(undefined);
      return;
    }

    setIsLoadingApplication(true);
    setApplication(undefined);
    setError(undefined);

    getApplicationById(applicationId)
      .then((app) => {
        setApplication(app);
      })
      .catch((e) => {
        setError(e.message || e.toString());
        Sentry.captureException(e);
      })
      .finally(() => {
        setIsLoadingApplication(false);
      });
  }, [applicationId, isAuthenticated]);

  const fetchLatestScans = useCallback(async (applicationId: string) => {
    const scans = await getAppScans(applicationId);

    return scans.filter((s: any) => s.application === applicationId);
  }, []);

  const refetchApplication = useCallback(async () => {
    if (!applicationId || isLoadingApplication) return;

    return getApplicationById(applicationId).then((app) => {
      setApplication(app);
    });
  }, [applicationId, isLoadingApplication]);

  useEffect(() => {
    if (!applicationId || !isAuthenticated) {
      setIsLoadingLatestScans(false);
      setLatestScans(undefined);
      return;
    }

    setIsLoadingLatestScans(true);
    fetchLatestScans(applicationId)
      .then((scans) => {
        setLatestScans(scans);
      })
      .catch((e) => {
        console.error(e);
        Sentry.captureException(e);
      })
      .finally(() => {
        setIsLoadingLatestScans(false);
      });
  }, [applicationId, fetchLatestScans, isAuthenticated]);

  useEffect(() => {
    if (!application || !isAuthenticated) {
      setIsLoadingRunningScans(false);
      setRunningScans(undefined);
      return;
    }

    setIsLoadingRunningScans(true);
    let relevant = true;

    let timer: ReturnType<typeof setTimeout>;
    const _fetchLoop = () => {
      if (!relevant) return;

      getRunningScans(application.app_id)
        .then((scans) => {
          setRunningScans(scans);
          if (!scans.length) {
            // If there are no running scans, fetch every 10 seconds
            timer = setTimeout(_fetchLoop, 10000);
          } else {
            // If there are running scans, fetch every 3 seconds
            timer = setTimeout(_fetchLoop, 3000);
          }
        })
        .catch((e) => {
          console.error(e);
          Sentry.captureException(e);

          // If there is an error, fetch every 5 seconds
          timer = setTimeout(_fetchLoop, 5000);
        })
        .finally(() => {
          setIsLoadingRunningScans(false);
        });
    };

    _fetchLoop();

    return () => {
      relevant = false;
      if (timer) clearTimeout(timer);
    };
  }, [application, isAuthenticated]);

  return (
    <ApplicationContext.Provider
      value={{
        application,
        isLoadingApplication,
        error,
        refetchApplication,

        latestScans,
        isLoadingLatestScans,

        runningScans,
        isLoadingRunningScans,

        setApplicationId,
        endpointsRisks,
      }}
    >
      {props.children}
    </ApplicationContext.Provider>
  );
}

export default ApplicationContextProvider;
