import { CloudOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Stack,
  SxProps,
  Tooltip,
  Typography,
  capitalize,
} from '@mui/material';
import {
  GridColDef,
  GridColumnsInitialState,
  GridFilterModel,
} from '@mui/x-data-grid';
import { GridColumnVisibilityModel, GridSortModel } from '@mui/x-data-grid-pro';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import * as Sentry from '@sentry/react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DangerIcon } from '../../assets/svgs/icons/DangerIcon';
import { GraphQLIcon } from '../../assets/svgs/icons/GraphQLIcon';
import { LockIcon2 } from '../../assets/svgs/icons/LockIcon2';
import { OpenLockIcon } from '../../assets/svgs/icons/OpenLockIcon';
import { BRAND_ORANGE } from '../../constants/colors';
import { AuthContext } from '../../contexts/Auth';
import { SnackbarContext } from '../../contexts/Snackbar';
import {
  APISource,
  UserContext,
  UserContextProps,
} from '../../contexts/User';
import { usePersistedJsonState } from '../../hooks/usePersistedState';
import { APICatalogItem } from '../../models/apiCatalog';
import { PyntFilter } from '../../services/BaseService';
import {
  getEndpoints,
  getEndpointsCount,
} from '../../services/EndpointsService';
import { distinct } from '../../utils';
import { track } from '../../utils/analytics';
import { reformatFilterModel, reformatSortModel } from '../../utils/datagrid';
import SourcesGridCell from '../Applications/SourcesGridCell';
import {
  APIMethodChip,
  ApplicationGridCell,
  NumberChipIndicator,
  PyntDataGrid,
} from '../Common';
import APIStatusChip from './APIStatusChip';
import RiskScoreChip, { getEnpointRisk } from './RiskScoreChip';

const RISK_SCORE_ORDER = ['HIGH', 'MEDIUM', 'LOW', 'N/A'];

const HOVERABLE_NUMBER: SxProps = {
  'color': '#212121',
  'p': 1,
  'marginInlineStart': -1,
  'minWidth': 0,
  'minHeight': 0,
  '&:hover': {
    bgColor: 'transparent',
    backgroundColor: 'transparent',
    textDecoration: 'underline',
    color: '#FF8C00',
  },
};

export const apiCatalogSourceTitle = (
  source:
    | 'scan'
    | 'swagger'
    | 'swagger_file'
    | 'postman_file'
    | 'aws_api_gw'
    | 'azure_api_gw'
    | 'kong_api_gw'
    | 'har_file',
) => {
  switch (source) {
    case 'aws_api_gw':
    case 'azure_api_gw':
    case 'kong_api_gw':
    case 'har_file':
      return 'Production';
    case 'scan':
      return 'Testing';
    case 'swagger':
    case 'swagger_file':
    case 'postman_file':
      return 'Documentation';
  }
};

interface Props {
  id?: string;
  filter?: GridFilterModel;
  sorting?: GridSortModel;
  hideFooter?: boolean;
  columnVisibilityModel?: GridColumnVisibilityModel;
  autoHeight?: boolean;
  autoPageSize?: boolean;
  pagination?: boolean;
  limit?: number | undefined;
  pyntFilter?: PyntFilter['where'] | any;
}

export default function APICatalogGrid({
  id = 'APICatalogGrid',
  filter,
  sorting = [],
  hideFooter = false,
  columnVisibilityModel,
  autoHeight = true,
  autoPageSize = false,
  pagination = true,
  limit,
  pyntFilter,
}: Props) {
  const navigate = useNavigate();

  const { show } = useContext(SnackbarContext);
  const { isProd } = useContext(AuthContext);
  const { applications, apiSources, setSelectedApplicationId } = useContext(
    UserContext,
  ) as UserContextProps;

  const [apiCatalog, setApiCatalog] = useState<APICatalogItem[]>();
  const [isLoadingAPICatalog, setIsLoadingAPICatalog] = useState(false);
  const [sortModel, setSortModel] = useState<GridSortModel>(sorting);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [count, setCount] = useState<number>();

  useEffect(() => {
    const storedApiCatalog = localStorage.getItem(`api-catalog-${id}`);
    if (storedApiCatalog) {
      try {
        const parsedApiCatalog = JSON.parse(storedApiCatalog);
        if (!parsedApiCatalog.length) return;
        setApiCatalog(parsedApiCatalog);
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  const onSourceViewClick = useCallback(
    (
      endpoint: APICatalogItem,
      partialSource: Pick<APISource, 'type' | 'source_id'>,
    ) => {
      track('web_app_view_api_source_button_click');

      const app = applications?.find((a) => a.app_id === endpoint.application);
      const source = apiSources?.find((a) => a.type === partialSource.type);

      if (!app || !source) {
        return;
      }

    },
    [apiSources, applications],
  );

  const onEndpointScanTimeClick = useCallback(
    (endpoint: APICatalogItem) => {
      track('web_app_view_last_scan_button_click');

      const scanId = endpoint.properties?.last_scan;

      if (!scanId) {
        return;
      }

      setSelectedApplicationId('*');
      navigate({
        pathname: '/dashboard/scans-history',
        search: `?filter=${JSON.stringify({
          items: [{ field: 'scan_id', value: scanId, operator: 'equals' }],
        })}`,
      });
    },
    [apiSources, applications],
  );

  const fetchApiCatalog = useCallback(async () => {
    if (isLoadingAPICatalog) return;

    setIsLoadingAPICatalog(true);

    const filter: PyntFilter = {
      where: {
        ...reformatFilterModel(filterModel, columns),
        ...pyntFilter,
      },
      sort: reformatSortModel(sortModel, columns),
      offset: page * pageSize,
      limit: limit ? limit : pageSize,
    };

    const apiCatalog = await getEndpoints(filter).catch((e) => {
      console.error(e);
      Sentry.captureException(e);
    });

    setApiCatalog(apiCatalog);
    setIsLoadingAPICatalog(false);

    if (page === 0) {
      if (apiCatalog) {
        localStorage.setItem(`api-catalog-${id}`, JSON.stringify(apiCatalog));
      } else {
        localStorage.removeItem(`api-catalog-${id}`);
      }
    }

    const apiCatalogCount = await getEndpointsCount(filter.where).catch((e) => {
      console.error(e);
      Sentry.captureException(e);
    });

    setCount(apiCatalogCount?.count);

    return apiCatalog;
  }, [isLoadingAPICatalog, filterModel, sortModel, page, pageSize, pyntFilter]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchApiCatalog();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [filterModel, sortModel, page, pageSize, pyntFilter]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'properties.scan_risk',
        headerName: 'Risk Score',
        width: 120,
        type: 'singleSelect',
        filterable: false,
        valueGetter: ({ row }) => {
          const risk = getEnpointRisk(row.properties);
          if (
            risk !== 'CRITICAL' &&
            row.properties?.endpoint_status?.trim()?.toUpperCase() === 'SHADOW'
          ) {
            return 'HIGH';
          }
          return risk || 'N/A';
        },
        valueOptions: ['HIGH', 'MEDIUM', 'LOW'],
        sortComparator: (v1: string, v2: string) =>
          RISK_SCORE_ORDER.indexOf(v2) - RISK_SCORE_ORDER.indexOf(v1),
        renderCell: ({ value }) =>
          value && value !== 1 ? (
            <Tooltip
              placement="top"
              arrow
              title={
                <Typography variant="body2">
                  The risk of this API is {capitalize(value.toLowerCase())}. For
                  a full breakdown of the risk calculation, visit:{' '}
                  <a
                    href="https://docs.pynt.io/documentation/api-catalog/navigate-catalog/apis-at-risk"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: BRAND_ORANGE }}
                    onClick={() => {
                      track('web_app_endpoint_risk_calculation_button_click');
                    }}
                  >
                    API Risk Calculation
                  </a>
                  .
                </Typography>
              }
            >
              <Box>
                <RiskScoreChip score={value} />
              </Box>
            </Tooltip>
          ) : (
            <></>
          ),
      },
      {
        field: 'endpoint_id',
        headerName: 'API ID',
        width: 80,
      },
      {
        field: 'method',
        headerName: 'Method',
        width: 100,
        type: 'singleSelect',
        valueOptions: ['get', 'post', 'put', 'patch', 'delete'],
        renderCell: ({ value }) => <APIMethodChip method={value} />,
      },
      {
        field: 'path',
        headerName: 'URL',
        minWidth: 150,
        flex: 1,
        sortable: false,
        filterable: false,
        renderCell: ({ value }) => (
          <Tooltip title={value} placement="right" arrow>
            <Box
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {value}
            </Box>
          </Tooltip>
        ),
      },
      {
        field: 'properties.authenticated',
        headerName: 'Authenticated',
        width: 110,
        type: 'singleSelect',
        align: 'center',
        valueGetter: ({ row }) => row.properties?.authenticated,
        valueOptions: [
          { value: true, label: 'Authenticated' },
          { value: false, label: 'Unauthenticated' },
        ],
        renderCell: ({ value }) =>
          value ? (
            <LockIcon2 size={20} />
          ) : value !== false ? (
            <Typography color={'#ACACBB'}>N/A</Typography>
          ) : (
            <OpenLockIcon size={20} />
          ),
      },
      {
        field: 'properties.endpoint_type',
        headerName: 'Technology',
        width: 100,
        type: 'singleSelect',
        valueOptions: ['REST', 'GraphQL'],
        sortable: false,
        filterable: false,
        valueGetter: ({ row }) => row.endpoint_type ?? 'REST',
        renderCell: ({ value }) => (
          <Stack direction={'row'} spacing={1}>
            {value !== 'GraphQL' ? (
              <CloudOutlined sx={{ fontSize: 20, color: '#8A9FC2' }} />
            ) : (
              <></>
            )}
            {value === 'GraphQL' ? (
              <GraphQLIcon size={20} color="#8A9FC2" />
            ) : (
              <></>
            )}
            <Box>{value}</Box>
          </Stack>
        ),
      },
      {
        field: 'source',
        headerName: 'Source',
        sortable: false,
        filterable: false,
        valueGetter: ({ row }) =>
          row.source
            ? distinct<Pick<APISource, 'type'>>(
                row.source?.map((s: any) => ({
                  name: s.value.endsWith('live-traffic.har') ? 'Live Traffic' : undefined,
                  type: s.name,
                })),
                (a) => apiCatalogSourceTitle(a.type),
              ).sort((v1, v2) =>
                apiCatalogSourceTitle(v1.type).localeCompare(
                  apiCatalogSourceTitle(v2.type),
                ),
              )
            : [],
        renderCell: ({ value, row }) => (
          <SourcesGridCell
            sources={value}
            hideAdd
            onSourceClick={(source, e) => {
              e.stopPropagation();
              onSourceViewClick(row, source);
            }}
          />
        ),
      },
      {
        field: 'properties.endpoint_status',
        headerName: 'Source gaps',
        minWidth: 150,
        flex: 1,
        type: 'singleSelect',
        sortable: false,
        filterable: false,
        valueGetter: ({ row }) => {
          const val = row.properties?.endpoint_status?.trim()?.toUpperCase();
          if (['VALIDATED', 'INTERNAL'].includes(val)) return undefined;

          return val;
        },
        valueOptions: [
          { value: 'UNDOCUMENTED', label: 'Undocumented API' },
          { value: 'NEW_API', label: 'New API' },
          { value: 'SHADOW', label: 'Shadow API' },
        ],
        renderCell: ({ value }) =>
          value ? (
            <APIStatusChip status={value} />
          ) : (
            <APIStatusChip status={undefined} />
          ),
      },
      {
        field: 'metadata.vulnerabilities',
        headerName: 'Vulnerabilities',
        minWidth: 150,
        flex: 1,
        sortable: false,
        filterable: false,
        valueGetter: ({ row }) => row.metadata?.vulnerabilities || 0,
        renderCell: ({ value }) => (
          <Box>
            <Tooltip title="Show vulnerabilities">
              <NumberChipIndicator value={value} />
            </Tooltip>
          </Box>
        ),
      },
      {
        field: 'application',
        headerName: 'Application',
        minWidth: 120,
        flex: 1,
        type: 'singleSelect',
        valueOptions:
          applications?.map((a) => ({
            label: a.name,
            value: a.app_id,
          })) ?? [],
        sortable: false,
        filterable: false,
        renderCell: ({ value }) =>
          value ? <ApplicationGridCell applicationId={value} /> : <></>,
      },
      {
        field: 'scansCount',
        headerName: '#Scans',
        width: 100,
        type: 'number',
        align: 'left',
        headerAlign: 'left',
        renderCell: ({ value }) =>
          value ? <NumberChipIndicator value={value} /> : <>None</>,
      },
      {
        field: 'properties.last_scan_time',
        headerName: 'Last Scan',
        width: 170,
        type: 'dateTime',
        sortable: false,
        valueGetter: ({ row }) =>
          row.properties?.last_scan_time
            ? new Date(row.properties?.last_scan_time + 'Z')
            : null,
        renderCell: ({ value, row }) =>
          value ? (
            <>
              <Button
                sx={HOVERABLE_NUMBER}
                onClick={(e) => {
                  e.stopPropagation();
                  onEndpointScanTimeClick(row);
                }}
              >
                {value.toLocaleString(undefined, {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </Button>
            </>
          ) : (
            <></>
          ),
      },
      {
        field: 'last_seen',
        headerName: 'Last Seen',
        width: 170,
        type: 'dateTime',
        filterable: false,
        sortable: false,
        valueGetter: ({ row }) =>
          row.last_seen ? new Date(row.last_seen + 'Z') : null,
        renderCell: ({ value }: { value?: Date }) =>
          value ? (
            <>
              {value.toLocaleString(undefined, {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </>
          ) : (
            <></>
          ),
      },
      {
        field: 'pii',
        headerName: 'PII',
        width: 80,
        sortable: false,
        filterable: false,
        renderCell: ({ value }) =>
          value ? <DangerIcon size={20} color={'#CA2E55'} /> : <></>,
      },
      {
        field: 'configuration',
        headerName: 'Configuration',
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: ({ value }) =>
          value ? <DangerIcon size={20} color={'#CA2E55'} /> : <></>,
      },
      {
        field: 'security',
        headerName: 'Security',
        width: 80,
        sortable: false,
        filterable: false,
      },
      // {
      //   field: 'actions',
      //   headerName: '',
      //   renderCell: () => <ChevronRightIcon />,
      // },
    ],
    [applications],
  );

  const [gridColumnsState, setGridColumnsState] =
    usePersistedJsonState<GridColumnsInitialState>(`${id}ColumnsState`, {
      columnVisibilityModel: {
        id: false,
        endpoint_id: false,
        pii: false,
        configuration: false,
        security: false,
        scansCount: false,
      },
    });

  const gridInitialState = useMemo<GridInitialStateCommunity>(() => {
    return {
      columns: {
        ...gridColumnsState,
        columnVisibilityModel: {
          ...gridColumnsState.columnVisibilityModel,
          ...(columnVisibilityModel ?? {}),
        },
      },
      pagination: { paginationModel: { pageSize: 10 } },
    };
  }, [gridColumnsState, filter, columnVisibilityModel]);

  // add polling
  // TODO: replace with websockets
  useEffect(() => {
    const interval = setInterval(async () => {
      const oldApis = new Map(
        apiCatalog?.map((a) => [a.endpoint_id, a.properties.last_scan_time]) ??
          [],
      );
      const fetchedAPIs = await fetchApiCatalog();
      const newAPIs = fetchedAPIs?.filter(
        (a: any) => !oldApis.has(a.endpoint_id),
      );
      const updatedAPIs = fetchedAPIs?.filter((a: any) => {
        if (!oldApis.has(a.endpoint_id) || !a.properties.last_scan_time) {
          return false;
        }

        const oldApi = oldApis.get(a.endpoint_id);
        if (!oldApi) {
          return true;
        }

        const oldApiDate = new Date(oldApi);
        const apiDate = new Date(a.properties.last_scan_time);

        return oldApiDate.getTime() < apiDate.getTime();
      });
      if (newAPIs?.length) {
        show(newAPIs.length === 1 ? 'New API!' : 'New APIs!');
      } else if (updatedAPIs?.length) {
        show(updatedAPIs.length === 1 ? 'API Updated!' : 'APIs Updated!');
      }
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, [apiCatalog]);

  return (
    <>
      <PyntDataGrid
        paginationMode="server"
        filterMode="server"
        sortingMode={'server'}
        sortModel={sortModel}
        filterModel={filterModel}
        filterDebounceMs={500}
        hideFooter={hideFooter}
        onSortModelChange={(sortModel) => setSortModel([...sortModel])}
        onFilterModelChange={(filterModel) =>
          setFilterModel({ ...filterModel })
        }
        paginationModel={{ page: page || 0, pageSize: pageSize || 50 }}
        onPaginationModelChange={(paginationModel) => {
          setPage(paginationModel.page);
          setPageSize(paginationModel.pageSize);
        }}
        autoHeight={autoHeight}
        autoPageSize={autoPageSize}
        rows={apiCatalog ?? []}
        rowCount={count}
        getRowId={(row) => row.endpoint_id}
        loading={!apiCatalog || isLoadingAPICatalog}
        columns={columns}
        initialState={gridInitialState}
        sx={{ width: '100%', borderRadius: 1 }}
        onStateChange={(state) => {
          setGridColumnsState(state.columns);
        }}
        pagination={pagination}
        pageSizeOptions={[25, 50, 100]}
        onRowClick={({ row }) => {
          if (isProd) return;

          // allow to select text without interaption
          if (window.getSelection()?.type === 'Range') return;

          navigate(`/dashboard/api-catalog/${row?.endpoint_id}`);
        }}
      />
    </>
  );
}
