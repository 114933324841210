import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  SxProps,
} from '@mui/material';
import { LockIcon } from '../../../assets/svgs/icons/LockIcon';
import IconTextField from '../../Common/IconTextField';
import TextFieldTitle from '../../Common/TextFieldTitle';
import { KongAPIGatewaySourceData } from './EditSourceFormAPIGateway';

const SELECT_ICON_WRAPPER: SxProps = {
  bgcolor: '#EFF5FF',
  width: 24,
  height: 24,
  borderRadius: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

interface Props {
  data?: KongAPIGatewaySourceData;
  onChanged: (data: KongAPIGatewaySourceData) => void;
  disabled?: boolean;
}

export default function EditSourceFormAPIGatewayKong({
  data,
  onChanged,
  disabled,
}: Props) {
  return (
    <Stack spacing={2}>
      <Box>
        <TextFieldTitle title="Admin Url" />
        <IconTextField
          fullWidth
          variant="filled"
          size="small"
          placeholder="Kong admin url"
          iconStart={
            <Box sx={SELECT_ICON_WRAPPER}>
              <LockIcon size={20} color="#BFCEE5" />
            </Box>
          }
          value={data?.['admin_url']}
          onChange={(v) =>
            onChanged({ ...data, admin_url: v.target.value?.trim() })
          }
          required
          disabled={disabled}
        />
      </Box>
      <Box>
        <TextFieldTitle title="Service Name" />
        <IconTextField
          fullWidth
          variant="filled"
          size="small"
          placeholder="Kong service name"
          iconStart={
            <Box sx={SELECT_ICON_WRAPPER}>
              <LockIcon size={20} color="#BFCEE5" />
            </Box>
          }
          value={data?.['service_name']}
          onChange={(v) =>
            onChanged({ ...data, service_name: v.target.value?.trim() })
          }
          required
          disabled={disabled}
        />
      </Box>
      <Box>
        <TextFieldTitle title="Authentication" />
        <FormControl
          variant="filled"
          fullWidth
          size="small"
          disabled={disabled}
        >
          <Select<'basic' | 'api_key'>
            disableUnderline
            value={data?.['auth_type'] ?? ''}
            onChange={(v) =>
              onChanged({
                ...data,
                auth_type:
                  !v.target.value || v.target.value === 'none'
                    ? null
                    : (v.target.value as any),
              })
            }
          >
            {[
              { value: 'basic', name: 'Basic Authentication' },
              { value: 'api_key', name: 'Key Auth' },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  <Box sx={SELECT_ICON_WRAPPER}>
                    <LockIcon size={20} color="#BFCEE5" />
                  </Box>
                  <span>{option.name}</span>
                </Stack>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {data?.auth_type ? (
        <Box>
          <TextFieldTitle title="Token" />
          <IconTextField
            fullWidth
            variant="filled"
            size="small"
            placeholder="Kong token"
            iconStart={
              <Box sx={SELECT_ICON_WRAPPER}>
                <LockIcon size={20} color="#BFCEE5" />
              </Box>
            }
            value={data?.['auth_token']}
            onChange={(v) =>
              onChanged({ ...data, auth_token: v.target.value?.trim() })
            }
            required
            disabled={disabled}
          />
        </Box>
      ) : (
        <></>
      )}
    </Stack>
  );
}
