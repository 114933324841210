import {
  Box,
  Button,
  Divider,
  Stack,
  SxProps,
  Typography,
  capitalize,
} from '@mui/material';
import { useCallback, useContext, useMemo } from 'react';
import {
  ApplicationContext,
  ApplicationContextProps,
} from '../../contexts/Application';
import { SnackbarContext } from '../../contexts/Snackbar';
import { APISource } from '../../contexts/User';
import { useHasFeature } from '../../hooks/useHasFeature';
import { track } from '../../utils/analytics';
import { apiCatalogSourceTitle } from '../APICatalog/APICatalogGrid';
import { normalizeRiskScore } from '../APICatalog/RiskScoreChip';
import SourcesGridCell from '../Applications/SourcesGridCell';
import CustomCard from '../CustomCard/CustomCard';

const CARD: SxProps = {
  pr: 4,
  pl: 4,
  pt: 4,
  pb: 4,
  height: 278,
};

const ANALYTICS_GRID: SxProps = {};

const ANALYTICS_GRID_COL: SxProps = {
  py: 2,
  flex: 1,
};

const ANALYTICS_GRID_COL_ITEM: SxProps = {
  px: 6,
  gap: 0.5,
  minWidth: 140,
};

interface Props {
  onViewSource: (source: APISource) => void;
  onAddSource: (source?: 'documentation' | 'testing' | 'production') => void;
}

export default function ApplicationOverviewCard({
  onViewSource,
  onAddSource,
}: Props) {
  const hasCreateSourceFeature = useHasFeature('create_source');

  const { show } = useContext(SnackbarContext);
  const { application } = useContext(
    ApplicationContext,
  ) as ApplicationContextProps;

  const textColor = useMemo(() => {
    switch (normalizeRiskScore(application?.risk)) {
      case 'LOW':
        return '#00A676';

      case 'MEDIUM':
        return '#CA822E';

      case 'HIGH':
        return '#CA2E55';

      case 'CRITICAL':
        return '#CA2E55';

      default:
        return '#d3d3d3';
    }
  }, [application?.risk]);

  const getSourcesByType = (type: ReturnType<typeof apiCatalogSourceTitle>) => {
    if (type === 'Testing') {
      const scanSource = application?.data_sources?.scan?.source_id;
      if (!scanSource) return [];

      return [
        {
          application: application?.app_id,
          type: 'scan',
          name: 'Pynt Scan',
          source_id: scanSource,
          status: 'SUCCEEDED',
          properties: {},
        } as APISource,
      ];
    }
    return (
      Object.keys(application?.data_sources || {})
        .filter((s) => apiCatalogSourceTitle(s as any) === type)
        .map((s) => {
          return {
            application: application?.app_id,
            type: s,
            name: (application?.data_sources as any)[s]?.name || 'Source',
            source_id: (application?.data_sources as any)[s]?.source_id || '',
            status:
              (application?.data_sources as any)[s]?.status || 'SUCCEEDED',
            properties: {},
          } as APISource;
        }) || []
    );
  };

  const onAppIdCopyButtonClick = useCallback(() => {
    if (!application) return;

    track('web_app_copy_application_id_button_click', {
      app_id: application.app_id,
    });

    navigator.clipboard.writeText(application.app_id);
    show('Application ID copied to clipboard');
  }, [application]);

  return (
    <CustomCard sx={CARD}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        spacing={6}
        height={'100%'}
      >
        <Stack direction={'column'} justifyContent={'start'} spacing={1}>
          <Typography
            sx={{
              color: '#050D21',
              fontSize: 28,
              textAlign: 'start',
              fontWeight: 500,
              whiteSpace: 'nowrap',
            }}
          >
            {application?.name}
          </Typography>
          <Box flex={1}>
            <Typography
              sx={{
                color: '#7B7B88',
                fontSize: 14,
                textAlign: 'start',
                maxWidth: 600,
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 4,
              }}
            >
              This is your {application?.name} page, you can view your api
              catalog and analytics about the apis at risk. Review the risk
              scores for actions you need to take and follow your team&apos;s
              scans in the scans tab.
            </Typography>

            <Stack direction={'row'}>
              <Button
                variant="text"
                size="small"
                sx={{ fontWeight: 400, ml: '-5px' }}
                onClick={onAppIdCopyButtonClick}
              >
                Copy App ID
              </Button>
            </Stack>
          </Box>

          <Stack
            direction={'row'}
            justifyContent={'start'}
            alignItems={'stretch'}
            spacing={4}
          >
            <Stack
              direction={'column'}
              spacing={0}
              alignItems={'start'}
              id="app-production-sources"
            >
              <Typography
                sx={{ color: '#7B7B88', fontSize: 14, textAlign: 'start' }}
              >
                Production
              </Typography>
              <Box flex={1}>
                <Stack ml={-1} height={'100%'} justifyContent={'center'}>
                  <SourcesGridCell
                    sources={getSourcesByType('Production')}
                    hideAdd={!hasCreateSourceFeature}
                    onAddClick={() => onAddSource('production')}
                    onSourceClick={(source) => onViewSource(source as any)}
                  />
                </Stack>
              </Box>
            </Stack>
            <Stack direction={'column'} spacing={0} alignItems={'start'}>
              <Typography
                sx={{ color: '#7B7B88', fontSize: 14, textAlign: 'start' }}
              >
                Testing
              </Typography>
              <Box flex={1}>
                <Stack ml={-1} height={'100%'} justifyContent={'center'}>
                  <SourcesGridCell
                    sources={getSourcesByType('Testing')}
                    hideAdd={!hasCreateSourceFeature}
                    onAddClick={() => onAddSource('testing')}
                    onSourceClick={(source) => onViewSource(source as any)}
                  />
                </Stack>
              </Box>
            </Stack>
            <Stack
              direction={'column'}
              spacing={0}
              alignItems={'start'}
              id="app-documentation-sources"
            >
              <Typography
                sx={{ color: '#7B7B88', fontSize: 14, textAlign: 'start' }}
              >
                Documentation
              </Typography>
              <Box flex={1}>
                <Stack ml={-1} height={'100%'} justifyContent={'center'}>
                  <SourcesGridCell
                    sources={getSourcesByType('Documentation')}
                    hideAdd={!hasCreateSourceFeature}
                    onAddClick={() => onAddSource('documentation')}
                    onSourceClick={(source) => onViewSource(source as any)}
                  />
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction={'row'} sx={ANALYTICS_GRID}>
          <Stack
            direction={'column'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={ANALYTICS_GRID_COL}
          >
            <Stack
              direction={'column'}
              alignItems={'center'}
              sx={ANALYTICS_GRID_COL_ITEM}
            >
              <Typography
                sx={{
                  color: textColor,
                  fontSize: 28,
                  textAlign: 'center',
                  fontWeight: 500,
                }}
              >
                {capitalize(
                  normalizeRiskScore(application?.risk)?.toLocaleLowerCase() ??
                    'None',
                )}
              </Typography>
              <Typography
                sx={{ color: '#7B7B88', fontSize: 14, textAlign: 'center' }}
              >
                Risk
              </Typography>
            </Stack>
            <Divider flexItem />
            <Stack
              direction={'column'}
              alignItems={'center'}
              sx={ANALYTICS_GRID_COL_ITEM}
            >
              <Typography
                sx={{
                  color: '#000',
                  fontSize: 28,
                  textAlign: 'center',
                  fontWeight: 500,
                }}
              >
                {application?.vulnerability_instances || 0}
              </Typography>
              <Typography
                sx={{ color: '#7B7B88', fontSize: 14, textAlign: 'center' }}
              >
                Vulnerabilities
              </Typography>
            </Stack>
          </Stack>
          <Divider orientation="vertical" flexItem />
          <Stack
            direction={'column'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={ANALYTICS_GRID_COL}
          >
            <Stack
              direction={'column'}
              alignItems={'center'}
              sx={ANALYTICS_GRID_COL_ITEM}
            >
              <Typography
                sx={{
                  color: '#000',
                  fontSize: 28,
                  textAlign: 'center',
                  fontWeight: 500,
                }}
              >
                {application?.number_of_endpoints || 0}
              </Typography>
              <Typography
                sx={{ color: '#7B7B88', fontSize: 14, textAlign: 'center' }}
              >
                APIs
              </Typography>
            </Stack>
            <Divider flexItem />
            <Stack
              direction={'column'}
              alignItems={'center'}
              sx={ANALYTICS_GRID_COL_ITEM}
            >
              <Typography
                sx={{
                  color: '#000',
                  fontSize: 28,
                  textAlign: 'center',
                  fontWeight: 500,
                }}
              >
                {application?.tests || 0}
              </Typography>
              <Typography
                sx={{ color: '#7B7B88', fontSize: 14, textAlign: 'center' }}
              >
                Tests
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </CustomCard>
  );
}
