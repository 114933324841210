import {
  Box,
  CircularProgress,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  SxProps,
  Tooltip,
  Typography,
  capitalize,
} from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { DocumentText } from '../../assets/svgs/icons/DocumentText';
import {
  ApplicationContext,
  ApplicationContextProps,
} from '../../contexts/Application';
import { AuthContext } from '../../contexts/Auth';
import usePrevious from '../../hooks/usePrevious';
import {
  RunningScanHistoryItem,
  ScanHistoryItem,
} from '../../models/scanHistory';
import { formatDuration } from '../../utils';
import { track } from '../../utils/analytics';
import ScanReportDialog from '../Common/ScanReportDialog';
import CustomCard from '../CustomCard/CustomCard';
import HistoryScanIntegrationIcon from '../ScansHistory/HistoryScanIntegrationIcon';

const CARD: SxProps = {
  pr: 4,
  pl: 4,
  pt: 4,
  pb: 4,
};

const FULL_STACK: SxProps = {
  width: '100%',
};

const LABEL: SxProps = {
  color: '#7B7B88',
  fontSize: 14,
  textAlign: 'start',
  whiteSpace: 'nowrap',
};

const VALUE: SxProps = {
  color: 'black',
  fontSize: 14,
  textAlign: 'end',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export default function ApplicationScanOverviewCard() {
  const { user } = useContext(AuthContext);
  const { latestScans, isLoadingLatestScans, runningScans } = useContext(
    ApplicationContext,
  ) as ApplicationContextProps;
  const [selectedScanId, setSelectedScanId] = useState<string>();
  const [scanReportDialogOpen, setScanReportDialogOpen] = useState(false);
  const prevRunningScans = usePrevious(runningScans);
  const selectedScan: ScanHistoryItem | RunningScanHistoryItem | undefined =
    useMemo(
      () =>
        latestScans?.find((s) => s.scan_id === selectedScanId) ||
        runningScans?.find((s) => s.scan_id === selectedScanId),
      [selectedScanId, latestScans, runningScans],
    );

  useEffect(() => {
    const existingScansIds = new Set(
      prevRunningScans?.map((s) => s.scan_id) || [],
    );
    const hasNewRunningScan = runningScans?.some(
      (s) => !existingScansIds.has(s.scan_id),
    );
    // Initial scan selection logic:
    // 1. If there is a running scan for the current user, select it.
    // 2. If there are any running scans, select the first one.
    // 3. If there are no running scans, select the latest scan.
    setSelectedScanId((selectedScanId) => {
      if (selectedScanId && !hasNewRunningScan) {
        if (
          runningScans?.find((s) => s.scan_id === selectedScanId) ||
          latestScans?.find((s) => s.scan_id === selectedScanId)
        ) {
          return selectedScanId;
        }
      }
      return (
        runningScans?.find((s) => s.source?.user === user?.uid)?.scan_id ||
        runningScans?.[0]?.scan_id ||
        latestScans?.[0]?.scan_id
      );
    });
  }, [latestScans, runningScans, prevRunningScans]);

  const progress =
    (selectedScan as any)?.traffic_progress ||
    (selectedScan as any)?.scan_progress ||
    0;

  return (
    <>
      <ScanReportDialog
        scanId={selectedScanId}
        open={scanReportDialogOpen}
        setOpen={setScanReportDialogOpen}
      />
      <CustomCard sx={CARD}>
        {!isLoadingLatestScans &&
          (latestScans?.length || runningScans?.length) ? (
          <Stack
            direction={'column'}
            justifyContent={'start'}
            spacing={1}
            sx={FULL_STACK}
          >
            <Stack direction="row" gap={1}>
              <Select
                size="small"
                value={selectedScanId || ''}
                onChange={(s) =>
                  s.target.value ? setSelectedScanId(s.target.value) : undefined
                }
                sx={{
                  textAlign: 'start',
                  flex: 1,
                  width: 'calc(100% - 48px)',
                }}
              >
                <MenuItem disabled>Select scan</MenuItem>
                {runningScans?.map((scan) => (
                  <MenuItem key={scan.scan_id} value={scan.scan_id}>
                    ({scan.progress_type === 'traffic' ? 'Traffic' : 'Running'}){' '}
                    {scan.scan_time}
                  </MenuItem>
                ))}
                {latestScans?.map((scan, i) => (
                  <MenuItem key={scan.scan_id} value={scan.scan_id}>
                    {i === 0 ? 'Last scan' : scan.scan_time}
                  </MenuItem>
                ))}
              </Select>

              <Tooltip title="Download Report" sx={{ mr: -1, flexShrink: 0 }}>
                <IconButton
                  disabled={
                    !selectedScanId || selectedScan?.scan_status === 'running'
                  }
                  onClick={() => {
                    track(
                      'web_app_application_screen_scan_card_view_report_button_click',
                      {
                        scan_id: selectedScanId,
                      },
                    );
                    setScanReportDialogOpen(true);
                  }}
                >
                  <DocumentText size={24} />
                </IconButton>
              </Tooltip>
            </Stack>
            <Box></Box>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={2}
            >
              <Typography sx={LABEL}>Status</Typography>
              {selectedScan ? (
                <Typography sx={VALUE}>
                  {capitalize(
                    'progress_type' in selectedScan &&
                      selectedScan.progress_type === 'traffic'
                      ? 'Generating Traffic'
                      : selectedScan.scan_status || '',
                  )}
                </Typography>
              ) : (
                <></>
              )}
            </Stack>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={2}
              spacing={4}
            >
              <Typography sx={LABEL}>Integration</Typography>
              {selectedScan ? (
                <Stack direction={'row'} alignItems={'center'} gap={0.5}>
                  <Typography sx={VALUE}>
                    {capitalize(selectedScan.source.integraion)}
                  </Typography>
                  <HistoryScanIntegrationIcon
                    type={selectedScan.source.integraion as any}
                  />
                </Stack>
              ) : (
                <></>
              )}
            </Stack>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={2}
            >
              <Typography sx={LABEL}>Test name</Typography>
              {selectedScan ? (
                <Tooltip title={selectedScan.functional_test_name}>
                  <Typography sx={VALUE}>
                    {selectedScan.functional_test_name}
                  </Typography>
                </Tooltip>
              ) : (
                <></>
              )}{' '}
            </Stack>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={2}>
              <Typography sx={LABEL}>Tags</Typography>
              {selectedScan ? (
                <Tooltip title={selectedScan.tags.join(', ')}>
                  <Typography sx={VALUE}>
                    {selectedScan.tags.join(', ')}
                  </Typography>
                </Tooltip>
              ) : (<>-</>)}
            </Stack>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={2}
            >
              <Typography sx={LABEL}>Time</Typography>
              {selectedScan ? (
                <Typography sx={VALUE}>
                  {new Date(selectedScan.scan_time + 'Z').toLocaleString(
                    undefined,
                    {
                      day: '2-digit',
                      month: '2-digit',
                      year: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                    },
                  )}
                </Typography>
              ) : (
                <></>
              )}{' '}
            </Stack>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={2}
            >
              <Typography sx={LABEL}>User</Typography>
              {selectedScan ? (
                <Typography sx={VALUE}>{selectedScan.first_name}</Typography>
              ) : (
                <></>
              )}
            </Stack>
            {!selectedScan || selectedScan.scan_status !== 'running' ? (
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                gap={2}
              >
                <Typography sx={LABEL}>Scan duration</Typography>
                {selectedScan && (
                  <Typography sx={VALUE}>
                    {formatDuration(
                      Math.floor(selectedScan.scan_duration / 1e9),
                    )}
                  </Typography>
                )}
              </Stack>
            ) : (
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                gap={2}
              >
                <Typography sx={LABEL}>
                  {'progress_type' in selectedScan &&
                    selectedScan.progress_type === 'traffic'
                    ? 'Traffic'
                    : 'Scan'}{' '}
                  progress
                </Typography>
                <Stack direction={'row'} alignItems={'center'} gap={0.5}>
                  <Typography sx={VALUE}>{progress}%</Typography>

                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{ width: 100, height: 12, borderRadius: '6px' }}
                  />
                </Stack>
              </Stack>
            )}
          </Stack>
        ) : (
          <></>
        )}
        {isLoadingLatestScans ? (
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            height={'100%'}
          >
            <CircularProgress />
          </Stack>
        ) : (
          <></>
        )}
        {!isLoadingLatestScans && latestScans?.length === 0 ? (
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            height={'100%'}
          >
            <Typography>No Scans</Typography>
          </Stack>
        ) : (
          <></>
        )}
        {!isLoadingLatestScans && !latestScans ? (
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            height={'100%'}
          >
            <Typography color={'red'}>
              There was an error loading the latest scans
            </Typography>
          </Stack>
        ) : (
          <></>
        )}
      </CustomCard>
    </>
  );
}
