import * as Sentry from '@sentry/react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  usePersistedJsonState,
  usePersistedState,
} from '../hooks/usePersistedState';
import { Tag } from '../models/tag';
import { getApplications } from '../services/ApplicationsService';
import { getAvailableFeatures } from '../services/FeaturesService';
import { getSources } from '../services/SourcesService';
import { getTags } from '../services/TagsService';
import { AuthContext } from './Auth';
import { Application } from '../models/application';

export interface APISource {
  source_id: string;
  name: string;
  type:
    | 'swagger'
    | 'swagger_file'
    | 'postman_file'
    | 'aws_api_gw'
    | 'azure_api_gw'
    | 'kong_api_gw'
    | 'scan'
    | 'har_file';
  status: string;
  application: string;
  properties: { name: string; value: any }[];
}

type Features = Record<string, boolean>;

export interface UserContextProps {
  apiSources?: APISource[];
  fetchApiSources: () => Promise<APISource[]>;
  isLoadingApiSources: boolean;
  applications?: Application[];
  tags?: Tag[];
  isLoadingTags: boolean;
  selectedApplication?: Application;
  fetchApplications: () => Promise<void>;
  fetchTags: () => Promise<Tag[]>;
  isLoadingApplications: boolean;
  getAppByIdSync: (id: string) => Application | undefined;
  selectedApplicationId: string | undefined;
  setSelectedApplicationId: (id: string) => any;
  features: Features;
}

export const UserContext = createContext<UserContextProps | null>(null);

function UserContextProvider(props: any) {
  const { isAuthenticated } = useContext(AuthContext);

  const [features, setFeatures] = usePersistedJsonState<Features>('featureflags', {});

  const [apiSources, setApiSources] = useState<APISource[]>();
  const [isLoadingApiSources, setIsLoadingApiSources] = useState(false);

  const [applications, setApplications] = useState<Application[]>();
  const [isLoadingApplications, setIsLoadingApplications] = useState(false);

  const [tags, setTags] = useState<Tag[]>();
  const [isLoadingTags, setIsLoadingTags] = useState(false);

  const [selectedApplicationId, setSelectedApplicationId] = usePersistedState(
    'selectedApplicationId',
    '*',
  );

  const selectedApplication = useMemo(() => {
    if (!selectedApplicationId || selectedApplicationId === '*') {
      return undefined;
    }

    const app = applications?.find(
      (application) => application.app_id === selectedApplicationId,
    );

    return app;
  }, [applications, selectedApplicationId]);

  const fetchApiSources = useMemo(
    () => async () => {
      if (isLoadingApiSources) return;

      setIsLoadingApiSources(true);

      const apiSources = await getSources()
        .catch((e) => {
          console.error(e);
          Sentry.captureException(e);
        })
        .finally(() => {
          setIsLoadingApiSources(false);
        });
      setApiSources(apiSources);

      return apiSources;
    },
    [isLoadingApiSources],
  );

  const fetchApplications = useMemo(
    () => async () => {
      if (isLoadingApplications) return;

      setIsLoadingApplications(true);

      const applications = await getApplications()
        .catch((e) => {
          console.error(e);
          Sentry.captureException(e);
          return [];
        })
        .finally(() => {
          setIsLoadingApplications(false);
        });

      setApplications(applications);
    },
    [isLoadingApplications],
  );

  const fetchTags = useCallback(async () => {
    setIsLoadingTags(true);

    const tags = await getTags()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsLoadingTags(false);
      });

    setTags(tags);

    return tags;
  }, []);

  const fetchAvailableFeatures = useCallback(async () => {
    const features = await getAvailableFeatures().catch((e) => {
      console.error(e);
      Sentry.captureException(e);
    });
    setFeatures(features);
  }, []);

  // reset on logout
  useEffect(() => {
    if (isAuthenticated) return;

    setApiSources(undefined);
    setApplications(undefined);
    setTags(undefined);
  }, [isAuthenticated]);

  // fetch code data when app loads
  useEffect(() => {
    if (!isAuthenticated) return;

    fetchApiSources();
    fetchApplications();
    fetchTags();
  }, [isAuthenticated]);

  // fetch available features
  useEffect(() => {
    if (!isAuthenticated) return;

    fetchAvailableFeatures();
  }, [isAuthenticated]);

  const getAppByIdSync = useMemo(
    () => (id: string) => {
      return applications?.find((a) => a.app_id === id);
    },
    [applications],
  );

  return (
    <UserContext.Provider
      value={{
        apiSources,
        isLoadingApiSources,
        fetchApiSources,
        applications,
        isLoadingApplications,
        fetchApplications,
        tags,
        isLoadingTags,
        fetchTags,
        getAppByIdSync,
        selectedApplicationId,
        setSelectedApplicationId,
        selectedApplication,
        features,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;
