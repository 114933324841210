import axios, { AxiosRequestConfig } from 'axios';
import {
  AUTH_REFRESH_TOKEN_STORAGE_KEY,
  AUTH_TOKEN_STORAGE_KEY,
  CUSTOM_HEADERS_STORAGE_KEY,
} from '../constants/params';
import { concurrentSafeExecution } from '../utils';
import { isJWTExpired } from '../utils/auth';

const BASE = process.env.REACT_APP_PYNT_API_BASE_URL;
const instance = axios.create({
  baseURL: BASE,
  headers: {
    'Content-type': 'application/json',
  },
});
export const onRefreshError: { cb?: () => void } = {};

export const getStoredHeaders = () => {
  try {
    const res = sessionStorage.getItem(CUSTOM_HEADERS_STORAGE_KEY);
    return res ? JSON.parse(res) : {};
  } catch (e) {
    return {};
  }
};

export const storeHeaders = (headers?: any) => {
  if (!headers) {
    sessionStorage.removeItem(CUSTOM_HEADERS_STORAGE_KEY);
  } else {
    sessionStorage.setItem(CUSTOM_HEADERS_STORAGE_KEY, JSON.stringify(headers));
  }
};

export const getStoredAccessToken = () => {
  return sessionStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
};

export const storeAccessToken = (accessToken?: string) => {
  if (!accessToken) {
    sessionStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
  } else {
    sessionStorage.setItem(AUTH_TOKEN_STORAGE_KEY, accessToken);
  }
};

export const getStoredRefreshToken = () => {
  return localStorage.getItem(AUTH_REFRESH_TOKEN_STORAGE_KEY);
};

export const storeRefreshToken = (refreshToken?: string) => {
  if (!refreshToken) {
    localStorage.removeItem(AUTH_REFRESH_TOKEN_STORAGE_KEY);
  } else {
    localStorage.setItem(AUTH_REFRESH_TOKEN_STORAGE_KEY, refreshToken);
  }
};

const refreshToken = concurrentSafeExecution(async () => {
  try {
    const token = getStoredRefreshToken();
    if (!token) return;
    const resp = await axios.post<{ token: string }>(BASE + '/auth/refresh', {
      refresh_token: token,
    });

    // Save the newly created token
    if (resp.status === 200) {
      const accessToken = resp.data.token;
      storeAccessToken(accessToken);
    }

    return resp.data;
  } catch (e) {
    console.error('Error', e);
  }
});

instance.interceptors.request.use(
  async (config) => {
    if (config.headers.Authorization) return config;
    let token: string | null = getStoredAccessToken();

    // if the current token is expired, remove it
    if (token && isJWTExpired(token)) {
      console.log('Token expired');
      token = null;
    }

    // if there is no token and there is a refresh token, refresh the token
    if (!token && getStoredRefreshToken()) {
      const resp = await refreshToken();
      if (!resp) {
        onRefreshError.cb?.();
        throw new Error('No token');
      }

      token = resp.token;
    }

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    const ch = getStoredHeaders();

    if (ch) {
      Object.entries(ch).forEach(([k, v]) => {
        if (config.headers[k]) return;
        config.headers[k] = v;
      });
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error.response?.status === 401 &&
      error.response?.data?.license?.status !== 'expired' &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const resp = await refreshToken();
      if (!resp) {
        onRefreshError.cb?.();
        return Promise.reject(error);
      }
      return instance(originalRequest);
    }
    return Promise.reject(error);
  },
);

export async function request<T = any>(
  path: string,
  config?: AxiosRequestConfig,
) {
  return instance<T>(path, {
    ...config,
  });
}

export async function get<T = any>(path: string, config?: AxiosRequestConfig) {
  return request<T>(path, { ...config, method: 'get' });
}

export async function post(
  path: string,
  params: any,
  config?: AxiosRequestConfig,
) {
  return request(path, {
    ...config,
    method: 'post',
    data: JSON.stringify(params),
  });
}

export async function put<T = any>(
  path: string,
  params: any,
  config?: AxiosRequestConfig,
) {
  return request<T>(path, {
    ...config,
    method: 'put',
    data: JSON.stringify(params),
  });
}

export async function patch(
  path: string,
  params?: any,
  config?: AxiosRequestConfig,
) {
  return request(path, {
    ...config,
    method: 'patch',
    data: params ? JSON.stringify(params) : undefined,
  });
}

export async function httpDelete(
  path: string,
  config?: AxiosRequestConfig,
) {
  return request(path, {
    ...config,
    method: 'delete',
  });
}

export async function postFormData(
  path: string,
  data: FormData,
  config?: AxiosRequestConfig,
) {
  return instance.post(path, data, {
    ...config,
    headers: {
      'Content-type': 'multipart/form-data',
      ...config?.headers,
    },
  });
}

export type PyntFilter = {
  where?: {
    [key: string]: string | number | { [key: string]: string | number };
  };
  sort?: { [key: string]: number };
  limit?: number;
  offset?: number;
};
