import { Components, Theme } from '@mui/material';
import type {} from '@mui/x-data-grid/themeAugmentation';

export default function themedMuiDataGrid(): Pick<
  Components<Theme>,
  'MuiDataGrid'
> {
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          backgroundColor: '#ffffff',
          border: '0px solid #F5F8FD',
        },
      },
    },
  };
}
