import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SxProps,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import { useContext, useEffect, useState } from 'react';
import {
  ApplicationContext,
  ApplicationContextProps,
} from '../../../contexts/Application';
import { SnackbarContext } from '../../../contexts/Snackbar';
import {
  APISource,
  UserContext,
  UserContextProps,
} from '../../../contexts/User';
import AlertDialog from '../../../dialogs/AlertDialog';
import { useHasFeature } from '../../../hooks/useHasFeature';
import {
  createSource,
  deleteSource,
  getSource,
} from '../../../services/SourcesService';
import { track } from '../../../utils/analytics';
import CustomDrawer from '../../Common/CustomDrawer';
import TextFieldTitle from '../../Common/TextFieldTitle';
import EditSourceForm, { SourceData } from './EditSourceForm';
import {
  AwsAPIGatewaySourceData,
  AzureAPIGatewaySourceData,
  KongAPIGatewaySourceData,
} from './EditSourceFormAPIGateway';
import { PostmanSourceData } from './EditSourceFormPostmanFile';
import { Application } from '../../../models/application';

const BOTTOM_SPACER: SxProps = { minHeight: 14, flex: 1 };

interface Props {
  mode: 'create' | 'edit' | 'view';
  application?: Application;
  showApplicationSelector?: boolean;
  defaultData?: Partial<SourceData>;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  sourceId?: string;
  onSave?: (data: SourceData) => void;
}

const getIntegration = (type: APISource['type']): SourceData['integration'] => {
  switch (type) {
    case 'swagger':
    case 'swagger_file':
      return 'swagger';

    case 'postman_file':
      return 'postman';

    case 'aws_api_gw':
    case 'azure_api_gw':
    case 'kong_api_gw':
      return 'apiGateway';
  }
};

const getSourceOrigin = (
  type: APISource['type'],
): SourceData['sourceOrigin'] => {
  switch (type) {
    case 'swagger':
    case 'swagger_file':
    case 'postman_file':
      return 'documentation';

    case 'azure_api_gw':
    case 'aws_api_gw':
    case 'kong_api_gw':
    case 'har_file':
      return 'production';
  }
};

const source2SourceData = (source?: APISource): SourceData => {
  if (!source) return {};

  const integration = getIntegration(source.type);
  const sourceOrigin = getSourceOrigin(source.type);
  const props = Object.fromEntries(
    source.properties.map((p) => [p.name, p.value]),
  );

  return {
    sourceOrigin,
    integration,
    swaggerType:
      integration === 'swagger' ? (props.url ? 'link' : 'file') : undefined,
    url: integration === 'swagger' ? props.url : undefined,
    ...((source.type === 'aws_api_gw'
      ? ({
          vendor: 'aws',
          region: props.region,
          access_key_id: props.access_key_id,
          secret_access_key_id: props.secret_key,
          gw_id: props.gw_id,
          stage: props.stage,
        } as AwsAPIGatewaySourceData)
      : {}) as any),
    ...((source.type === 'azure_api_gw'
      ? ({
          vendor: 'azure',
          tenant_id: props.tenant_id,
          client_id: props.client_id,
          client_secret: props.client_secret,
          subscription_id: props.subscription_id,
          resource_group_name: props.resource_group_name,
          service_name: props.service_name,
        } as AzureAPIGatewaySourceData)
      : {}) as any),
    ...((source.type === 'kong_api_gw'
      ? ({
          vendor: 'kong',
          admin_url: props.admin_url,
          service_name: props.service_name,
          auth_type: props.auth_type,
          auth_token: props.auth_token,
        } as KongAPIGatewaySourceData)
      : {}) as any),
    ...((source.type === 'swagger_file'
      ? ({
          swaggerFile: props.path,
        } as PostmanSourceData)
      : {}) as any),
    ...((source.type === 'postman_file'
      ? ({
          file: props.path,
        } as PostmanSourceData)
      : {}) as any),
  };
};

const EditSrouceDrawer = (props: Props) => {
  const { fetchApiSources, fetchApplications, applications } = useContext(
    UserContext,
  ) as UserContextProps;
  const { refetchApplication } = useContext(
    ApplicationContext,
  ) as ApplicationContextProps;
  const { show } = useContext(SnackbarContext);
  const { mode, application, isOpen, setIsOpen } = props;
  const [data, setData] = useState<SourceData>({
    ...props.defaultData,
  });

  const [isLoadingSourceData, setIsLoadingSourceData] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [deletingSource, setDeletingSource] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState<
    Application | undefined
  >(application);

  const hasDeleteSourceFeature = useHasFeature('delete_source');

  const [openVerifySourceDeleteDialog, setOpenVerifySourceDeleteDialog] =
    useState(false);

  // Clean data upon drawer close
  useEffect(() => {
    if (props.isOpen) return;

    setData((d) => {
      return { ...d, ...props.defaultData } as SourceData;
    });
    setIsLoadingSave(false);
    setSelectedApplication(props.application);
    setDeletingSource(false);
  }, [props.isOpen, props.application, props.defaultData]);

  useEffect(() => {
    setSelectedApplication(application);
  }, [application]);

  useEffect(() => {
    if (!props.defaultData) return;
    setData((d) => {
      return { ...d, ...props.defaultData } as SourceData;
    });
  }, [props.defaultData]);

  useEffect(() => {
    if (!props.sourceId || !props.isOpen) return;

    let relevant = true;
    setIsLoadingSourceData(true);
    getSource(props.sourceId)
      .then((source) => {
        if (!relevant) return;
        setData(source ? source2SourceData(source) : {});
      })
      .finally(() => {
        if (!relevant) return;
        setIsLoadingSourceData(false);
      });

    return () => {
      relevant = false;
    };
  }, [props.sourceId, props.isOpen]);

  const _createSource = async (data: SourceData, application: Application) => {
    return createSource(data, application).catch((e) => {
      console.error(e);
      throw e;
    });
  };

  const onSaveClick = () => {
    const id = Date.now();
    track('web_app_save_source_button_click', {
      id,
      mode,
      source_id: props.sourceId,
      application_id: selectedApplication?.app_id,
      integration: data.integration,
      sourceOrigin: data.sourceOrigin,
    });

    if (mode === 'edit' || mode === 'view') {
      show('Edit not yet supported');
      return;
    }

    if (!selectedApplication) {
      show('Please select an application');
      return;
    }

    setIsLoadingSave(true);

    const promise = _createSource(data, selectedApplication);

    promise
      .then(async () => {
        await fetchApiSources().catch((e) => {
          console.error(e);
          Sentry.captureException(e);
        });
        await fetchApplications().catch((e) => {
          console.error(e);
          Sentry.captureException(e);
        });
        await refetchApplication().catch((e) => {
          console.error(e);
          Sentry.captureException(e);
        });
        setIsLoadingSave(false);
        setIsOpen(false);
        props.onSave?.(data);

        track('web_app_save_source_action_success', {
          id,
          mode,
          source_id: props.sourceId,
          application_id: props.application?.app_id,
          integration: data.integration,
          sourceOrigin: data.sourceOrigin,
        });
      })
      .catch((e) => {
        console.error(e);
        show(e?.response?.data?.error?.message ?? e.toString(), 'error');
        track('web_app_save_source_action_failed', {
          id,
          mode,
          source_id: props.sourceId,
          application_id: props.application?.app_id,
          integration: data.integration,
          sourceOrigin: data.sourceOrigin,
          error: e.message || e.toString(),
        });
        Sentry.captureException(e);
      })
      .finally(() => {
        setIsLoadingSave(false);
      });
  };

  const onDeleteClick = () => {
    const sourceId = props.sourceId;
    if (!sourceId) return;

    track('web_app_setup_source_delete_button_click', {
      application_id: selectedApplication?.app_id,
      source_id: props.sourceId,
    });

    setOpenVerifySourceDeleteDialog(true);
  };

  return (
    <>
      <AlertDialog
        open={!!openVerifySourceDeleteDialog}
        onClose={() => {
          setOpenVerifySourceDeleteDialog(false);
        }}
        title="Do you wish to continue deleting this source?"
        actions={[
          {
            // eslint-disable-next-line quotes
            title: "No, I'd like to keep it",
            onClick: () => setOpenVerifySourceDeleteDialog(false),
          },
          {
            title: 'Yes, Delete the source',
            onClick: () => {
              const sourceId = props?.sourceId;
              setOpenVerifySourceDeleteDialog(false);
              if (!sourceId) return;

              setDeletingSource(true);
              deleteSource(sourceId || '')
                .then(async () => {
                  await fetchApiSources().catch((e) => {
                    console.error(e);
                    Sentry.captureException(e);
                  });
                  await fetchApplications().catch((e) => {
                    console.error(e);
                    Sentry.captureException(e);
                  });
                  await refetchApplication().catch((e) => {
                    console.error(e);
                    Sentry.captureException(e);
                  });
                })
                .then(() => {
                  props.setIsOpen(false);
                  show('Source deleted.');
                })
                .catch((e) => {
                  show(
                    'Failed to delete source, ' + e.message || e.toString(),
                    'error',
                  );
                  Sentry.captureException(e);
                })
                .finally(() => {
                  setDeletingSource(false);
                });
            },
          },
        ]}
      />
      <CustomDrawer
        header={{
          title:
            mode === 'edit'
              ? `Edit source of ${selectedApplication?.name} app`
              : mode === 'view'
                ? selectedApplication?.name
                : `Connect new source to ${selectedApplication?.name} app`,
        }}
        open={isOpen}
        setOpen={() => setIsOpen?.(false)}
      >
        {isLoadingSourceData ? (
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ height: '100%' }}
          >
            <CircularProgress />
          </Stack>
        ) : (
          <></>
        )}
        {isLoadingSourceData ? (
          <></>
        ) : (
          <Stack
            direction={'column'}
            alignItems={'stretch'}
            flex={1}
            height={'100%'}
          >
            <Stack gap={2}>
              {props.showApplicationSelector ? (
                <Box>
                  <TextFieldTitle title="Application" />
                  <FormControl fullWidth size="small">
                    <InputLabel
                      shrink={false}
                      disabled
                      disableAnimation
                      sx={{ lineHeight: 1.3 }}
                    >
                      {selectedApplication ? '' : 'Select...'}
                    </InputLabel>
                    <Select
                      disableUnderline
                      value={selectedApplication?.app_id || ''}
                      onChange={(v) => {
                        // if (v.target.value === 'add_new') {
                        //   track(
                        //     'web_app_run_scan_drawer_add_new_application_button_click',
                        //   );
                        //   setShowCreateAppDrawer(true);
                        //   return;
                        // }
                        setSelectedApplication(
                          applications?.find(
                            (a) => a.app_id === v.target.value,
                          ),
                        );
                      }}
                    >
                      {/* <MenuItem
              value={'add_new'}
              color={BRAND_ORANGE}
              sx={{ color: BRAND_ORANGE }}
            >
              + add new application
            </MenuItem> */}
                      {applications?.map((option) => (
                        <MenuItem key={option.app_id} value={option.app_id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              ) : (
                <></>
              )}
              <EditSourceForm
                data={data}
                onChanged={(data) => setData(data)}
                disabled={mode === 'edit' || mode === 'view'}
              />
            </Stack>
            <Box sx={BOTTOM_SPACER} />
            {mode === 'view' ? (
              <Stack
                direction={'row'}
                justifyContent={'flex-start'}
                spacing={1}
              >
                {hasDeleteSourceFeature ? (
                  <LoadingButton
                    variant="outlined"
                    onClick={onDeleteClick}
                    loading={deletingSource}
                    color="error"
                  >
                    Delete Source
                  </LoadingButton>
                ) : (
                  <></>
                )}
              </Stack>
            ) : (
              <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
                <Button variant="text" onClick={() => setIsOpen?.(false)}>
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  onClick={onSaveClick}
                  loading={isLoadingSave}
                  disabled={mode === 'edit'}
                >
                  Save
                </LoadingButton>
              </Stack>
            )}
          </Stack>
        )}
      </CustomDrawer>
    </>
  );
};

export default EditSrouceDrawer;
