import { AuthUser } from '../models/authUser';
import { get, put } from './BaseService';

export async function getUser() {
  const res = await get('/user/me');

  return res.data;
}

interface CreateUserResponse {
  new_organization_created: boolean;
}

export async function createUser() {
  const res = await put<CreateUserResponse>('/user', {});

  return res.data;
}

export async function exchangeOAuthToken(code: string, redirectUri: string) {
  const res = await get('/auth/login', {
    params: { code, redirect_uri: redirectUri },
  });

  return res.data;
}

export async function getAuthUser(token: string): Promise<AuthUser> {
  const res = await get('/user/me/oauth', {
    headers: { Authorization: 'Bearer ' + token },
  });

  return res.data;
}

export async function discoverSSO(email: string): Promise<string> {
  const res = await get<string>('/sso/discover', {
    params: { email },
  });
  return res.data;
}

export async function storeDeviceCode(
  deviceCode: string,
  authCode: string,
  redirectUri: string,
): Promise<void> {
  await put(`/auth/device-code/${deviceCode}`, {
    authorization_code: authCode,
    redirect_uri: redirectUri,
  });
}
