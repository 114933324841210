import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Collapse,
  debounce,
  Stack,
  styled,
  SxProps,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import * as Sentry from '@sentry/react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { allExpanded, defaultStyles, JsonView } from 'react-json-view-lite';
import { useNavigate } from 'react-router-dom';
import 'react-json-view-lite/dist/index.css';

import owaspIcon from '../../assets/images/owasp.png';
import { AuthContext } from '../../contexts/Auth';
import {
  IntegrationsContext,
  IntegrationsContextProps,
} from '../../contexts/Integrations';
import { SnackbarContext } from '../../contexts/Snackbar';
import { UserContext, UserContextProps } from '../../contexts/User';
import {
  DisplayedVulnerability,
  statusToTitle,
  VulnerabilityInstanceStatus,
  VulnerableEndpoint,
} from '../../models/vulnerability';
import { getEvidence } from '../../services/VulnerabilitiesService';
import { track } from '../../utils/analytics';
import {
  APIMethodChip,
  CustomDrawer,
  JiraTicketingBadge,
  TextFieldTitle,
} from '../Common';
import { JiraTicketForm } from '../Ticketing/JiraTicketForm';
import TestCategoryChip from './TestCategoryChip';
import {
  createTicket,
  setVulnerabilityTags,
  updateVulnerabilityStatus,
} from '../../services/VulnerabilityInstancesService';

const GRAY_TEXT_BOX: SxProps = {
  bgcolor: '#F2F2F2',
  border: '1px solid #D3D3D3',
  p: 1.5,
  borderRadius: 0.5,
  fontSize: 12,
  lineHeight: 2,
  wordBreak: 'break-word',
  overflowX: 'scroll',
};

const BUTTONS_CONTAINER: SxProps = {
  borderTop: '1px solid #D3D3D3',
  pt: 2,
  px: 2,
};

const STEP_FORM_CONTAINER: SxProps = {
  py: 2,
  px: 3,
  pb: 2,
  mb: 4,
  bgcolor: '#0057FF0F',
  borderRadius: 1,
};

const TABS_UNDERLINE: SxProps = {
  mt: '-0.75px',
  mx: 2,
  height: '1px',
  width: 'calc(100%-16px)',
  bgcolor: '#D3D3D3',
};

const OVERVIEW_ITEM_TITLE: SxProps = {
  color: '#7B7B88',
  fontSize: 12,
};

interface Props {
  vulnerability?: DisplayedVulnerability;
  close: () => void;
  onUpdated: () => void;
}

const VulnerabilityDrawer = ({ vulnerability, close, onUpdated }: Props) => {
  const navigate = useNavigate();
  const isOpen = vulnerability !== undefined;

  const { show } = useContext(SnackbarContext);
  const { user } = useContext(AuthContext);
  const { activeJiraIntegration } = useContext(
    IntegrationsContext,
  ) as IntegrationsContextProps;

  const [selectedTab, setSelectedTab] = useState({
    id: 'overview',
    title: 'Overview',
  });
  const [step, setStep] = useState<'mark-false-positive' | 'open-ticket'>();
  const [stepTouched, setStepTouched] = useState(false);
  const [stepData, setStepData] = useState<Record<string, any>>({});
  const [stepLoading, setStepLoading] = useState(false);

  const isAdmin = useMemo(() => user?.role === 'Admin', [user]);

  const jiraTicket = useMemo(() => vulnerability?.ticket, [vulnerability]);
  const firstEndpoint = vulnerability?.vulnerable_endpoints[0];
  const hasEvidence = firstEndpoint && firstEndpoint.evidencePaths.length > 0;
  const hasCurlCmd = firstEndpoint && firstEndpoint.curlCmd !== null;

  const tabs = useMemo(() => {
    return [
      { id: 'overview', title: 'Overview' },
      ...(hasEvidence ? [{ id: 'evidence', title: 'Evidence' }] : []),
      ...(hasCurlCmd ? [{ id: 'reproduce', title: 'Reproduce via Curl' }] : []),
    ];
  }, [vulnerability]);

  useEffect(() => {
    if (isOpen) return;

    setSelectedTab(tabs[0]);
    setStep(undefined);
    setStepTouched(false);
  }, [isOpen, tabs]);

  const onMarkFalsePositiveClick = useCallback(() => {
    setStepData({});
    setStep('mark-false-positive');
    setStepTouched(true);
  }, []);

  const onOpenTicketClick = useCallback(() => {
    setStepData({});
    setStep('open-ticket');
    setStepTouched(true);
  }, []);

  const onSubmitFalsePositive = useCallback(() => {
    if (!vulnerability) return;

    track(
      'web_app_vulnerability_drawer_mark_false_positive_submit_button_click',
      {
        vulnerabilityId: vulnerability.id,
        markAs:
          vulnerability.status === 'detected' ? 'false_positive' : 'detected',
      },
    );
    setStepLoading(true);

    const markFalsePositive =
      vulnerability.status === VulnerabilityInstanceStatus.DETECTED;
    let newStatus = VulnerabilityInstanceStatus.FALSE_POSITIVE_CLAIMED;
    if (isAdmin) {
      newStatus = markFalsePositive
        ? VulnerabilityInstanceStatus.WAIVED
        : VulnerabilityInstanceStatus.DETECTED;
    }
    updateVulnerabilityStatus(vulnerability.id, newStatus)
      .then(() => {
        if (isAdmin) {
          if (markFalsePositive) {
            show('Vulnerability marked as false positive', 'success');
          } else {
            show('Vulnerability marked as not false positive', 'success');
          }
        } else {
          show('Request to mark vulnerability as false positive sent', 'info');
        }

        onUpdated();
        setStep(undefined);
      })
      .catch((e) => {
        show('Failed to mark vulnerability as false positive', 'error');
        Sentry.captureException(e);
      })
      .finally(() => {
        setStepLoading(false);
      });
  }, [vulnerability, isAdmin]);

  const onSubmitCreateTicket = useCallback(() => {
    if (!vulnerability || activeJiraIntegration === null) return;

    track(
      'web_app_vulnerability_instance_drawer_create_ticket_submit_button_click',
      {
        vulnerabilityInstanceId: vulnerability.id,
      },
    );
    setStepLoading(true);

    createTicket(
      vulnerability.id,
      activeJiraIntegration.integration_id,
      stepData.projectId,
      stepData.issueType,
      stepData.name,
      stepData.description,
    )
      .then(async () => {
        show('Ticket created', 'success');

        onUpdated();
        setStepData({});
        setStep(undefined);
      })
      .catch((e) => {
        show('Failed to create ticket, ' + e.message, 'error');
        Sentry.captureException(e);
      })
      .finally(() => {
        setStepLoading(false);
      });
  }, [vulnerability, activeJiraIntegration, isAdmin, stepData]);

  return (
    <CustomDrawer
      sx={{
        width: step !== undefined ? '1001px' : '501px',
        transition:
          isOpen && stepTouched
            ? 'width 400ms ease-in-out, transform 2s cubic-bezier(0, 0, 0.2, 1) 0ms !important'
            : undefined,
      }}
      header={{
        title: vulnerability?.name,
        hideDivider: true,
      }}
      open={isOpen}
      setOpen={close}
    >
      {vulnerability && (
        <Stack
          overflow={'hidden'}
          flex={1}
          spacing={2}
          mx={-2}
          key={vulnerability.id}
        >
          <Stack direction={'row'} flex={1} overflow={'hidden'}>
            <Stack overflow={'hidden'} gap={2} flexShrink={0} width={500}>
              <Box>
                <Tabs
                  sx={{
                    px: 2,
                  }}
                  value={selectedTab.id}
                  onChange={(_, t) => {
                    if (selectedTab.id === t) return;
                    setSelectedTab(tabs.find((_t) => _t.id === t) ?? tabs[0]);
                  }}
                >
                  {tabs.map((t, i) => (
                    <Tab
                      sx={{ pl: 0, pr: 0, ml: i !== 0 ? 2 : 0 }}
                      key={t.id}
                      value={t.id}
                      label={t.title}
                    />
                  ))}
                </Tabs>
                <Box sx={TABS_UNDERLINE}></Box>
              </Box>
              <Box flex={1} overflow={'scroll'} px={2}>
                {selectedTab.id === 'overview' && (
                  <VulnerabilityOverview
                    vulnerability={vulnerability}
                    onUpdated={onUpdated}
                  />
                )}
                {selectedTab.id === 'evidence' && (
                  <VulnerabilityEvidence
                    scanId={vulnerability.vulnerable_endpoints[0].scanId}
                    evidencePaths={
                      vulnerability.vulnerable_endpoints[0].evidencePaths
                    }
                    isLLM={vulnerability.isLLM}
                  />
                )}
                {selectedTab.id === 'reproduce' && (
                  <Stack gap={2}>
                    <Stack gap={0.5}>
                      <Typography>Reproduce via Curl</Typography>
                      <Typography sx={GRAY_TEXT_BOX}>
                        {vulnerability.vulnerable_endpoints[0].curlCmd}
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Box>
            </Stack>

            <Collapse
              key={'mark-false-positive-step'}
              in={step === 'mark-false-positive'}
              orientation="horizontal"
              collapsedSize={'500px'}
              hidden={step && step !== 'mark-false-positive'}
              sx={{
                width: '500px',
                pointerEvents: step !== 'mark-false-positive' ? 'none' : 'auto',
                opacity: step !== 'mark-false-positive' ? 0 : 1,
                transition: 'width, opacity 400ms ease-in-out',
                overflowX: 'hidden',
                overflowY: 'auto',
              }}
            >
              <Stack
                width={'500px'}
                paddingInlineEnd={3}
                paddingInlineStart={2}
              >
                <Box sx={STEP_FORM_CONTAINER}>
                  <Stack gap={2}>
                    <Typography variant="h5" fontWeight={500}>
                      {vulnerability.status === 'detected'
                        ? 'Mark Vulnerability as False Positive'
                        : 'Mark Vulnerability as not False Positive'}
                    </Typography>
                    <Box>
                      <TextFieldTitle title="Reason" />
                      <TextField
                        placeholder="Enter your reason for this change here..."
                        fullWidth
                        variant="filled"
                        size="small"
                        rows={5}
                        multiline
                        onChange={() => {}}
                        disabled={stepLoading || step !== 'mark-false-positive'}
                      />
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Collapse>

            <Collapse
              key={'open-ticket-step'}
              in={step === 'open-ticket'}
              hidden={step && step !== 'open-ticket'}
              orientation="horizontal"
              collapsedSize={'500px'}
              sx={{
                width: '500px',
                opacity: step !== 'open-ticket' ? 0 : 1,
                transition: 'width, opacity 400ms ease-in-out',
                overflowX: 'hidden',
                overflowY: 'auto',
              }}
            >
              <Stack
                width={'500px'}
                paddingInlineEnd={3}
                paddingInlineStart={2}
              >
                <Box sx={STEP_FORM_CONTAINER}>
                  <JiraTicketForm
                    ticketVulnerabilityInformation={{
                      name: vulnerability.name,
                      endpoint: vulnerability.vulnerable_endpoints[0].endpoint,
                      category: vulnerability.owasp_category,
                      description: vulnerability.description,
                      remediation: vulnerability.remediation,
                      curl_cmd: vulnerability.vulnerable_endpoints[0].curlCmd,
                      link: vulnerability.applicationId
                        ? `https://app.pynt.io/dashboard/application/${vulnerability.applicationId}?tab=vulnerabilities&vulnerabilityId=${vulnerability.id}`
                        : `https://app.pynt.io/dashboard/vulnerabilities/${vulnerability.id}`,
                    }}
                    disabled={stepLoading || step !== 'open-ticket'}
                    data={stepData}
                    onChange={setStepData}
                  />
                </Box>
              </Stack>
            </Collapse>
          </Stack>
          <Stack
            direction={'row'}
            gap={1}
            justifyContent={'end'}
            sx={BUTTONS_CONTAINER}
          >
            {step === 'mark-false-positive' ? (
              <>
                <Button
                  key={'cancel-mark-false-positive'}
                  variant="text"
                  disabled={stepLoading}
                  onClick={() => setStep(undefined)}
                >
                  Cancel
                </Button>
                <LoadingButton
                  key={'submit-mark-false-positive'}
                  variant="contained"
                  loading={stepLoading}
                  onClick={() => onSubmitFalsePositive()}
                >
                  Submit
                </LoadingButton>
              </>
            ) : (
              <></>
            )}

            {step == 'open-ticket' ? (
              <>
                <Button
                  key={'cancel-open-ticket'}
                  variant="text"
                  disabled={stepLoading}
                  onClick={() => setStep(undefined)}
                >
                  Cancel
                </Button>
                <LoadingButton
                  key={'submit-open-ticket'}
                  variant="contained"
                  loading={stepLoading}
                  onClick={() => onSubmitCreateTicket()}
                >
                  Save Ticket
                </LoadingButton>
              </>
            ) : (
              <></>
            )}

            {!step ? (
              <>
                {vulnerability.owasp_link ? (
                  <Button
                    key={'owasp-link'}
                    variant="outlined"
                    startIcon={<img src={owaspIcon} height={20} width={20} />}
                    onClick={() => {
                      track(
                        'web_app_vulnerability_drawer_view_owasp_button_click',
                      );
                      window.open(vulnerability.owasp_link, '_blank');
                    }}
                  >
                    View OWASP
                  </Button>
                ) : (
                  <></>
                )}
                {!jiraTicket ? (
                  <Tooltip
                    title={
                      activeJiraIntegration !== null ? (
                        'Open Jira Ticket'
                      ) : (
                        <Typography>
                          To open Jira tickets first add the Jira integration{' '}
                          <Button
                            sx={{ p: 0, minWidth: 0 }}
                            onClick={() => {
                              navigate(
                                '/dashboard/settings/integrations?type=Ticketing+Systems',
                              );
                            }}
                          >
                            here
                          </Button>
                          .
                        </Typography>
                      )
                    }
                  >
                    <Box>
                      <Button
                        key={'open-ticket'}
                        variant="contained"
                        disabled={activeJiraIntegration === null}
                        onClick={() => onOpenTicketClick()}
                      >
                        Open Ticket
                      </Button>
                    </Box>
                  </Tooltip>
                ) : (
                  <></>
                )}

                {(isAdmin &&
                  vulnerability.status !==
                    VulnerabilityInstanceStatus.FALSE_POSITIVE_CLAIMED) ||
                vulnerability.status ===
                  VulnerabilityInstanceStatus.DETECTED ? (
                  <Tooltip
                    arrow
                    title={
                      vulnerability.status ===
                      VulnerabilityInstanceStatus.DETECTED
                        ? 'Mark the vulnerability as False Positive'
                        : 'Change the vulnerability status back to Detected'
                    }
                  >
                    <Button
                      key={'mark-false-positive'}
                      variant="contained"
                      sx={{ px: 1 }}
                      onClick={() => onMarkFalsePositiveClick()}
                    >
                      {vulnerability.status ===
                      VulnerabilityInstanceStatus.DETECTED
                        ? 'Mark as False Positive'
                        : 'Mark as Valid'}
                    </Button>
                  </Tooltip>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </Stack>
        </Stack>
      )}
    </CustomDrawer>
  );
};

const SingleEndpoint = ({
  vulnerableEndpoint,
}: {
  vulnerableEndpoint: VulnerableEndpoint;
}) => {
  const [method, endpoint] = vulnerableEndpoint.endpoint.split(' ');

  return (
    <>
      <Stack
        direction={'row'}
        gap={1}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography sx={OVERVIEW_ITEM_TITLE}>Endpoint</Typography>
        <Stack direction={'row'} gap={1} alignItems={'center'}>
          <Box>
            <APIMethodChip method={method} />
          </Box>
          <Typography
            textOverflow={'ellipsis'}
            overflow={'hidden'}
            color="#050D21"
            fontSize={12}
          >
            {endpoint}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

const NoBorderAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&': {
    border: 0,
    boxShadow: 'none',
  },
  '&::before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-root, .MuiAccordionDetails-root': {
    padding: 0,
    minHeight: 0,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}));

const EndpointsOverview = ({
  vulnerableEndpoints,
}: {
  vulnerableEndpoints: DisplayedVulnerability['vulnerable_endpoints'];
}) => {
  if (vulnerableEndpoints.length === 1) {
    return <SingleEndpoint vulnerableEndpoint={vulnerableEndpoints[0]} />;
  }

  return (
    <NoBorderAccordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={OVERVIEW_ITEM_TITLE}>
          Seen in {vulnerableEndpoints.length} Endpoints
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ textAlign: 'end' }}>
        {vulnerableEndpoints.map((vulnerableEndpoint) => (
          <Typography key={vulnerableEndpoint.endpoint} fontSize={12}>
            {vulnerableEndpoint.endpoint}
          </Typography>
        ))}
      </AccordionDetails>
    </NoBorderAccordion>
  );
};

const VulnerabilityOverview = ({
  vulnerability,
  onUpdated,
}: {
  vulnerability: DisplayedVulnerability;
  onUpdated: () => void;
}) => {
  const { show } = useContext(SnackbarContext);
  const { user } = useContext(AuthContext);
  const { tags, fetchTags } = useContext(UserContext) as UserContextProps;
  const isAdmin = useMemo(() => user?.role === 'Admin', [user]);

  const updateTags = useCallback(
    async (tags: string[]) => {
      if (!vulnerability) return;
      await setVulnerabilityTags(vulnerability.id, tags).catch(() => {
        show('Failed to update tags');
      });
      await fetchTags();
      onUpdated();
    },
    [vulnerability, fetchTags],
  );
  const updateTagsDelayed = useMemo(
    () => debounce(updateTags, 300),
    [updateTags],
  );

  const jiraTicket = useMemo(() => vulnerability.ticket, [vulnerability]);

  return (
    <Stack gap={2}>
      <Stack
        direction={'row'}
        gap={1}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography sx={OVERVIEW_ITEM_TITLE}>Status</Typography>
        <Typography color="#ACACBB" fontSize={12}>
          {statusToTitle(vulnerability.status)}
        </Typography>
      </Stack>

      <Stack
        direction={'row'}
        gap={1}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography flexShrink={0} sx={OVERVIEW_ITEM_TITLE}>
          Category
        </Typography>
        <TestCategoryChip category={vulnerability.owasp_category} />
      </Stack>

      <Stack
        direction={'row'}
        gap={1}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography flexShrink={0} sx={OVERVIEW_ITEM_TITLE}>
          Host
        </Typography>
        <Typography fontSize={12}>{vulnerability.host}</Typography>
      </Stack>

      <EndpointsOverview
        vulnerableEndpoints={vulnerability.vulnerable_endpoints}
      />

      <Stack
        direction={'row'}
        gap={1}
        justifyContent={'space-between'}
        alignItems={'baseline'}
      >
        <Typography sx={OVERVIEW_ITEM_TITLE}>Labels</Typography>
        <Stack direction={'row'} gap={1} alignItems={'center'}>
          <Autocomplete
            multiple
            freeSolo
            size="small"
            id="tags-standard"
            options={tags?.map((t) => t.name) || []}
            defaultValue={vulnerability.tags}
            sx={{ width: 350 }}
            renderInput={(params) => <TextField {...params} fullWidth />}
            onChange={(e, v) => {
              updateTagsDelayed(v);
            }}
          />
        </Stack>
      </Stack>

      {jiraTicket && (
        <Stack
          direction={'row'}
          gap={1}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography sx={OVERVIEW_ITEM_TITLE}>Ticket</Typography>
          <JiraTicketingBadge ticket={jiraTicket} />
        </Stack>
      )}

      <Stack gap={0.5}>
        <Typography>Description (What we found?)</Typography>
        <Typography sx={GRAY_TEXT_BOX}>{vulnerability.description}</Typography>
      </Stack>

      <Stack gap={0.5}>
        <Typography>Remediation (How to fix it)</Typography>
        <Typography sx={GRAY_TEXT_BOX}>{vulnerability.remediation}</Typography>
      </Stack>

      {vulnerability.status === 'false_positive_claimed' && isAdmin ? (
        <FalsePositiveApprovalCard
          vulnerability={vulnerability}
          onUpdated={onUpdated}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
};

type VulnerabilityEvidenceJSON = {
  type: 'json';
  data: any;
};

type VulnerabilityEvidenceFile = {
  type: 'file';
  name: string;
  blobUrl: string;
};

type VulnerabilityEvidence =
  | VulnerabilityEvidenceJSON
  | VulnerabilityEvidenceFile;

const VulnerabilityEvidence = ({
  scanId,
  evidencePaths,
  isLLM,
}: {
  scanId: string;
  evidencePaths: string[];
  isLLM: boolean;
}) => {
  const [evidence, setEvidence] = useState<VulnerabilityEvidence[]>([]);
  const [error, setError] = useState();

  useEffect(() => {
    setError(undefined);
    setEvidence([]);

    const evidenceLoading = evidencePaths.map(async (fileName) => {
      const blob = await getEvidence(scanId, fileName);

      if (!fileName.includes('.') || fileName.endsWith('.json')) {
        const data = await blob.text();
        return {
          type: 'json',
          data: JSON.parse(data),
        } as VulnerabilityEvidenceJSON;
      }

      const blobUrl = URL.createObjectURL(blob);
      return {
        type: 'file',
        name: fileName,
        blobUrl,
      } as VulnerabilityEvidenceFile;
    });

    Promise.allSettled(evidenceLoading)
      .then((res) => {
        if (!res.some((a) => a.status === 'fulfilled')) {
          throw (res[0] as any).reason;
        }
        setEvidence(
          res
            .map((e) => e.status === 'fulfilled' && e.value)
            .filter((a) => !!a) as VulnerabilityEvidence[],
        );
      })
      .catch((e) => {
        console.error(e);
        setError(e || 'failed to get evidence');
        Sentry.captureException(e);
      });
  }, [scanId, evidencePaths, setEvidence]);

  const jsonEvidencesData = useMemo(
    () =>
      evidence
        .filter((e): e is VulnerabilityEvidenceJSON => e.type === 'json')
        .map((e) => e.data),
    [evidence],
  );
  const fileEvidences = useMemo(
    () =>
      evidence.filter((e): e is VulnerabilityEvidenceFile => e.type === 'file'),
    [evidence],
  );

  if (!evidence.length) {
    if (error) {
      return (
        <Stack alignItems={'center'}>
          <Typography color={'red'}>Error getting evidence</Typography>
        </Stack>
      );
    }
    return (
      <Stack alignItems={'center'}>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack gap={2}>
      {isLLM && <LLMEvidenceDisplay evidence={jsonEvidencesData} />}
      <Stack gap={0.5}>
        <Typography>Evidence</Typography>
        {jsonEvidencesData.length > 0 && (
          <Box sx={GRAY_TEXT_BOX}>
            <React.Fragment>
              <JsonView
                data={jsonEvidencesData}
                shouldExpandNode={allExpanded}
                style={{ ...defaultStyles, container: '' }}
              />
            </React.Fragment>
          </Box>
        )}
        {fileEvidences.map((e) => (
          <img
            key={e.name}
            style={{ cursor: 'pointer' }}
            src={e.blobUrl}
            onClick={() => {
              window.open(e.blobUrl, '_blank', 'noopener,noreferrer');
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};

const PROMPT_MESSAGE_BOX: SxProps = {
  bgcolor: '#fff',
  border: '1px solid #D3D3D3',
  p: 1.5,
  borderRadius: 2,
  fontSize: 12,
  lineHeight: 2,
  wordBreak: 'break-word',
  maxWidth: '90%',
};

const LLMEvidenceDisplay = ({ evidence }: { evidence: any[] }) => {
  const conversation = useMemo(() => {
    return evidence
      ?.map((e) => {
        try {
          return {
            request: e?.request?.body,
            response: e?.response?.body,
          };
        } catch (e) {
          return null;
        }
      })
      .filter((a) => !!a)
      .map((a) => a as any);
  }, [evidence]);

  return (
    <Stack gap={0.5}>
      <Typography>LLM Attacker</Typography>
      <Stack sx={GRAY_TEXT_BOX} spacing={1.5}>
        {conversation?.map((item) => (
          <Stack key={JSON.stringify(item)} spacing={1}>
            <Stack direction={'row'} justifyContent={'end'} width={'100%'}>
              <Box sx={[PROMPT_MESSAGE_BOX, { bgcolor: '#E1FFD4' }]}>
                {item.request || '[EMPTY MESSAGE]'}
              </Box>
            </Stack>
            <Box sx={PROMPT_MESSAGE_BOX}>{item.response}</Box>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

const FalsePositiveApprovalCard = ({
  vulnerability,
  onUpdated,
}: {
  vulnerability: DisplayedVulnerability;
  onUpdated: () => void;
}) => {
  const { show } = useContext(SnackbarContext);
  const [loading, setLoading] = useState<{ accept: boolean }>();

  const onSubmitFalsePositive = useCallback(
    (accept: boolean) => {
      track(
        'web_app_vulnerability_drawer_mark_false_positive_approve_reject_button_click',
        {
          accept,
        },
      );
      setLoading({ accept });

      updateVulnerabilityStatus(
        vulnerability.id,
        accept
          ? VulnerabilityInstanceStatus.WAIVED
          : VulnerabilityInstanceStatus.DETECTED,
      )
        .then(() => {
          if (accept) {
            show('Vulnerability marked as false positive', 'success');
          } else {
            show(
              'Request to mark vulnerability as false positive rejected',
              'info',
            );
          }

          onUpdated();
        })
        .catch((e) => {
          show('Failed to update vulnerability', 'error');
          Sentry.captureException(e);
        })
        .finally(() => {
          setLoading(undefined);
        });
    },
    [vulnerability, show],
  );

  return (
    <Box sx={STEP_FORM_CONTAINER}>
      <Stack gap={2}>
        <Typography variant="h6" fontWeight={500}>
          Vulnerability was marked as false positive
        </Typography>
        <Stack direction={'row'} gap={1} justifyContent={'end'}>
          <LoadingButton
            variant="outlined"
            disabled={!!loading}
            loading={loading && !loading.accept}
            onClick={() => onSubmitFalsePositive(false)}
          >
            Reject
          </LoadingButton>
          <LoadingButton
            variant="contained"
            disabled={!!loading}
            loading={loading && loading.accept}
            onClick={() => onSubmitFalsePositive(true)}
          >
            Approve
          </LoadingButton>
        </Stack>
      </Stack>
    </Box>
  );
};

export default VulnerabilityDrawer;
