import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import {
  GridFilterModel,
  GridFilterOperator,
  GridPaginationModel,
  GridRowSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import * as Sentry from '@sentry/react';
import { Box, Stack, SxProps, Tooltip, Typography } from '@mui/material';

import { ChevronRightIcon } from '../../assets/svgs/icons/ChevronRightIcon';
import {
  DisplayedVulnerability,
  VulnerabilitySeverity,
} from '../../models/vulnerability';
import { APIMethodChip, JiraTicketingBadge, PyntDataGrid } from '../Common';
import RiskScoreChip, { normalizeRiskScore } from '../APICatalog/RiskScoreChip';
import { fetchVulnerabilities } from '../../services/VulnerabilityInstancesService';
import { Application } from '../../models/application';
import { PyntFilter } from '../../services/BaseService';
import { reformatFilterModel, reformatSortModel } from '../../utils/datagrid';
import VulnerabilityDrawer from './VulnerabilityDrawer';
import {
  IntegrationsContext,
  IntegrationsContextProps,
} from '../../contexts/Integrations';

type LoadState = 'initial' | 'loading' | 'error' | 'done';

const CENTER: SxProps = {
  width: '100%',
  height: '100%',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
};

const TOOLTIP_AVAILABLE: SxProps = {
  fontStyle: 'italic',
  textDecoration: 'underline',
};

const NoResultsFound = ({ loadState }: { loadState: LoadState }) => {
  if (loadState === 'error') {
    return (
      <Box sx={CENTER}>
        <p>
          We encountered an issue displaying the vulnerabilities. Our engineers
          have been notified.
        </p>
        <p>Please check again soon.</p>
      </Box>
    );
  }
  return <Box sx={CENTER}>No vulnerabilities found.</Box>;
};

const VulnerableEndpointsCell = ({
  vulnerableEndpoints,
}: {
  vulnerableEndpoints: DisplayedVulnerability['vulnerable_endpoints'];
}) => {
  if (vulnerableEndpoints.length === 0) {
    return <Box>None</Box>;
  }
  if (vulnerableEndpoints.length === 1) {
    const endpoint = vulnerableEndpoints[0].endpoint;
    const [method, path] = endpoint.split(' ');
    return (
      <Tooltip title={endpoint}>
        <Stack direction={'row'} gap={2} width={'100%'}>
          <Box>
            <APIMethodChip method={method} />
          </Box>
          <Typography textOverflow={'ellipsis'} overflow={'hidden'}>
            {path}
          </Typography>
        </Stack>
      </Tooltip>
    );
  }

  const endpointsToShow = vulnerableEndpoints.slice(0, 10);
  const additionalEndpoints = vulnerableEndpoints.length - 10;

  return (
    <Tooltip
      title={
        <>
          {endpointsToShow.map((vulnerableEndpoint) => (
            <div key={vulnerableEndpoint.endpoint}>
              {vulnerableEndpoint.endpoint}
            </div>
          ))}
          {additionalEndpoints > 0 && (
            <div>... and {additionalEndpoints} more</div>
          )}
        </>
      }
      arrow
    >
      <Box sx={TOOLTIP_AVAILABLE}>{vulnerableEndpoints.length} endpoints</Box>
    </Tooltip>
  );
};

interface Props {
  applications: Application[];
  filter: {
    applicationId?: string;
    status?: string;
  };
  navigateToVulnerabilityId: string | undefined;
  navigateFn: (vulnerabilityId: string | undefined) => void;
  singleAppMode: boolean;
}

const existenceFilterOperators: GridFilterOperator[] = [
  {
    label: 'Exists',
    value: 'exists',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value) {
        return null;
      }
      return () => true;
    },
  },
  {
    label: 'Does not exist',
    value: 'notExists',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value) {
        return null;
      }
      return () => false;
    },
  },
];

export default function VulnerabilitiesGrid({
  applications,
  filter: filterProp,
  navigateToVulnerabilityId,
  navigateFn,
  singleAppMode,
}: Props) {
  const { activeJiraIntegration } = useContext(
    IntegrationsContext,
  ) as IntegrationsContextProps;
  const [data, setData] = useState<{
    vulnerabilities: DisplayedVulnerability[];
    total: number;
    loadState: LoadState;
  }>({ vulnerabilities: [], total: 0, loadState: 'initial' });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'severity', sort: 'desc' },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });
  const [selectedVulnerability, setSelectedVulnerabilityState] =
    useState<DisplayedVulnerability>();
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const indexedApplications = useMemo(() => {
    const indexedApps: Record<string, string> = {};
    applications.forEach((application) => {
      indexedApps[application.app_id] = application.name;
    });
    return indexedApps;
  }, [applications]);

  const onSelectVulnerability = useCallback(
    (vulnerabilityId: string | undefined) => {
      if (selectedVulnerability?.id === vulnerabilityId) {
        return;
      }
      navigateFn(vulnerabilityId);
    },
    [selectedVulnerability, navigateFn],
  );

  const fetch = useCallback(async () => {
    if (data.loadState === 'loading') return;

    setData((prev) => ({ ...prev, loadState: 'loading' }));

    const whereFilter = { ...reformatFilterModel(filterModel, columns) };
    if (filterProp?.applicationId) {
      whereFilter.application_id = filterProp.applicationId;
    }
    if (filterProp?.status) {
      whereFilter.status = filterProp.status;
    }

    const filter: PyntFilter = {
      where: whereFilter,
      sort: reformatSortModel(sortModel, columns),
      offset: paginationModel.page * paginationModel.pageSize,
      limit: paginationModel.pageSize,
    };

    try {
      const { vulnerabilities, total } = await fetchVulnerabilities(filter);
      setData({
        vulnerabilities,
        total,
        loadState: 'done',
      });
    } catch (e) {
      Sentry.captureException(e);
      setData({
        vulnerabilities: [],
        total: 0,
        loadState: 'error',
      });
    }
  }, [setData, filterModel, sortModel, paginationModel, filterProp]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    const selectedVulnerability = data.vulnerabilities.find(
      ({ id }) => id === navigateToVulnerabilityId,
    );
    setSelectedVulnerabilityState(selectedVulnerability);
    setRowSelectionModel(
      selectedVulnerability ? [selectedVulnerability.id] : [],
    );
  }, [
    data,
    navigateToVulnerabilityId,
    setSelectedVulnerabilityState,
    setRowSelectionModel,
  ]);

  const columns: GridColDef<DisplayedVulnerability>[] = useMemo(
    () => [
      {
        field: 'severity',
        headerName: 'Severity',
        type: 'singleSelect',
        valueOptions: [
          { value: VulnerabilitySeverity.CRITICAL, label: 'Critical' },
          { value: VulnerabilitySeverity.HIGH, label: 'High' },
          { value: VulnerabilitySeverity.MEDIUM, label: 'Medium' },
          { value: VulnerabilitySeverity.LOW, label: 'Low' },
          { value: VulnerabilitySeverity.INFO, label: 'Info' },
        ],
        renderCell: ({ value }) => (
          <Box>
            <RiskScoreChip score={normalizeRiskScore(value) ?? 'N/A'} />
          </Box>
        ),
      },
      {
        field: 'owasp_category',
        headerName: 'Category',
        filterable: false,
        sortable: false,
        width: 280,
      },
      {
        field: 'name',
        headerName: 'Test Name',
        filterable: false,
        sortable: false,
        width: 250,
      },
      {
        field: 'host',
        headerName: 'Host',
        filterField: 'scope_info.endpoint_attacks_info.host',
        width: 150,
      },
      {
        field: 'vulnerable_endpoints',
        headerName: 'Endpoint(s)',
        filterable: false,
        sortable: false,
        width: 180,
        renderCell: ({ value }) => (
          <VulnerableEndpointsCell vulnerableEndpoints={value} />
        ),
      },
      ...(!singleAppMode
        ? [
            {
              field: 'applicationId',
              headerName: 'Application',
              filterable: false, // filtered in the outer screen
              valueGetter: ({ value }) => indexedApplications[value],
            } as GridColDef<DisplayedVulnerability>,
          ]
        : []),
      {
        field: 'status',
        headerName: 'Status',
        filterable: false, // filtered in the outer screen
      },
      ...(activeJiraIntegration !== null
        ? [
            {
              field: 'ticket',
              headerName: 'Ticket',
              filterField: 'tickets.id',
              type: 'singleSelect',
              valueOptions: [
                { value: true, label: 'Exists' },
                { value: false, label: 'Does not exist' },
              ],
              renderCell: ({ value }) =>
                value ? <JiraTicketingBadge ticket={value} /> : '-',
              filterOperators: existenceFilterOperators,
            } as GridColDef<DisplayedVulnerability>,
          ]
        : []),
      {
        field: '_actions',
        headerName: '',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
        disableExport: true,
        renderCell: () => <ChevronRightIcon />,
      },
    ],
    [singleAppMode, indexedApplications, activeJiraIntegration],
  );

  return (
    <>
      <VulnerabilityDrawer
        vulnerability={selectedVulnerability}
        close={() => onSelectVulnerability(undefined)}
        onUpdated={fetch}
      />
      <PyntDataGrid
        paginationMode="server"
        filterMode="server"
        sortingMode={'server'}
        autoHeight={singleAppMode}
        sortModel={sortModel}
        filterModel={filterModel}
        onSortModelChange={(sortModel) => setSortModel(sortModel)}
        onFilterModelChange={setFilterModel}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[25, 50, 100]}
        pagination
        sx={{ borderRadius: 1 }}
        rows={data.vulnerabilities}
        rowCount={data.total}
        loading={data.loadState === 'loading'}
        columns={columns}
        slots={{
          noRowsOverlay: () => <NoResultsFound loadState={data.loadState} />,
          noResultsOverlay: () => <NoResultsFound loadState={data.loadState} />,
        }}
        disableMultipleRowSelection
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={(selectionModel) =>
          onSelectVulnerability(selectionModel[0] as string)
        }
      />
    </>
  );
}
