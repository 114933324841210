import { useCallback, useContext, useMemo, useState } from 'react';
import { Container } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import {
  DashboardAppBar,
  GridWrapper,
  SelectApplication,
} from '../components/Common';
import VulnerabilitiesGrid from '../components/Vulnerabilities/VulnerabilitiesGrid';
import { UserContext, UserContextProps } from '../contexts/User';
import SelectVulnerabilityStatus from '../components/Common/SelectVulnerabilityStatus';

const Vulnerabilities = () => {
  const { applications: loadedApplications } = useContext(
    UserContext,
  ) as UserContextProps;
  const applications = useMemo(
    () => loadedApplications ?? [],
    [loadedApplications],
  );

  const [selectedApplicationId, setSelectedApplicationId] =
    useState<string>('*');
  const [selectedVulnerabilityStatus, setSelectedVulnerabilityStatus] =
    useState<string>('*');

  const filterProp = useMemo(
    () => ({
      applicationId:
        selectedApplicationId !== '*' ? selectedApplicationId : undefined,
      status:
        selectedVulnerabilityStatus !== '*'
          ? selectedVulnerabilityStatus
          : undefined,
    }),
    [selectedApplicationId, selectedVulnerabilityStatus],
  );

  const { id: vulnerabilityId } = useParams();

  const navigate = useNavigate();
  const navigateFn = useCallback(
    (vulnerabilityId: string | undefined) => {
      if (vulnerabilityId) {
        navigate(vulnerabilityId);
      } else {
        navigate('..', { relative: 'path' });
      }
    },
    [navigate],
  );

  return (
    <>
      <DashboardAppBar
        title={'Vulnerabilities'}
        startActions={
          <SelectApplication
            selectedApplicationId={selectedApplicationId}
            setSelectedApplicationId={setSelectedApplicationId}
          />
        }
        endActions={
          <SelectVulnerabilityStatus
            selectedVulnerabilityStatus={selectedVulnerabilityStatus}
            setSelectedVulnerabilityStatus={setSelectedVulnerabilityStatus}
          />
        }
      />
      <Container maxWidth={false}>
        <GridWrapper>
          <VulnerabilitiesGrid
            applications={applications}
            filter={filterProp}
            navigateToVulnerabilityId={vulnerabilityId}
            navigateFn={navigateFn}
            singleAppMode={false}
          />
        </GridWrapper>
      </Container>
    </>
  );
};

export default Vulnerabilities;
