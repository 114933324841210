import KeyboardArrowDownRounded from '@mui/icons-material/KeyboardArrowDownRounded';
import { Components, Theme } from '@mui/material';

export default function themedMuiSelect(): Pick<
  Components<Theme>,
  'MuiSelect'
> {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDownRounded,
      },
      styleOverrides: {
        filled: {
          borderRadius: 4,
          textAlign: 'start',
          backgroundColor: 'white',
        },
      },
    },
  };
}
