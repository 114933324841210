import * as Sentry from '@sentry/react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Integration } from '../models/integration';
import { getIntegrations } from '../services/IntegrationsService';
import { AuthContext } from './Auth';
import { SnackbarContext } from './Snackbar';

export interface IntegrationsContextProps {
  integrations?: Integration[];
  fetchIntegrations: () => Promise<Integration[]>;
  isLoadingIntegrations: boolean;
  activeJiraIntegration: Integration | null;
}

export const IntegrationsContext =
  createContext<IntegrationsContextProps | null>(null);

function IntegrationsContextProvider(props: any) {
  const { isAuthenticated } = useContext(AuthContext);
  const { showDebug } = useContext(SnackbarContext);

  const [integrations, setIntegrations] = useState<Integration[]>();
  const [isLoadingIntegrations, setIsLoadingIntegrations] = useState(false);

  // reset on logout
  useEffect(() => {
    if (isAuthenticated) {
      fetchIntegrations();
    } else {
      setIntegrations(undefined);
    }
  }, [isAuthenticated]);

  const fetchIntegrations = useMemo(
    () => async () => {
      if (isLoadingIntegrations) return;

      setIsLoadingIntegrations(true);

      const items = await getIntegrations()
        .catch((e) => {
          showDebug(e.toString());
          Sentry.captureException(e);
        })
        .finally(() => {
          setIsLoadingIntegrations(false);
        });
      setIntegrations(items);

      return items;
    },
    [isLoadingIntegrations],
  );

  const activeJiraIntegration = useMemo(
    () =>
      integrations?.find(
        (i) => i.status === 'ACTIVE' && i.integration_type === 'jira',
      ) ?? null,
    [integrations],
  );

  return (
    <IntegrationsContext.Provider
      value={{
        integrations,
        isLoadingIntegrations,
        fetchIntegrations,
        activeJiraIntegration,
      }}
    >
      {props.children}
    </IntegrationsContext.Provider>
  );
}

export default IntegrationsContextProvider;
