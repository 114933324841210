import { get, httpDelete, patch, post } from './BaseService';
import { AxiosError } from 'axios';

export async function getOrgUsers() {
  const res = await get('/user');

  return res.data;
}

export async function getInvites() {
  const res = await get(
    `/invite?filter=${JSON.stringify({ where: { status: 'pending' } })}`,
  );

  return res.data;
}

export async function createInvite(email: string, role?: 'Admin' | 'User') {
  const res = await post('/invite', { email, role });

  return res.data;
}

export async function deleteInvite(inviteId: string) {
  const res = await httpDelete('/invite/' + inviteId);

  return res.data;
}

export async function updateUserRole(userId: string, role: 'Admin' | 'User') {
  const res = await patch(`/user/${userId}`, {
    role,
  });

  return res.data;
}

export async function deleteUser(userId: string) {
  const res = await httpDelete(`/user/${userId}`);

  return res.data;
}

export async function acceptInvite(inviteId: string) {
  const res = await post('/invite/accept?invite_id=' + inviteId, {});

  return res.data;
}

export async function acceptAWSMarketplaceInvite(awsMarketplaceId: string) {
  const res = await post('/marketplace/aws/signup', {
    aws_marketplace_id: awsMarketplaceId,
  });

  return res.data;
}

interface GetSSOResponse {
  identity_provider: string | null;
}

export async function getSSOConfig(): Promise<GetSSOResponse> {
  try {
    const res = await get<GetSSOResponse>('/organization/sso');
    return res.data;
  } catch (error: unknown) {
    const err = error as AxiosError;
    if (err.response?.status === 404) {
      return {
        identity_provider: null,
      };
    }
    throw err;
  }
}
