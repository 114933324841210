import { LoadingButton } from '@mui/lab';
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
  ReactNode,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LightLogo } from '../assets/svgs/LightLogo';
import { SigninBackground } from '../assets/svgs/SigninBackground';
import { GithubIcon } from '../assets/svgs/icons/GithubIcon';
import { GoogleIcon } from '../assets/svgs/icons/GoogleIcon';
import { LinkedInIcon } from '../assets/svgs/icons/LinkedInIcon';
import { AuthContext } from '../contexts/Auth';
import { SnackbarContext } from '../contexts/Snackbar';
import { usePersistedState } from '../hooks/usePersistedState';
import { track } from '../utils/analytics';
import SSOLoginForm from '../components/Common/SSOLoginForm';

const GRID_CONTAINER: SxProps = {
  width: '100vw',
  height: '100vh',
  position: 'fixed',
};

const GRID_LEFT: SxProps = {
  bgcolor: 'secondary.main',
  height: '100vh',
  position: 'relative',
};

const GRID_RIGHT: SxProps = {
  bgcolor: 'white',
  height: '100vh',
};

const LogoContainer: SxProps = {
  position: 'fixed',
  top: 32,
  left: 32,
};

const LOGIN_BOX: SxProps = {
  p: 3,
  textAlign: 'start',
  width: 600,
};

const TITLE: SxProps = {
  fontSize: 32,
  fontWeight: 700,
};

const SUBTITLE: SxProps = {
  fontSize: 16,
};

const SUCCESSFUL_CLI_LOGIN: SxProps = {
  fontSize: 24,
  fontWeight: 'medium',
};

const LOGIN_LOADING_BUTTON: SxProps = {
  bgcolor: '#F5F8FD',
  p: 2,
  height: 68,
};

const GOOGLE_BUTTON: SxProps = {
  bgcolor: '#F5F8FD',
  p: 2,
  height: 68,
};

const LINKEDIN_BUTTON: SxProps = {
  'bgcolor': '#007EBB',
  'p': 2,
  'height': 68,
  ':hover': {
    bgcolor: '#007EBB',
  },
};

const GITHUB_BUTTON: SxProps = {
  'bgcolor': '#FF8C00',
  'p': 2,
  'height': 68,
  ':hover': {
    bgcolor: '#FF8C00',
  },
};

const GOOGLE_BUTTON_LABEL: SxProps = {
  fontSize: 16,
  fontWeight: 700,
  color: 'black',
};

const GITHUB_BUTTON_LABEL: SxProps = {
  fontSize: 16,
  fontWeight: 700,
  color: 'white',
};

const LINKEDIN_BUTTON_LABEL: SxProps = {
  fontSize: 16,
  fontWeight: 700,
  color: 'white',
};

const TERMS_WRAPPER: SxProps = {
  position: 'fixed',
  bottom: 0,
  p: 5,
  textAlign: 'start',
};

const LINK: CSSProperties = {
  color: 'primary.main',
};

const ACTIONABLE_LINK: SxProps = {
  fontSize: 14,
  color: 'primary.main',
  textDecoration: 'underline',
  cursor: 'pointer',
};

const LoginPage = ({ children }: { children: ReactNode }) => (
  <Grid
    container
    sx={GRID_CONTAINER}
    justifyContent={'center'}
    alignItems={'center'}
  >
    <Grid item xs={3} sx={GRID_LEFT}>
      <Box sx={LogoContainer}>
        <LightLogo />
      </Box>
      <SigninBackground width={'100%'} height={'100%'} />
    </Grid>
    <Grid
      container
      item
      xs={9}
      justifyContent={'center'}
      alignItems={'center'}
      sx={GRID_RIGHT}
    >
      {children}
      <Box sx={TERMS_WRAPPER}>
        We will not make any use of the auth provider without your permission.
        By logging in or signing up, you agree to abide by our policies,
        including our{' '}
        <a
          href="https://github.com/pynt-io/pynt/blob/main/EULA.md"
          style={LINK}
        >
          <u>Terms of Service</u>
        </a>{' '}
        and{' '}
        <a
          href="https://github.com/pynt-io/pynt/blob/main/Privacy-Policy.md"
          style={LINK}
        >
          <u>Privacy Policy</u>
        </a>
      </Box>
    </Grid>
  </Grid>
);

const Login = () => {
  const navigate = useNavigate();
  const { isAuthenticated, loginWithProvider, confirmOAuthCode } =
    useContext(AuthContext);
  const { show } = useContext(SnackbarContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loginProvider, setLoginProvider] = usePersistedState('loginProvider');
  const [isLoading, setIsLoading] = useState(() => {
    // start loading instantly if a code exists
    const code = searchParams.get('code') ?? '';
    return !!code;
  });

  const deviceCode = useMemo(
    () => searchParams.get('device_code') ?? undefined,
    [searchParams],
  );
  const [loginMode, setLoginMode] = useState<
    'app' | 'device' | 'deviceCompleted'
  >(() => (deviceCode ? 'device' : 'app'));
  const loginWithSocial = useCallback(
    (provider: 'Google' | 'GitHub' | 'LinkedIn' | 'Azure') => {
      setLoginProvider(provider);
      loginWithProvider(
        provider === 'GitHub' ? 'GithubShim' : provider,
        deviceCode,
      );
    },
    [setLoginProvider, loginWithProvider, deviceCode],
  );

  useEffect(() => {
    const code = searchParams.get('code') ?? '';
    const state = searchParams.get('state') ?? undefined;
    if (!code) return;

    setIsLoading(true);
    setSearchParams((p) => {
      p.delete('code');
      p.delete('state');
      return p;
    });

    confirmOAuthCode(code, state)
      .then(({ deviceCode, newOrgCreated, goto }) => {
        if (deviceCode) {
          setLoginMode('deviceCompleted');
          return;
        }

        const _goto: string = searchParams.get('goto') || goto || '';
        if (newOrgCreated) {
          navigate('/onboarding');
        } else {
          if (/^\/\w/.test(_goto)) {
            navigate(_goto);
          } else {
            navigate('/dashboard/overview');
          }
        }
      })
      .catch((e) => {
        show(e.toString(), 'error');
        Sentry.captureException(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [searchParams, navigate, setLoginMode]);

  useEffect(() => {
    if (loginMode === 'app' && !isLoading && isAuthenticated) {
      navigate('/');
    }
  }, [loginMode, isLoading, isAuthenticated]);

  const onContactUsClick = useCallback(() => {
    const url = 'mailto:support@pynt.io';
    window.open(url, '_top');
  }, []);

  const [ssoForm, setSsoForm] = useState<boolean>(false);
  const switchToSSO = useCallback(() => {
    setSsoForm(true);
    track('web_app_login_with_sso_button_click');
    setLoginProvider('SSO');
  }, [setSsoForm, setLoginProvider]);
  const switchBackFromSSO = useCallback(() => {
    setLoginProvider(undefined);
    setSsoForm(false);
  }, [setSsoForm, setLoginProvider]);

  if (loginMode === 'deviceCompleted') {
    return (
      <LoginPage>
        <Box sx={LOGIN_BOX}>
          <Stack direction={'column'} spacing={1}>
            <Typography sx={SUCCESSFUL_CLI_LOGIN}>
              You have successfully logged-in to Pynt&apos;s CLI.
            </Typography>
            <Typography sx={SUBTITLE}>
              You can close this window, or visit{' '}
              <a
                href="https://docs.pynt.io/documentation/intro/overview"
                rel="noreferrer noopener"
              >
                Pynt documentation
              </a>
              .
            </Typography>
          </Stack>
        </Box>
      </LoginPage>
    );
  }

  return (
    <LoginPage>
      {ssoForm ? (
        <SSOLoginForm clickedBack={switchBackFromSSO} />
      ) : (
        <Box sx={LOGIN_BOX}>
          <Stack direction={'column'} spacing={5}>
            <Stack direction={'column'} spacing={1}>
              <Typography sx={TITLE}>Welcome to Pynt.</Typography>
              <Typography sx={SUBTITLE}>
                Start your 5 days free trial with Pynt&apos;s API Security
                Platform
              </Typography>
            </Stack>
            <Stack direction={'column'} spacing={2}>
              <LoadingButton
                sx={
                  isLoading && loginProvider === 'Google'
                    ? LOGIN_LOADING_BUTTON
                    : GOOGLE_BUTTON
                }
                loading={isLoading && loginProvider === 'Google'}
                onClick={() => {
                  track('web_app_login_with_google_button_click');

                  loginWithSocial('Google');
                }}
              >
                {isLoading && loginProvider === 'Google' ? (
                  <></>
                ) : (
                  <Stack
                    direction={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    spacing={2}
                  >
                    <GoogleIcon size={32} />
                    <Typography sx={GOOGLE_BUTTON_LABEL}>
                      Sign in with Google
                    </Typography>
                  </Stack>
                )}
              </LoadingButton>
              <LoadingButton
                sx={
                  isLoading && loginProvider === 'GitHub'
                    ? LOGIN_LOADING_BUTTON
                    : GITHUB_BUTTON
                }
                loading={isLoading && loginProvider === 'GitHub'}
                onClick={() => {
                  track('web_app_login_with_github_button_click');

                  loginWithSocial('GitHub');
                }}
              >
                {isLoading && loginProvider === 'GitHub' ? (
                  <></>
                ) : (
                  <Stack
                    direction={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    spacing={2}
                  >
                    <GithubIcon />
                    <Typography sx={GITHUB_BUTTON_LABEL}>
                      Sign in with GitHub
                    </Typography>
                  </Stack>
                )}
              </LoadingButton>
              <LoadingButton
                sx={
                  isLoading && loginProvider === 'LinkedIn'
                    ? LOGIN_LOADING_BUTTON
                    : LINKEDIN_BUTTON
                }
                loading={isLoading && loginProvider === 'LinkedIn'}
                onClick={() => {
                  track('web_app_login_with_LinkedIn_button_click');

                  loginWithSocial('LinkedIn');
                }}
              >
                {isLoading && loginProvider === 'LinkedIn' ? (
                  <></>
                ) : (
                  <Stack
                    direction={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    spacing={2}
                  >
                    <LinkedInIcon />
                    <Typography sx={LINKEDIN_BUTTON_LABEL}>
                      Sign in with LinkedIn
                    </Typography>
                  </Stack>
                )}
              </LoadingButton>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'center'}
                spacing={2}
              >
                <LoadingButton
                  variant="text"
                  disabled={isLoading && loginProvider === 'Azure'}
                  onClick={() => {
                    track('onboarding-1-signup-with-azure');
                    track('postman_onboard_login_with_azure_button_click');

                    loginWithSocial('Azure');
                  }}
                >
                  <Stack
                    direction={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    spacing={1}
                  >
                    {isLoading && loginProvider === 'Azure' ? (
                      <CircularProgress size={16} />
                    ) : (
                      <></>
                    )}
                    <Typography sx={ACTIONABLE_LINK}>Azure AD</Typography>
                  </Stack>
                </LoadingButton>
                <LoadingButton
                  variant="text"
                  disabled={isLoading && loginProvider === 'SSO'}
                  onClick={switchToSSO}
                >
                  <Stack
                    direction={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    spacing={1}
                  >
                    {isLoading && loginProvider === 'SSO' ? (
                      <CircularProgress size={16} />
                    ) : (
                      <></>
                    )}
                    <Typography sx={ACTIONABLE_LINK}>
                      Single Sign-On (SSO)
                    </Typography>
                  </Stack>
                </LoadingButton>
              </Stack>
              <Stack alignItems={'center'} justifyContent={'center'}>
                <Divider sx={{ width: '40%', margin: 'auto' }}></Divider>
              </Stack>
              <Stack alignItems={'center'} justifyContent={'center'} pt={2}>
                <Typography>
                  Need help?{' '}
                  <a href="#" onClick={onContactUsClick}>
                    Contact Support
                  </a>
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      )}
    </LoginPage>
  );
};

export default Login;
