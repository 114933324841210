import { get, httpDelete, post, put, PyntFilter } from './BaseService';
import { Application } from '../models/application';

export async function getApplications(filter: PyntFilter = {}) {
  const res = await get<Array<Application>>('/application/metadata', {
    params: { filter: JSON.stringify(filter) },
  });

  return res.data;
}

export async function getApplicationsCount(where: PyntFilter['where'] = {}) {
  const res = await get('/application/metadata/count', {
    params: { where: JSON.stringify(where) },
  });

  return res.data;
}

export async function getApplicationById(appId: string) {
  const res = await get<Application>(`/application/${appId}/metadata`, {
    validateStatus: status => status === 200 || status === 404,
  });

  if (res.status === 404) {
    return undefined;
  }

  return res.data;
}

export async function createApplication(name: string) {
  const res = await put('/application', { name });

  return res.data;
}

export async function updateApplication(applicationId: string, name: string) {
  const res = await post(`/application/${applicationId}`, {
    name,
  });

  return res.data;
}

export async function updateApplicationAssignee(
  applicationId: string,
  applicationName: string,
  assigneeId: string,
) {
  const res = await post(`/application/${applicationId}`, {
    name: applicationName,
    assignee_id: assigneeId,
  });

  return res.data;
}

export async function deleteApplication(applicationId: string) {
  const res = await httpDelete(`/application/${applicationId}`);

  return res.data;
}

export async function getApplicationSources(applicationId: string) {
  const res = await get(`/applicationSources?app_id=${applicationId}`, {});

  return res.data;
}

export async function createPentestReport(
  applicationId: string,
  scanId: string,
) {
  const res = await post(
    `/pentestReport?app_id=${applicationId}&scan_id=${scanId}`,
    {},
  );

  return res.data;
}

export async function getPentestReportStatus(reqId: string) {
  const res = await get(
    `/pentestReport?pentest_id=${reqId}&time=${Date.now()}`,
    {},
  );

  return res.data;
}
