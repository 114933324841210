import { get, httpDelete, post } from './BaseService';

export async function replaceAuthCode(code: string) {
  const res = await post(
    '/integration',
    {
      integration_type: 'jira',
      action: 'connect',
      properties: { code: code },
    },
    {
      timeout: 300000,
    },
  );
  return res.data;
}

export async function commitSetupAction(
  integrationId: string,
  action: string,
  selectionKey: string,
  selectionValue: any,
) {
  const res = await post(
    '/integration',
    {
      integration_type: 'jira',
      integration_id: integrationId,
      action,
      properties: {
        selection_key: selectionKey,
        selection_value: selectionValue,
      },
    },
    {
      timeout: 300000,
    },
  );
  return res.data;
}

export async function getIntegrations() {
  const res = await get('/integration');
  return res.data;
}

export async function deleteIntegration(integrationId: string) {
  const res = await httpDelete(`/integration?integration_id=${integrationId}`);
  return res.data;
}
