import { capitalize } from '@mui/material';
import { SourceData } from '../components/Setup/EditSource/EditSourceForm';
import { APIGatewaySourceData } from '../components/Setup/EditSource/EditSourceFormAPIGateway';
import { get, httpDelete, postFormData, put } from './BaseService';
import { Application } from '../models/application';

export async function getSources() {
  const res = await get('/source');

  return res.data;
}

export async function getSource(sourceId: string) {
  const res = await get(`/source?source_id=${sourceId}`);

  return res.data;
}

export async function createDocumentSource(
  data: SourceData,
  application: Application,
) {
  let sourceRes;
  if (data.integration === 'swagger' && data.swaggerType === 'file') {
    if (!data.swaggerFile || typeof data.swaggerFile !== 'object') {
      throw new Error('Please select a file');
    }
    sourceRes = await put('/source', {
      type: 'swagger_file',
      application: application.app_id,
      name: 'Swagger File',
      properties: [{ name: 'path', value: data.swaggerFile.name }],
    });

    const formData = new FormData();
    formData.append('content', data.swaggerFile);
    formData.append('source_id', sourceRes.data.source_id);
    formData.append('application_id', application.app_id);

    await postFormData('/uploadFile', formData);
  } else if (data.integration === 'postman') {
    if (!data.file || typeof data.file !== 'object') {
      throw new Error('Please select a file');
    }
    sourceRes = await put('/source', {
      type: 'postman_file',
      application: application.app_id,
      name: 'Postman File',
      properties: [{ name: 'path', value: data.file.name }],
    });

    const formData = new FormData();
    formData.append('content', data.file);
    formData.append('source_id', sourceRes.data.source_id);
    formData.append('application_id', application.app_id);

    await postFormData('/uploadFile', formData);
  } else if ('url' in data) {
    sourceRes = await put('/source', {
      type: data.integration,
      application: application.app_id,
      name: capitalize(data.integration ?? ''),
      properties: [{ name: 'url', value: data.url }],
    });
  } else {
    throw new Error('Unable to create source.');
  }

  const res = await put('/externalSource', {
    source_id: sourceRes.data.source_id,
  });

  return res.data;
}

export async function createAWSApiGatewaySource(
  region: string,
  accessKey: string,
  secretAccessKey: string,
  gwId: string,
  stage: string,
  applicationId: string,
) {
  const sourceRes = await put('/source', {
    type: 'aws_api_gw',
    application: applicationId,
    name: 'aws'.toUpperCase(),
    properties: [
      { name: 'region', value: region },
      { name: 'access_key_id', value: accessKey },
      { name: 'secret_key', value: secretAccessKey },
      { name: 'gw_id', value: gwId },
      { name: 'stage', value: stage },
    ],
  });

  const res = await put('/externalSource', {
    source_id: sourceRes.data.source_id,
  });

  return res.data;
}

export async function createAzureApiGatewaySource(
  tenantId: string,
  clientId: string,
  clientSecret: string,
  subscriptionId: string,
  resourceGroupName: string,
  serviceName: string,
  applicationId: string,
) {
  const sourceRes = await put('/source', {
    type: 'azure_api_gw',
    application: applicationId,
    name: 'azure'.toUpperCase(),
    properties: [
      { name: 'tenant_id', value: tenantId },
      { name: 'client_id', value: clientId },
      { name: 'client_secret', value: clientSecret },
      { name: 'subscription_id', value: subscriptionId },
      { name: 'resource_group_name', value: resourceGroupName },
      { name: 'service_name', value: serviceName },
    ],
  });

  const res = await put('/externalSource', {
    source_id: sourceRes.data.source_id,
  });

  return res.data;
}

export async function createKongApiGatewaySource(
  adminUrl: string,
  serviceName: string,
  authType: null | 'basic' | 'api_key',
  authToken: string | undefined,
  applicationId: string,
) {
  const sourceRes = await put('/source', {
    type: 'kong_api_gw',
    application: applicationId,
    name: 'kong'.toUpperCase(),
    properties: [
      { name: 'admin_url', value: adminUrl },
      { name: 'service_name', value: serviceName },
      { name: 'auth_type', value: authType },
      { name: 'auth_token', value: authToken },
    ],
  });

  const res = await put('/externalSource', {
    source_id: sourceRes.data.source_id,
  });

  return res.data;
}

export const createSource = async (
  data: SourceData,
  application: Application,
) => {
  switch (data.integration) {
    case 'swagger':
    case 'postman':
      return createDocumentSource(data, application);
    case 'apiGateway':
      return createApiGatewaySource(data, application);
  }
  throw new Error('not supported yet');
};

export const createApiGatewaySource = async (
  data: APIGatewaySourceData,
  application: Application,
) => {
  switch (data.vendor) {
    case 'aws':
      return createAWSApiGatewaySource(
        data.region ?? 'us-east-1',
        data.access_key_id ?? '',
        data.secret_access_key_id ?? '',
        data.gw_id ?? '',
        data.stage ?? '',
        application?.app_id ?? '',
      );

    case 'azure':
      return createAzureApiGatewaySource(
        data.tenant_id ?? '',
        data.client_id ?? '',
        data.client_secret ?? '',
        data.subscription_id ?? '',
        data.resource_group_name ?? '',
        data.service_name ?? '',
        application?.app_id ?? '',
      );

    case 'kong':
      return createKongApiGatewaySource(
        data.admin_url ?? '',
        data.service_name ?? '',
        data.auth_type ?? null,
        data.auth_token ?? '',
        application?.app_id ?? '',
      );
  }
  throw new Error('not supported yet');
};

export async function deleteSource(sourceId: string) {
  const res = await httpDelete(`/source?source_id=${sourceId}`);

  return res.data;
}
