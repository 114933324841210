import { Organization, OrganizationConfig } from '../models/organization';
import { get, patch } from './BaseService';

export async function getOrganizationById(
  organizationId: string,
): Promise<Organization> {
  const res = await get(`/organization/${organizationId}`);

  return res.data;
}

export async function updateOrgName(organizationId: string, name: string) {
  const res = await patch(`/organization/${organizationId}`, { name });

  return res.data;
}

export async function updateOrgConfig(
  orgId: string,
  config: Partial<OrganizationConfig>,
) {
  const res = await patch(`/organization/${orgId}/config`, config);

  return res.data;
}
