import { useContext, useEffect } from 'react';
import { AuthContext } from '../../contexts/Auth';
import { useNavigate, useParams } from 'react-router-dom';

const SSOLogin = () => {
  const { isAuthenticated, loginWithProvider } = useContext(AuthContext);
  const { provider } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      return navigate('/dashboard');
    }

    if (!provider) {
      return;
    }

    loginWithProvider(provider);
  }, [isAuthenticated, provider, navigate, loginWithProvider]);

  return null;
};

export default SSOLogin;
